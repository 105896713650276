import { useState, useEffect } from 'react';
import { getVerifiedGroupPostIds } from '../services/groupService';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook to fetch verified post IDs for a given group.
 * 
 * @param {string} groupId - The ID of the group.
 * @returns {{ postIds: string[], isLoading: boolean, error: string | null }}
 */
const useGroupPosts = (groupId) => {
  const { t } = useTranslation();
  const [postIds, setPostIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Don't fetch if groupId is not provided
    if (!groupId) {
      setPostIds([]);
      setIsLoading(false);
      setError(null); // Or potentially set an error like 'Group ID not provided'
      return;
    }

    const fetchPosts = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const ids = await getVerifiedGroupPostIds(groupId);
        setPostIds(ids);
      } catch (err) { // Catch the error thrown by the service
        console.error('Error in useGroupPosts:', err);
        // Use a generic error message, potentially translating it
        setError(t('groups.posts_load_error') || 'Failed to load posts.'); 
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();

    // No cleanup needed for this effect as it's a one-time fetch per groupId change

  }, [groupId, t]); // Re-run effect if groupId or t changes

  return { postIds, isLoading, error };
};

export default useGroupPosts; 