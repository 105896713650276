// src/components/GroupList.js

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UsersRound, Plus, Search } from 'lucide-react';
import { useGroups } from '../hooks';
import './GroupList.css';

const GroupList = ({ onCreateGroup }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groups, loading, formatTime, getInitials } = useGroups();
  const [searchQuery, setSearchQuery] = useState('');
  
  const filteredGroups = groups.filter(group =>
    group.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const SkeletonGroupItem = () => (
    <div className="group-item group-list-skeleton">
      <div className="group-avatar group-list-skeleton-avatar" />
      <div className="group-details">
        <div className="group-details-top">
          <div className="group-list-skeleton-text group-list-skeleton-name" />
          <div className="group-list-skeleton-text group-list-skeleton-time" />
        </div>
        <div className="group-details-bottom">
          <div className="group-list-skeleton-text group-list-skeleton-message" />
        </div>
      </div>
    </div>
  );

  return (
    <div className="group-list">
      <div className="group-list-header">
        <div className="header-content">
          <div className="header-title">
            <UsersRound className="title-icon" />
            <h1>{t('chat.groups')}</h1>
          </div>
          <button
            onClick={onCreateGroup}
            className="create-group-button"
            aria-label="Create new group"
          >
            <Plus />
          </button>
        </div>

        <div className="search-container">
          <Search className="search-icon" />
          <input
            type="text"
            placeholder={t('groups.search_groups')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
        </div>
      </div>        

      <div className="group-list-content">
        {loading ? (
          // Show 10 skeleton items while loading
          Array(10).fill(0).map((_, index) => (
            <SkeletonGroupItem key={index} />
          ))
        ) : filteredGroups.map((group) => (
          <button
            key={group.id}
            onClick={() => navigate(`/groups/${group.id}`)}
            className={`group-item ${group.unreadCount > 0 ? 'unread' : ''}`}
          >
            <div className="group-avatar">
              {group.icon ? (
                <img
                  src={group.icon}
                  alt={group.name}
                  className="avatar-image"
                />
              ) : (
                <div className={`avatar-initials ${group.unreadCount > 0 ? 'unread' : ''}`}>
                  {getInitials(group.name)}
                </div>
              )}
            </div>

            <div className="group-details">
              <div className="group-details-top">
                <h3 className="group-name">{group.name}</h3>
                <span className="last-active">
                  {formatTime(group.lastMessageAt?.toDate())}
                </span>
              </div>
              <div className="group-details-bottom">
                <p className="last-message">
                  {group.lastMessage}
                </p>
                {group.unreadCount > 0 && (
                  <span className="unread-badge">
                    {group.unreadCount}
                  </span>
                )}
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default GroupList;