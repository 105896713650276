// src/components/AgentChatInterface.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Send, Loader, Image, X } from 'lucide-react';
import { auth } from '../firebase';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import ImageUploader from './ImageUploader';
import ChatPostList from './ChatPostList';
import DraftItem from './DraftItem';
import botIcon from '../assets/icon.png';
import './AgentChatInterface.css';

// Import agent chat service functions
import { 
  subscribeToMessages,
  loadMoreMessages,
  sendMessage,
  uploadChatImages
} from '../services/agentChatService';

const MAX_IMAGES = 6;
const MESSAGES_PER_PAGE = 25;

const AgentChatInterface = () => {
  const { t } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [error, setError] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [showSendButton, setShowSendButton] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  // Refs
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const topOfListRef = useRef(null);
  const textareaRef = useRef(null);
  const messageListRef = useRef(null);

  // Subscribe to messages
  useEffect(() => {
    if (!auth.currentUser) return;

    const handleMessagesUpdate = ({ messages: fetchedMessages, lastVisible: lastDoc, hasMore }) => {
      setMessages(fetchedMessages);
      setLastVisible(lastDoc);
      setHasMoreMessages(hasMore);
      setInitialLoadComplete(true);
    };
    
    const handleError = (error) => {
      console.error('Error fetching messages:', error);
      setError(t('chat.load_error'));
    };

    const unsubscribe = subscribeToMessages(
      MESSAGES_PER_PAGE,
      handleMessagesUpdate,
      handleError
    );

    return () => unsubscribe();
  }, [t]);

  // Initial scroll to bottom
  useEffect(() => {
    if (initialLoadComplete && messageListRef.current && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [initialLoadComplete]);

  // Load more messages
  const handleLoadMoreMessages = useCallback(async () => {
    if (!lastVisible || isLoadingMore || !hasMoreMessages || !auth.currentUser) return;

    setIsLoadingMore(true);

    try {
      // Remember current scroll position
      const listElem = messageListRef.current;
      const prevScrollHeight = listElem.scrollHeight;
      const prevScrollTop = listElem.scrollTop;

      // Load more messages using service
      const { messages: olderMessages, lastVisible: newLastVisible, hasMore } = 
        await loadMoreMessages(lastVisible, MESSAGES_PER_PAGE);

      if (olderMessages.length > 0) {
        setMessages((prevMessages) => [...olderMessages, ...prevMessages]);
        setLastVisible(newLastVisible);
        setHasMoreMessages(hasMore);
        
        // Adjust scroll position
        requestAnimationFrame(() => {
          const newScrollHeight = listElem.scrollHeight;
          listElem.scrollTop = newScrollHeight - prevScrollHeight + prevScrollTop;
        });
      } else {
        setHasMoreMessages(false);
      }
    } catch (error) {
      console.error('Error loading more messages:', error);
      setError(t('chat.load_more_error'));
    } finally {
      setIsLoadingMore(false);
    }
  }, [lastVisible, isLoadingMore, hasMoreMessages, t]);

  // Set up IntersectionObserver to load more messages when scrolled to top
  useEffect(() => {
    if (!topOfListRef.current || !hasMoreMessages) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLoadingMore) {
            handleLoadMoreMessages();
          }
        });
      },
      { root: null, rootMargin: '100px', threshold: 0.1 }
    );

    observer.observe(topOfListRef.current);

    return () => {
      observer.disconnect();
    };
  }, [handleLoadMoreMessages, isLoadingMore, hasMoreMessages]);

  // Auto-scroll behavior for new messages
  useEffect(() => {
    if (!initialLoadComplete) return;

    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      const isNearBottom = scrollHeight - scrollTop - clientHeight < 300;
  
      if (isNearBottom) {
        requestAnimationFrame(() => {
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        });
      }
    }
  }, [messages.length, initialLoadComplete]);

  // Textarea height adjustment
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '48px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  // Image handling functions
  const handleFileSelect = async (files) => {
    try {
      const imagesData = await uploadChatImages(files);
      setUploadedImages((prevImages) => [...prevImages, ...imagesData]);
      setShowSendButton(true);
    } catch (error) {
      console.error('Error uploading images:', error);
      setError(t('chat.image_upload_error'));
    }
  };

  const handleRemoveImage = (index) => {
    setUploadedImages((prevImages) => {
      const newImages = prevImages.filter((_, i) => i !== index);
      // Update send button visibility
      setShowSendButton(input.trim().length > 0 || newImages.length > 0);
      return newImages;
    });
  };

  const handleImageIconClick = () => {
    fileInputRef.current.click();
  };

  const handleImageLoad = useCallback(() => {
    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      const isNearBottom = scrollHeight - scrollTop - clientHeight < 300;
      if (isNearBottom) {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
      }
    }
  }, []);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if ((input.trim() === '' && uploadedImages.length === 0) || !auth.currentUser) return;
  
    setIsLoading(true);
    setError(null);
  
    try {
      // Send message using the agent service
      await sendMessage(input, uploadedImages);

      // Reset input and uploaded images
      setInput('');
      setUploadedImages([]);
      setShowSendButton(false);
      
      // Scroll to bottom
      requestAnimationFrame(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      });
    } catch (error) {
      console.error('Error sending message:', error);
      setError(t('chat.send_error'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setInput(inputValue);
    setShowSendButton(inputValue.trim().length > 0 || uploadedImages.length > 0);
  };

  return (
    <div className="chat-interface">
      <div className="message-list" ref={messageListRef}>
        <div ref={topOfListRef} />
        
        {/* Loading indicator when fetching more messages */}
        {isLoadingMore && (
          <div className="loading-more">
            <div className="spinner" />
            <span>{t('chat.loading_more')}</span>
          </div>
        )}
        
        {/* Scroll indicator when there are more messages to load */}
        {hasMoreMessages && !isLoadingMore && (
          <div className="scroll-indicator">{t('chat.scroll_to_load')}</div>
        )}
        
        {messages.map((message, index) => {
          const isCurrentUser = message.sender === 'user';
          const isBot = message.sender === 'bot';
          
          // Is this the first message from this sender in a consecutive sequence?
          const isFirstInSequence = 
            index === 0 || 
            messages[index - 1].sender !== message.sender;
          
          return (
            <div
              key={message.id}
              className={`message-container ${isCurrentUser ? 'sent' : 'received'}`}
            >
              {/* Updated Bot avatar and name - only show for first message in sequence */}
              {!isCurrentUser && isBot && isFirstInSequence && (
                <>
                  <div className="avatar-container">
                    <img
                      src={botIcon}
                      alt={t('chat.agent_icon')}
                      className="sender-profile-picture"
                    />
                  </div>
                  <div className="sender-display-name">
                    {t('app_name') || 'Assistant'}
                  </div>
                </>
              )}

              {/* Unified message rendering */}
              {(() => {
                switch (message.type) {
                  case 'search_results':
                    return (
                      <ChatPostList
                        postIds={message.postIds}
                      />
                    );

                  case 'draft_post':
                    return (
                      <DraftItem
                        draftId={message.draftId}
                      />
                    );

                  // Handle regular chat messages (either from user or bot)
                  case 'chat':
                  default:
                    // Display text, images, etc.
                    return (
                      <div className="message">
                        {message.images && message.images.map((image, idx) => (
                          <img
                            key={idx}
                            src={image.url}
                            alt={image.name || t('chat.uploaded_image', { index: idx + 1 })}
                            className="message-image"
                            loading="lazy"
                            onLoad={handleImageLoad}
                          />
                        ))}
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm, remarkBreaks]}
                        >
                          {message.text}
                        </ReactMarkdown>
                      </div>
                    );
                }
              })()}
            </div>
          );
        })}
        <div ref={messagesEndRef} />
      </div>

      {error && <div className="error-message">{error}</div>}

      <div className="message-input-container">
        {uploadedImages.length > 0 && (
          <div className="uploaded-images-preview">
            {uploadedImages.map((image, index) => (
              <div key={index} className="uploaded-image-container">
                <img 
                  src={image.url} 
                  alt={t('chat.uploaded_image', { index: index + 1 })} 
                  className="uploaded-image" 
                />
                <button
                  onClick={() => handleRemoveImage(index)}
                  className="remove-image-button"
                  aria-label={t('chat.remove_image')}
                >
                  <X size={20} />
                </button>
              </div>
            ))}
          </div>
        )}

        <form onSubmit={handleSendMessage} className="message-input">
          <button
            type="button"
            onClick={handleImageIconClick}
            className="image-upload-button"
            aria-label={t('chat.upload_images')}
            disabled={uploadedImages.length >= MAX_IMAGES || isLoading}
            title={uploadedImages.length >= MAX_IMAGES 
              ? t('chat.max_images_reached', { max: MAX_IMAGES }) 
              : t('chat.upload_images')}
          >
            <Image size={22} />
          </button>
          
          <textarea
            ref={textareaRef}
            value={input}
            onChange={handleInputChange}
            placeholder={t('chat.type_message')}
            disabled={isLoading}
            rows={1}
          />
          
          <button
            type="submit"
            disabled={isLoading || (input.trim().length === 0 && uploadedImages.length === 0)}
            className={`send-button ${showSendButton ? 'visible' : ''} ${
              isLoading ? 'loading' : ''
            }`}
            aria-label={isLoading ? t('chat.processing_message') : t('chat.send_message')}
          >
            {isLoading ? <Loader size={20} className="loader" /> : <Send size={20} />}
          </button>
        </form>
      </div>

      <ImageUploader
        onFilesSelected={handleFileSelect}
        fileInputRef={fileInputRef}
        currentImageCount={uploadedImages.length}
        maxImages={MAX_IMAGES}
      />
    </div>
  );
};

export default AgentChatInterface;