// src/hooks/useFriendRequests.js
import { useState, useEffect, useCallback } from 'react';
import { 
  subscribeFriendRequests, 
  acceptFriendRequest, 
  declineFriendRequest 
} from '../services/friendshipService';

/**
 * Hook for managing friend requests with real-time updates
 */
const useFriendRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [processingRequests, setProcessingRequests] = useState(new Set());

  useEffect(() => {
    setLoading(true);
    
    // Subscribe to real-time friend request updates
    const unsubscribe = subscribeFriendRequests((requestsList) => {
      setRequests(requestsList);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  /**
   * Accept a friend request
   * @param {string} requesterId - ID of the user who sent the request
   */
  const handleAcceptRequest = useCallback(async (requesterId) => {
    if (processingRequests.has(requesterId)) return;
    
    setProcessingRequests(prev => new Set(prev).add(requesterId));
    setError(null);

    try {
      await acceptFriendRequest(requesterId);
      // The requests state will update automatically via the subscription
      return true;
    } catch (err) {
      console.error('Error accepting friend request:', err);
      setError(err.message || 'Failed to accept request');
      return false;
    } finally {
      setProcessingRequests(prev => {
        const newSet = new Set(prev);
        newSet.delete(requesterId);
        return newSet;
      });
    }
  }, [processingRequests]);

  /**
   * Decline a friend request
   * @param {string} requesterId - ID of the user who sent the request
   */
  const handleDeclineRequest = useCallback(async (requesterId) => {
    if (processingRequests.has(requesterId)) return;
    
    setProcessingRequests(prev => new Set(prev).add(requesterId));
    setError(null);

    try {
      await declineFriendRequest(requesterId);
      // The requests state will update automatically via the subscription
      return true;
    } catch (err) {
      console.error('Error declining friend request:', err);
      setError(err.message || 'Failed to decline request');
      return false;
    } finally {
      setProcessingRequests(prev => {
        const newSet = new Set(prev);
        newSet.delete(requesterId);
        return newSet;
      });
    }
  }, [processingRequests]);

  /**
   * Check if a specific user request is currently being processed
   * @param {string} requesterId - ID of the user to check
   * @returns {boolean} Whether request is being processed
   */
  const isProcessingRequest = useCallback((requesterId) => {
    return processingRequests.has(requesterId);
  }, [processingRequests]);

  return {
    requests,
    loading,
    error,
    isProcessingRequest,
    acceptRequest: handleAcceptRequest,
    declineRequest: handleDeclineRequest,
    hasRequests: requests.length > 0
  };
};

export default useFriendRequests; 