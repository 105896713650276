// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from './components/Layout';
import Auth from './components/Auth';
import ProfileForm from './components/ProfileForm/ProfileForm';
import Chatbot from './pages/Chatbot';
import Friends from './pages/Friends';
import Profile from './pages/Profile';
import PostDetailView from './components/PostDetailView';
import GroupPage from './pages/GroupPage';
import GroupChatPage from './pages/GroupChatPage';
import AgentChatPage from './pages/AgentChatPage';
import NotificationHandler from './components/NotificationHandler';
import { initializeFCM } from './services/fcmTokenService';
import { PostProvider } from './contexts/PostContext';
import { isNotificationSupported } from './utils/featureDetection';
import { getSecuredSearchClient } from './utils/secureSearch';
import Button from './components/Button';
import { useUser } from './hooks';
import './App.css';
import logo from './assets/logo.png';

const LoadingScreen = () => (
  <div className="loading-screen">
    <img src={logo} alt="App Logo" className="loading-logo" />
  </div>
);

const ErrorScreen = ({ message }) => (
  <div className="error-screen">
    <p>{message}</p>
    <Button onClick={() => window.location.reload()} variant="primary" size="medium">
      Retry
    </Button>
  </div>
);

function App() {
  const { t } = useTranslation();
  const { user, profile, loading, error, updateCompleteUserProfile, isProfileIncomplete } = useUser();
  const [notificationSupported, setNotificationSupported] = useState(false);

  useEffect(() => {
    console.log('App started');

    // Check notification support
    if (isNotificationSupported()) {
      setNotificationSupported(true);
    } else {
      console.log('Notifications are not supported on this browser');
    }
  }, []);

  // Handle FCM token initialization and refresh in the background
  useEffect(() => {
    if (user && notificationSupported && !isProfileIncomplete()) {
      // Initialize FCM after UI is rendered
      const initializeFCMInBackground = async () => {
        try {
          const fcmInitialized = await initializeFCM();
          console.log('FCM initialization completed', fcmInitialized ? 'successfully' : 'with warnings');                    
        } catch (error) {
          console.error('Background FCM initialization failed:', error);
        }
      };

      initializeFCMInBackground();
    }
  }, [user, notificationSupported, isProfileIncomplete]);

  // Prefetch Algolia secured key after profile is complete
  useEffect(() => {
    if (user && !isProfileIncomplete()) {
      const prefetchAlgoliaKey = async () => {
        try {
          await getSecuredSearchClient();
          console.log('Algolia secured key prefetched successfully');
        } catch (error) {
          console.error('Failed to prefetch Algolia key:', error);
        }
      };

      prefetchAlgoliaKey();
    }
  }, [user, isProfileIncomplete]);

  const handleProfileUpdate = async (updatedProfile, originalUsername, profilePicture) => {
    try {
      await updateCompleteUserProfile(updatedProfile, originalUsername, profilePicture);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingScreen />;
    }

    if (!user) {
      return <Auth onAuthSuccess={() => {}} />;
    }

    if (isProfileIncomplete()) {
      return <ProfileForm 
        existingProfile={profile} 
        onProfileUpdate={handleProfileUpdate}
      />;
    }

    if (error) {
      return <ErrorScreen message={t('profile.profile_load_error')} />
    }

    // Profile is complete, show main content
    return (
      <PostProvider>
        <Layout>
          <Routes>
            <Route path="/" element={<Chatbot />} />
            <Route path="/friends" element={<Friends />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/:userId?" element={<Profile />} />
            <Route path="/profile/:userId?/saved" element={<Profile initialTab="saved" />} />
            <Route 
              path="/post/:postId" 
              element={<PostDetailView key={window.location.pathname} />} 
            />
            <Route path="/groups" element={<GroupPage />} />
            <Route path="/groups/:groupId" element={<GroupChatPage />} />
            <Route path="/agent" element={<AgentChatPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          {notificationSupported && <NotificationHandler />}
        </Layout>
      </PostProvider>
    );
  };

  return (
    <Router>
      <div className="app-container">
        {renderContent()}
      </div>
    </Router>
  );
}

export default App;