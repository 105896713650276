// src/services/fcmTokenService.js
import { messaging } from '../firebase';
import { getToken, deleteToken } from "firebase/messaging";
import { auth, db } from '../firebase';
import { doc, setDoc, serverTimestamp, getDoc, updateDoc, deleteField } from 'firebase/firestore';
import { isFirebaseMessagingSupported } from '../utils/featureDetection';

const VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;
const FCM_REFRESH_KEY = 'fcm_last_refresh';

// Configure delays and retries
const TOKEN_DELETION_DELAY = 2000; // 2 seconds wait after token deletion
const SERVICE_WORKER_CHECK_INTERVAL = 500; // 500ms between service worker checks
const SERVICE_WORKER_CHECK_TIMEOUT = 10000; // 10 seconds total timeout
const TOKEN_REFRESH_RETRIES = 3; // Number of retry attempts

/**
 * Initialize FCM and request necessary permissions
 * @return {Promise<string|null>} FCM token if successful, null otherwise
 */
export const initializeFCM = async () => {
  if (!await isFirebaseMessagingSupported()) {
    console.log('Firebase Messaging is not supported on this browser');
    return null;
  }

  try {
    // Request permission if not already granted
    if (Notification.permission !== 'granted') {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        console.log('Notification permission denied');
        return null;
      }
    }

    // Ensure service worker is ready before proceeding
    const serviceWorker = await ensureServiceWorkerIsReady();
    if (!serviceWorker) {
      console.error('Service worker couldn\'t be activated in time');
      return null;
    }

    // Check if we need to refresh the token due to page reload
    if (shouldRefreshToken()) {
      console.log('Page was refreshed, initiating proper FCM token refresh sequence');
      return await safeTokenRefresh(serviceWorker);
    }

    // Normal flow - get and save token
    return await getAndSaveFCMToken(serviceWorker);
  } catch (error) {
    console.error('Error initializing FCM:', error);
    return null;
  }
};

/**
 * Wait for service worker to be fully activated
 * @return {Promise<ServiceWorkerRegistration|null>} The active service worker or null
 */
const ensureServiceWorkerIsReady = async () => {
  const startTime = Date.now();
  
  while (Date.now() - startTime < SERVICE_WORKER_CHECK_TIMEOUT) {
    try {
      const registration = await navigator.serviceWorker.getRegistration();
      
      if (registration && registration.active) {
        console.log('Service worker is active');
        return registration;
      }
      
      console.log('Waiting for service worker to activate...');
      await new Promise(resolve => setTimeout(resolve, SERVICE_WORKER_CHECK_INTERVAL));
    } catch (error) {
      console.error('Error checking service worker status:', error);
      return null;
    }
  }
  
  console.error('Service worker activation timed out');
  return null;
};

/**
 * Determine if token needs refreshing based on page refresh detection
 * @return {boolean} True if token should be refreshed
 */
const shouldRefreshToken = () => {
  const lastRefresh = localStorage.getItem(FCM_REFRESH_KEY);
  const currentTime = Date.now().toString();
  
  // If the page session is new (no refresh key) or older than 10 seconds, 
  // consider this a page reload
  const isPageReload = !lastRefresh || 
    (Date.now() - parseInt(lastRefresh, 10)) > 10000;
  
  // Update the refresh timestamp
  localStorage.setItem(FCM_REFRESH_KEY, currentTime);
  
  return isPageReload;
};

/**
 * Safe token refresh with server synchronization and retry logic
 * @param {ServiceWorkerRegistration} serviceWorkerRegistration - The active service worker
 * @return {Promise<string|null>} The new FCM token
 */
const safeTokenRefresh = async (serviceWorkerRegistration) => {
  const user = auth.currentUser;
  if (!user) return null;
  
  try {
    // 1. First remove the token from Firestore to prevent notifications during refresh
    const userRef = doc(db, 'users', user.uid);
    await updateDoc(userRef, {
      fcmToken: deleteField(),
      fcmTokenUpdatedAt: deleteField()
    });
    console.log('FCM token removed from Firestore');
    
    // 2. Delete the token from Firebase messaging system
    await deleteToken(messaging);
    console.log('FCM token deleted from Firebase');
    
    // 3. Wait for token deletion to propagate in Firebase backend
    await new Promise(resolve => setTimeout(resolve, TOKEN_DELETION_DELAY));
    
    // 4. Try to get a new token with retries
    let newToken = null;
    for (let attempt = 0; attempt < TOKEN_REFRESH_RETRIES; attempt++) {
      try {
        console.log(`Getting new FCM token (attempt ${attempt + 1}/${TOKEN_REFRESH_RETRIES})`);
        
        // Get a fresh token
        newToken = await getToken(messaging, { 
          vapidKey: VAPID_KEY,
          serviceWorkerRegistration
        });
        
        if (newToken) {
          console.log('New FCM token obtained successfully');
          break;
        }
        
        // Wait before retry
        if (attempt < TOKEN_REFRESH_RETRIES - 1) {
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      } catch (error) {
        console.error(`Error getting token on attempt ${attempt + 1}:`, error);
        if (attempt < TOKEN_REFRESH_RETRIES - 1) {
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }
    }
    
    if (!newToken) {
      console.error('Failed to obtain new FCM token after multiple attempts');
      return null;
    }
    
    // 5. Save the new token to Firestore
    await setDoc(userRef, { 
      fcmToken: newToken,
      fcmTokenUpdatedAt: serverTimestamp()
    }, { merge: true });
    console.log('New FCM token saved to Firestore');
    
    return newToken;
  } catch (error) {
    console.error('Error during safe token refresh:', error);
    return null;
  }
};

/**
 * Get FCM token and save it to Firestore
 * @param {ServiceWorkerRegistration} serviceWorkerRegistration - The active service worker
 * @return {Promise<string|null>} The FCM token
 */
export const getAndSaveFCMToken = async (serviceWorkerRegistration) => {
  try {
    // If no registration provided, get it
    if (!serviceWorkerRegistration) {
      serviceWorkerRegistration = await navigator.serviceWorker.getRegistration();
    }
    
    const currentToken = await getToken(messaging, { 
      vapidKey: VAPID_KEY,
      serviceWorkerRegistration
    });
    
    if (currentToken) {
      console.log('FCM token received');
      await saveFCMToken(currentToken);
      return currentToken;
    } else {
      console.log('No registration token available');
      return null;
    }
  } catch (error) {
    console.error('Error getting FCM token:', error);
    return null;
  }
};

/**
 * Save FCM token to Firestore
 * @param {string} token - The FCM token to save
 * @return {Promise<boolean>} Success status
 */
export const saveFCMToken = async (token) => {
  const user = auth.currentUser;
  if (!user) return false;
  
  try {
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    
    // Only update if token changed
    if (!userDoc.exists() || !userDoc.data().fcmToken || userDoc.data().fcmToken !== token) {
      await setDoc(userRef, { 
        fcmToken: token,
        fcmTokenUpdatedAt: serverTimestamp()
      }, { merge: true });
      console.log('FCM token saved');
    }
    
    return true;
  } catch (error) {
    console.error('Error saving FCM token:', error);
    return false;
  }
};