// src/services/userService.js
import { auth, db, storage } from '../firebase';
import { 
  doc, 
  getDoc, 
  setDoc, 
  serverTimestamp
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import { getFunctions, httpsCallable } from 'firebase/functions';

/**
 * Get the current authenticated user
 * @returns {Object|null} Firebase Auth user object or null
 */
export const getCurrentUser = () => {
  return auth.currentUser;
};

/**
 * Get a user profile by ID
 * @param {string} userId - The user ID to fetch
 * @returns {Promise<Object>} User profile data
 * @throws {Error} If the profile cannot be fetched
 */
export const getUserProfile = async (userId) => {
  try {
    const docRef = doc(db, 'users', userId);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      // Create a new profile document with default values
      const defaultProfile = {
        displayName: '',
        username: '',
        bio: '',
        profilePictureUrl: '',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };
      
      await setDoc(docRef, defaultProfile);
      return { id: docSnap.id, ...defaultProfile };
    }
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

/**
 * Update a user's profile
 * @param {Object} profileData - The profile data to update
 * @returns {Promise<Object>} Updated profile data
 * @throws {Error} If the update fails
 */
export const updateUserProfile = async (profileData) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not authenticated');
  }

  try {
    await setDoc(doc(db, 'users', user.uid), {
      ...profileData,
      updatedAt: serverTimestamp()
    }, { merge: true });

    return profileData;
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

/**
 * Set a username (handles uniqueness check via Cloud Function)
 * @param {string} username - The desired username
 * @returns {Promise<Object>} Result with success status
 * @throws {Error} If the username cannot be set
 */
export const setUsername = async (username) => {
  const functions = getFunctions();
  const setUsernameFunction = httpsCallable(functions, 'setUsername');
  
  try {
    const result = await setUsernameFunction({ username });
    if (!result.data.success) {
      throw new Error('Username already taken');
    }
    return result.data;
  } catch (error) {
    console.error('Error setting username:', error);
    throw error;
  }
};

/**
 * Upload a profile picture for the current user
 * @param {File} file - The image file to upload
 * @returns {Promise<string>} URL of the uploaded image
 * @throws {Error} If the upload fails
 */
export const uploadProfilePicture = async (file) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not authenticated');
  }

  try {
    // Compress the image before upload
    const compressedFile = await imageCompression(file, {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    });

    const storageRef = ref(storage, `profilePictures/${user.uid}/profile.jpg`);
    await uploadBytes(storageRef, compressedFile);
    const profilePictureUrl = await getDownloadURL(storageRef);
    
    // Update the user profile with the new image URL
    await updateUserProfile({ profilePictureUrl });
    
    return profilePictureUrl;
  } catch (error) {
    console.error('Error uploading profile picture:', error);
    throw error;
  }
};

/**
 * Update user profile with complete data
 * Includes handling username change and profile picture upload
 * @param {Object} profileData - Basic profile data
 * @param {string} originalUsername - Previous username
 * @param {File} profilePicture - Profile picture file (optional)
 * @returns {Promise<Object>} Complete updated profile
 * @throws {Error} If any part of the update fails
 */
export const updateCompleteProfile = async (profileData, originalUsername, profilePicture) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not authenticated');
  }

  try {
    let updatedUsername = originalUsername;
    // Handle username change if necessary
    if (profileData.username !== originalUsername) {
      await setUsername(profileData.username);
      updatedUsername = profileData.username;
    }

    // Handle profile picture if provided
    let profilePictureUrl = profileData.profilePictureUrl;
    if (profilePicture) {
      profilePictureUrl = await uploadProfilePicture(profilePicture);
    }

    // Prepare the complete profile data
    const completeProfileData = {
      ...profileData,
      username: updatedUsername,
      profilePictureUrl,
      updatedAt: serverTimestamp()
    };

    // Update the user document
    await setDoc(doc(db, 'users', user.uid), completeProfileData, { merge: true });

    return completeProfileData;
  } catch (error) {
    console.error('Error updating complete profile:', error);
    throw error;
  }
};