// src/services/postService.js
import { db, auth } from '../firebase';
import { 
  collection, 
  doc, 
  getDoc, 
  query, 
  where, 
  getDocs, 
  orderBy, 
  startAfter,
  limit
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Post from '../models/Post';

// Initialize Firebase Functions
const functions = getFunctions();

// Post CRUD functions
const createPostFunction = httpsCallable(functions, 'createPost');
const updatePostFunction = httpsCallable(functions, 'updatePost');
const deletePostFunction = httpsCallable(functions, 'deletePost');

// Comment functions
const addCommentFunction = httpsCallable(functions, 'addComment');
const deleteCommentFunction = httpsCallable(functions, 'deleteComment');
const replyToCommentFunction = httpsCallable(functions, 'replyToComment');
const deleteReplyFunction = httpsCallable(functions, 'deleteReply');

// Post interaction functions
const likePostFunction = httpsCallable(functions, 'likePost');
const unlikePostFunction = httpsCallable(functions, 'unlikePost');
const sharePostFunction = httpsCallable(functions, 'sharePost');
const savePostFunction = httpsCallable(functions, 'savePost');
const unsavePostFunction = httpsCallable(functions, 'unsavePost');

// Helper function to check friendship status
const checkFriendship = async (userId1, userId2) => {
  const user1Doc = await getDoc(doc(db, 'users', userId1));
  const user2Doc = await getDoc(doc(db, 'users', userId2));

  if (!user1Doc.exists() || !user2Doc.exists()) {
    return false;
  }

  const user1Friends = user1Doc.data().friends || [];
  const user2Friends = user2Doc.data().friends || [];

  return user1Friends.includes(userId2) && user2Friends.includes(userId1);
};

// ============== POSTS CRUD OPERATIONS ==============

/**
 * Create a new post
 */
export const createPost = async (name, description, link, privacy, searchMetadata, fromGroupDraft = false, groupId = null, originalDraftId = null) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to create a post');

  if (!name || !description) {
    throw new Error('Name and description are required for creating a post.');
  }

  try {
    const result = await createPostFunction({
      name,
      description,
      link: link || '',
      privacy: privacy || 'public',
      searchMetadata: searchMetadata || [],
      fromGroupDraft,
      groupId,
      originalDraftId
    });

    return result.data;
  } catch (error) {
    console.error('Error creating post:', error);
    throw new Error(error.message || 'Failed to create post');
  }
};

/**
 * Update an existing post
 */
export const updatePost = async (postId, updates) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to update a post');

  try {
    const result = await updatePostFunction({
      postId,
      updates
    });

    return result.data;
  } catch (error) {
    console.error('Error updating post:', error);
    throw new Error(error.message || 'Failed to update post');
  }
};

/**
 * Delete a post
 */
export const deletePost = async (postId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to delete a post');

  try {
    await deletePostFunction({ postId });
    return true;
  } catch (error) {
    console.error('Error deleting post:', error);
    throw new Error(error.message || 'Failed to delete post');
  }
};

/**
 * Get a single post by ID
 */
export const getPost = async (postId) => {
  const postRef = doc(db, 'posts', postId);
  const postSnap = await getDoc(postRef);

  if (!postSnap.exists()) throw new Error('Post not found');
  
  const postData = postSnap.data();
  
  // Convert Firestore Timestamp to JavaScript timestamp
  if (postData.createdAt && typeof postData.createdAt.toDate === 'function') {
    postData.createdAt = postData.createdAt.toDate().getTime();
  }
  
  return { objectID: postSnap.id, ...postData };
};

/**
 * Get posts from a specific user with pagination
 */
export const getUserPosts = async ({
  userId,
  currentUserId,
  limitCount = 5,
  lastDocRef = null
}) => {
  const user = auth.currentUser;
  if (!user) throw new Error("User must be logged in to fetch posts");

  try {
    let baseQuery;
    
    if (userId === currentUserId) {
      // User viewing their own posts, show all
      baseQuery = query(
        collection(db, "posts"),
        where("userId", "==", userId),
        orderBy("updatedAt", "desc")
      );
    } else {
      // Check if the current user is friends with the profile owner
      const areFriends = await checkFriendship(userId, currentUserId);

      if (areFriends) {
        // Friends can see public and friends-only posts
        baseQuery = query(
          collection(db, "posts"),
          where("userId", "==", userId),
          where("privacy", "in", ["public", "friends"]),
          orderBy("updatedAt", "desc")
        );
      } else {
        // Non-friends can only see public posts
        baseQuery = query(
          collection(db, "posts"),
          where("userId", "==", userId),
          where("privacy", "==", "public"),
          orderBy("updatedAt", "desc")
        );
      }
    }

    // Add pagination constraints
    let paginatedQuery;
    if (lastDocRef) {
      paginatedQuery = query(
        baseQuery,
        startAfter(lastDocRef),
        limit(limitCount)
      );
    } else {
      paginatedQuery = query(   
        baseQuery,
        limit(limitCount)
      );
    } 

    const querySnapshot = await getDocs(paginatedQuery);
    const posts = querySnapshot.docs.map(doc => Post.fromFirestore(doc));

    // Get the last document for next pagination
    const lastVisible = querySnapshot.docs.length > 0
      ? querySnapshot.docs[querySnapshot.docs.length - 1]
      : null;

    return {
      posts,
      lastDocRef: lastVisible
    };      
  } catch (error) {
    console.error("Error fetching posts:", error);
    return { posts: [], lastDocRef: null };
  }
};

// ============== POST INTERACTIONS ==============

/**
 * Like a post
 */
export const likePost = async (postId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to like a post');

  try {
    const result = await likePostFunction({ postId });
    return result.data;
  } catch (error) {
    console.error('Error liking post:', error);
    throw new Error(error.message || 'Failed to like post');
  }
};

/**
 * Unlike a post
 */
export const unlikePost = async (postId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to unlike a post');

  try {
    const result = await unlikePostFunction({ postId });
    return result.data;
  } catch (error) {
    console.error('Error unliking post:', error);
    throw new Error(error.message || 'Failed to unlike post');
  }
};

/**
 * Share a post
 */
export const sharePost = async (postId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to share a post');

  try {
    const result = await sharePostFunction({ postId });
    return result.data;
  } catch (error) {
    console.error('Error sharing post:', error);
    throw new Error(error.message || 'Failed to share post');
  }
};

/**
 * Save a post to user's saved posts subcollection
 */
export const savePost = async (postId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to save a post');

  try {
    const result = await savePostFunction({ postId });
    return result.data;
  } catch (error) {
    console.error('Error saving post:', error);
    throw new Error(error.message || 'Failed to save post');
  }
};

/**
 * Unsave a post from user's saved posts subcollection
 */
export const unsavePost = async (postId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to unsave a post');

  try {
    const result = await unsavePostFunction({ postId });
    return result.data;
  } catch (error) {
    console.error('Error unsaving post:', error);
    throw new Error(error.message || 'Failed to unsave post');
  }
};

// ============== COMMENTS/REPLIES ==============

/**
 * Add a comment to a post
 */
export const commentOnPost = async (postId, comment) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to comment on a post');

  try {
    const result = await addCommentFunction({
      postId,
      content: comment
    });

    return result.data;
  } catch (error) {
    console.error('Error commenting on post:', error);
    throw new Error(error.message || 'Failed to add comment to post');
  }
};

/**
 * Reply to a comment
 */
export const replyToComment = async (postId, commentIndex, replyContent, replyToUserId = null) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to reply to a comment');

  try {
    const result = await replyToCommentFunction({
      postId,
      commentIndex,
      content: replyContent,
      replyToUserId
    });

    return result.data;
  } catch (error) {
    console.error('Error replying to comment:', error);
    throw new Error(error.message || 'Failed to reply to comment');
  }
};

/**
 * Delete a comment
 */
export const deleteComment = async (postId, commentIndex) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to delete a comment');

  try {
    const result = await deleteCommentFunction({
      postId,
      commentIndex
    });

    return result.data;
  } catch (error) {
    console.error('Error deleting comment:', error);
    throw new Error(error.message || 'Failed to delete comment');
  }
};

/**
 * Delete a reply
 */
export const deleteReply = async (postId, commentIndex, replyIndex) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to delete a reply');

  try {
    const result = await deleteReplyFunction({
      postId,
      commentIndex,
      replyIndex
    });

    return result.data;
  } catch (error) {
    console.error('Error deleting reply:', error);
    throw new Error(error.message || 'Failed to delete reply');
  }
};

// ============== SAVED POSTS MANAGEMENT ==============

/**
 * Check if the current user has saved a specific post
 */
export const checkIfPostIsSaved = async (postId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to check saved posts');

  try {
    const ref = doc(db, 'users', user.uid, 'savedPosts', postId);
    const snap = await getDoc(ref);
    return snap.exists();
  } catch (error) {
    console.error('Error checking if post is saved:', error);
    throw new Error(error.message || 'Failed to check if post is saved');
  }
};

/**
 * Get all posts saved by the current user with pagination
 * 
 * @param {number} limit - Number of posts to fetch
 * @param {object} lastDocRef - Reference to the last document for pagination
 */
export const getUserSavedPosts = async (limitCount = 5, lastDocRef = null) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to fetch saved posts');

  try {
    let savedPostsQuery = query(
      collection(db, 'users', user.uid, 'savedPosts'),
      orderBy('createdAt', 'desc')
    );

    // Add pagination if lastDocRef is provided
    if (lastDocRef) {
      savedPostsQuery = query(
        savedPostsQuery,
        startAfter(lastDocRef),
        limit(limitCount)
      );
    } else {
      savedPostsQuery = query(
        savedPostsQuery,
        limit(limitCount)
      );
    }

    const querySnapshot = await getDocs(savedPostsQuery);
    
    // Get the last visible document for pagination
    const lastVisible = querySnapshot.docs.length > 0
      ? querySnapshot.docs[querySnapshot.docs.length - 1]
      : null;
    
    // Extract post IDs and saved post metadata
    const savedPostsData = querySnapshot.docs.map(doc => ({
      id: doc.id, // This is the post ID
      ...doc.data()
    }));
    
    // Get full post data for each saved post
    const postIds = savedPostsData.map(savedPost => savedPost.postId || savedPost.id);
    const fullPostsData = await getSpecificPostsByIds(postIds);
    
    // Merge saved post metadata with full post data
    // Ensure that all required fields exist, with defaults if missing
    const mergedPosts = savedPostsData.map(savedPost => {
      const fullPost = fullPostsData.find(post => post.id === (savedPost.postId || savedPost.id)) || {};
      
      // Create a complete post object with all required fields
      return {
        // Default post structure with empty arrays for collections
        objectID: savedPost.id || savedPost.postId,
        id: savedPost.id || savedPost.postId,
        name: savedPost.postTitle || fullPost.name || '',
        description: savedPost.postDescription || fullPost.description || '',
        userId: savedPost.ownerId || fullPost.userId || '',
        likes: fullPost.likes || [],
        comments: fullPost.comments || [],
        shares: fullPost.shares || 0,
        privacy: savedPost.visibility || fullPost.privacy || 'public',
        createdAt: fullPost.createdAt || savedPost.createdAt || Date.now(),
        updatedAt: fullPost.updatedAt || savedPost.updatedAt || Date.now(),
        link: fullPost.link || '',
        
        // Include all properties from the full post
        ...fullPost,
        
        // Override with saved post metadata if available
        ...savedPost
      };
    });
    
    return {
      savedPosts: mergedPosts,
      lastDocRef: lastVisible,
      hasMore: querySnapshot.docs.length === limitCount
    };
  } catch (error) {
    console.error('Error fetching saved posts:', error);
    throw new Error(error.message || 'Failed to fetch saved posts');
  }
};

/**
 * Get specific posts by ID
 */
export const getSpecificPostsByIds = async (postIds) => {
  if (!postIds.length) return [];
  
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to fetch posts');

  // Process in batches of 10 for Firestore's 'in' query limit
  const posts = [];
  for (let i = 0; i < postIds.length; i += 10) {
    const batchIds = postIds.slice(i, Math.min(i + 10, postIds.length));
    
    const q = query(
      collection(db, 'posts'),
      where('__name__', 'in', batchIds),
      orderBy('updatedAt', 'desc')
    );

    try {
      const querySnapshot = await getDocs(q);
      const batchPosts = querySnapshot.docs.map(doc => {
        const data = doc.data();
        // Convert Firestore Timestamp to JavaScript timestamp
        if (data.createdAt && typeof data.createdAt.toDate === 'function') {
          data.createdAt = data.createdAt.toDate().getTime();
        }
        return {
          id: doc.id,
          ...data
        };
      });
      posts.push(...batchPosts);
    } catch (error) {
      console.error(`Error fetching batch of posts:`, error);
    }
  }

  return posts;
};