// src/components/ImagePreviewModal.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { X, Download } from 'lucide-react';
import './ImagePreviewModal.css';

const ImagePreviewModal = ({ imageUrl, onClose }) => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  // Detect mobile devices and specifically iOS
  useEffect(() => {
    const checkDevice = () => {
      const userAgent = navigator.userAgent;
      const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      setIsMobile(mobileRegex.test(userAgent));

      // Rough check for iOS
      // (Note: iPadOS 13+ spoofs Mac, so you may refine detection if needed)
      const iOSRegex = /iPhone|iPad|iPod/i;
      setIsIOS(iOSRegex.test(userAgent));
    };
    
    checkDevice();
    window.addEventListener('resize', checkDevice);
    return () => window.removeEventListener('resize', checkDevice);
  }, []);

  // Handle ESC key press to close the modal
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    // Prevent body scrolling
    document.body.style.overflow = 'hidden';
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'auto';
    };
  }, [onClose]);

  // The core download (or share) logic
  const handleDownload = async () => {
    // 1) Desktop: immediate download
    if (!isMobile) {
      const link = document.createElement('a');
      link.href = imageUrl;

      // Best effort to extract a filename
      const filename = imageUrl.split('/').pop() || 'image';
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return;
    }

    // 2) iOS Mobile with Web Share API (iOS 15+)
    // Attempt to share the actual file to trigger native share sheet
    if (isIOS && navigator.canShare) {
      try {
        const response = await fetch(imageUrl, { mode: 'cors' });
        const blob = await response.blob();
        // Suggest a filename & type
        const file = new File([blob], 'photo.jpg', { type: blob.type || 'image/jpeg' });
        
        // Check if we can share the file
        if (navigator.canShare({ files: [file] })) {
          await navigator.share({ files: [file] });
          // Share completed: user stays in the modal
          return;
        }
      } catch (error) {
        // Differentiate user-cancel from actual error
        if (
          error.name === 'AbortError' ||
          error.name === 'NotAllowedError' ||
          /AbortError|NotAllowedError/i.test(error.toString())
        ) {
          // The user dismissed share sheet => do nothing; remain in modal
          console.log('Share canceled by user.');
          return;
        }
        console.warn('Web Share API error, will fallback:', error);
        // proceed to fallback
      }
    }

    // 3) Mobile fallback (any platform that doesn’t support file sharing):
    //    fetch, create a Blob URL, and force a download. iOS will typically open the share sheet.
    try {
      const resp = await fetch(imageUrl, { mode: 'cors' });
      const blob = await resp.blob();
      const url = URL.createObjectURL(blob);
      
      const link = document.createElement('a');
      link.href = url;
      link.download = 'photo.jpg';
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Fallback download failed:', err);
      // As a last resort, open image in a new tab:
      window.open(imageUrl, '_blank');
    }
  };

  if (!imageUrl) return null;

  return (
    <div className="image-preview-backdrop" onClick={onClose}>
      <div className="image-preview-content" onClick={(e) => e.stopPropagation()}>
        <button 
          className="close-button" 
          onClick={onClose} 
          aria-label="Close preview"
        >
          <X size={24} />
        </button>
        
        <div className="image-container">
          <img 
            src={imageUrl} 
            alt="Full size preview" 
            className="image-preview" 
            loading="eager"
            contextMenu="true"
          />
        </div>
        
        <button 
          className="download-button" 
          onClick={handleDownload}
          aria-label={t('common.download')}
        >
          <Download size={18} />
          <span>
            {isMobile ? t('common.open_image') : t('common.download')}
          </span>
        </button>
        
        {isMobile && (
          <p className="mobile-hint">
            {t('common.mobile_image_save_hint')}
          </p>
        )}
      </div>
    </div>
  );
};

export default ImagePreviewModal;