// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, connectAuthEmulator } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getMessaging } from "firebase/messaging";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { isFirebaseMessagingSupported } from './utils/featureDetection';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

// Initialize App Check - Add this BEFORE initializing other Firebase services
if (process.env.NODE_ENV === 'development') {
  // Enable debug token for development
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

// Initialize App Check with reCAPTCHA v3
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
  isTokenAutoRefreshEnabled: true
});

// Initialize other Firebase services AFTER App Check
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

export let messaging = null;

// Connect to Emulators in Development AFTER initializing services
if (process.env.NODE_ENV === 'development') {
  console.log("Connecting to Firebase Emulators...");
  try {
    // Make sure you have the Firebase CLI installed and emulators running
    // Run: firebase emulators:start
    connectAuthEmulator(auth, "http://localhost:9099");
    console.log("Auth Emulator connected.");
    connectFirestoreEmulator(db, "localhost", 8081);
    console.log("Firestore Emulator connected.");
    connectStorageEmulator(storage, "localhost", 9199);
    console.log("Storage Emulator connected.");
    connectFunctionsEmulator(functions, "localhost", 5001);
    console.log("Functions Emulator connected.");
  } catch (error) {
    console.error("Error connecting to Firebase Emulators:", error);
    console.warn("Ensure Firebase Emulators are running. Run 'firebase emulators:start' in your terminal.");
  }
}

(async () => {
  try {
    const messagingSupported = await isFirebaseMessagingSupported();
    if (messagingSupported) {
      messaging = getMessaging(app);
      console.log("Firebase Messaging initialized successfully.");
    } else {
      console.log("Firebase Messaging is not supported on this browser.");
    }
  } catch (error) {
    console.error("Error initializing Firebase Messaging:", error);
  }
})();

export { onAuthStateChanged };
export { app };