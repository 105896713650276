// src/components/ConfirmationModal.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import styles from './ConfirmationModal.module.css';

const ConfirmationModal = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  message, 
  title,
  confirmText,
  cancelText,
  isLoading = false
}) => {
  const { t } = useTranslation();
  
  if (!isOpen) return null;
  
  // Prevent clicks inside the modal from closing it
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div 
      className={styles.modalOverlay} 
      onClick={onClose}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div className={styles.modalContainer} onClick={handleModalClick}>
        <div className={styles.modalHeader}>
          <h3 id="modal-title" className={styles.modalTitle}>
            {title || t('common.confirm')}
          </h3>
          <button 
            className={styles.closeButton} 
            onClick={onClose}
            aria-label={t('common.close')}
          >
            &times;
          </button>
        </div>
        
        <div className={styles.modalContent}>
          <p>{message}</p>
        </div>
        
        <div className={styles.modalActions}>
          <Button 
            onClick={onClose} 
            variant="secondary"
            disabled={isLoading}
          >
            {cancelText || t('common.cancel')}
          </Button>
          <Button 
            onClick={onConfirm} 
            variant="danger"
            disabled={isLoading}
          >
            {isLoading ? (
              <span className={styles.spinner}></span>
            ) : (
              confirmText || t('common.confirm')
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;