// src/components/PostSkeleton.js
import React from 'react';
import styles from './PostSkeleton.module.css';

const PostSkeleton = ({ isDetailView = false }) => {
  return (
    <div className={`${styles.postSkeleton} ${isDetailView ? styles.detailView : ''}`}>
      <div className={styles.skeletonHeader}>
        <div className={styles.posterInfo}>
          <div className={`${styles.skeletonAvatar} ${styles.loadingShimmer}`}></div>
          <div className={styles.posterInfoText}>
            <div className={`${styles.skeletonName} ${styles.loadingShimmer}`}></div>
            <div className={`${styles.skeletonUsername} ${styles.loadingShimmer}`}></div>
          </div>
          <div className={`${styles.skeletonTime} ${styles.loadingShimmer}`}></div>
        </div>
        
        <div className={`${styles.skeletonTitle} ${styles.loadingShimmer}`}></div>
        
        <div className={styles.skeletonContent}>
          <div className={`${styles.skeletonParagraph} ${styles.loadingShimmer}`}></div>
          <div className={`${styles.skeletonParagraph} ${styles.loadingShimmer}`} style={{ width: '90%' }}></div>
          <div className={`${styles.skeletonParagraph} ${styles.loadingShimmer}`} style={{ width: '80%' }}></div>
        </div>
      </div>
      
      <div className={styles.skeletonActions}>
        <div className={`${styles.skeletonButton} ${styles.loadingShimmer}`}></div>
        <div className={`${styles.skeletonButton} ${styles.loadingShimmer}`}></div>
        <div className={`${styles.skeletonButton} ${styles.loadingShimmer}`}></div>
        <div className={`${styles.skeletonButton} ${styles.loadingShimmer}`}></div>
      </div>
    </div>
  );
};

export default PostSkeleton;