// src/services/notificationService.js

import { db, auth } from '../firebase';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  orderBy, 
  limit
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Note: createNotification function has been removed as notifications
// are now handled exclusively on the server side

/**
 * Retrieves notifications for the current user
 * @param {number} limitCount - Maximum number of notifications to retrieve
 * @returns {Promise<Array>} Array of notification objects
 */
export const getNotifications = async (limitCount = 20) => {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error('User must be logged in to get notifications');

  const q = query(
    collection(db, 'notifications'),
    where('userId', '==', currentUser.uid),
    where('type', '!=', 'group_message'), // Exclude 'group_message' notifications
    orderBy('createdAt', 'desc'),
    limit(limitCount)
  );

  const querySnapshot = await getDocs(q);
  // Ensure read status is properly included in the returned data
  return querySnapshot.docs.map(doc => {
    const data = doc.data();
    return { 
      id: doc.id, 
      ...data,
      read: data.read === true // Explicitly convert to boolean in case it's undefined
    };
  });
};

/**
 * Gets count of unread notifications for the current user
 * @returns {Promise<number>} Number of unread notifications
 */
export const getUnreadNotificationsCount = async () => {
  const currentUser = auth.currentUser;
  if (!currentUser) return 0;

  const q = query(
    collection(db, 'notifications'),
    where('userId', '==', currentUser.uid),
    where('read', '==', false),
    where('type', '!=', 'group_message') // Exclude group chat notifications
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.size;
};

/**
 * Marks all notifications for the current user as read
 * @returns {Promise<Object>} Success status and count of updated notifications
 */
export const markAllNotificationsAsRead = async () => {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error('User must be logged in to update notifications');

  const functions = getFunctions();
  const markNotifications = httpsCallable(functions, 'markAllNotificationsAsRead');

  try {
    const result = await markNotifications();
    console.log(`Marked ${result.data.count} notifications as read`);
    return result.data;
  } catch (error) {
    console.error('Error marking notifications as read', error);
    throw error;
  }
};