import { useState, useEffect, useCallback } from 'react';
import { auth } from '../firebase';
import { usePost } from './usePost';
import { getUserProfile } from '../services/userService';
import { checkIfPostIsSaved } from '../services/postService';

/**
 * Custom hook for managing a post item's state and operations
 * @param {Object} initialPost - Initial post data
 * @param {Function} onPostDeleted - Optional callback when post is deleted
 * @returns {Object} Post state and methods for interacting with it
 */
const usePostItem = (initialPost, onPostDeleted) => {
  // Normalize the post data to ensure all required fields exist
  const normalizedInitialPost = {
    likes: [],
    comments: [],
    privacy: 'public',
    createdAt: Date.now(),
    userId: '',
    ...initialPost
  };
  
  const [post, setPost] = useState(normalizedInitialPost);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [canInteract, setCanInteract] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [commenters, setCommenters] = useState({});
  const [posterInfo, setPosterInfo] = useState(null);
  const [shareMessage, setShareMessage] = useState('');
  const [submittingComment, setSubmittingComment] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { 
    updatePost, deletePost, likePost, unlikePost, 
    commentOnPost, sharePost, savePost, unsavePost, getPost 
  } = usePost();

  const postId = post.objectID || post.id;

  // Update local post when initialPost changes
  useEffect(() => {
    setPost({
      likes: [],
      comments: [],
      privacy: 'public',
      createdAt: Date.now(),
      userId: '',
      ...initialPost
    });
  }, [initialPost]);

  // Check if the current user can interact with this post based on privacy settings
  const checkUserPermissions = useCallback(async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      setIsCurrentUser(false);
      setCanInteract(false);
      setIsLoading(false);
      return;
    }

    setIsCurrentUser(currentUser.uid === post.userId);
    
    // Check permission based on privacy setting
    if (post.privacy === 'public') {
      setCanInteract(true);
    } else if (post.privacy === 'friends') {
      // Check if user is a friend of the post creator
      const userProfile = await getUserProfile(currentUser.uid);
      const friends = userProfile.friends || [];
      setCanInteract(friends.includes(post.userId) || currentUser.uid === post.userId);
    } else if (post.privacy === 'private' && post.fromGroupDraft && post.groupId) {
      // Check if user is a member of the group
      try {
        const { getGroupMembers } = await import('../services/groupService');
        const members = await getGroupMembers(post.groupId);
        const isMember = members.some(member => member.id === currentUser.uid);
        setCanInteract(isMember || currentUser.uid === post.userId);
      } catch (error) {
        console.error('Error checking group membership:', error);
        setCanInteract(currentUser.uid === post.userId);
      }
    } else {
      // For any other case, user can only interact if they are the post owner
      setCanInteract(currentUser.uid === post.userId);
    }

    // Check if the user has saved this post
    try {
      const postIsSaved = await checkIfPostIsSaved(postId);
      setIsSaved(postIsSaved);
    } catch (error) {
      console.error('Error checking if post is saved:', error);
      setIsSaved(false);
    }
    
    setIsLoading(false);
  }, [post.userId, post.privacy, post.fromGroupDraft, post.groupId, postId]);

  // Fetch usernames of all commenters
  const fetchCommentersUsernames = useCallback(async () => {
    if (!post.comments || post.comments.length === 0) {
      setCommenters({});
      return;
    }
    
    const commenterIds = [...new Set(post.comments.map(comment => comment.userId))];
    const fetchedCommenters = {};
  
    for (const id of commenterIds) {
      try {
        const userProfile = await getUserProfile(id);
        fetchedCommenters[id] = userProfile.username || 'Anonymous';
      } catch (error) {
        console.error(`Error fetching commenter info for user ${id}:`, error);
        fetchedCommenters[id] = 'Anonymous';
      }
    }
  
    setCommenters(fetchedCommenters);
  }, [post.comments]);

  // Fetch information about the post creator
  const fetchPosterInfo = useCallback(async () => {
    if (!post.userId) {
      setPosterInfo({
        displayName: 'Anonymous',
        username: 'anonymous',
        profilePictureUrl: null,
        id: post.userId || '',
        createdAt: post.createdAt
      });
      return;
    }

    try {
      const userProfile = await getUserProfile(post.userId);
      setPosterInfo({
        displayName: userProfile.displayName || 'Anonymous',
        username: userProfile.username || 'anonymous',
        profilePictureUrl: userProfile.profilePictureUrl || null,
        id: post.userId,
        createdAt: post.createdAt
      });
    } catch (error) {
      console.error('Error fetching poster info:', error);
      setPosterInfo({
        displayName: 'Anonymous',
        username: 'anonymous',
        profilePictureUrl: null,
        id: post.userId,
        createdAt: post.createdAt
      });
    }
  }, [post.userId, post.createdAt]);

  // Initialize the post data
  useEffect(() => {
    const initializePostItem = async () => {
      await Promise.all([
        checkUserPermissions(),
        fetchCommentersUsernames(),
        fetchPosterInfo()
      ]);
    };
    
    initializePostItem();
  }, [checkUserPermissions, fetchCommentersUsernames, fetchPosterInfo]);

  // Handle updating a post
  const handleUpdate = async (updatedPostData) => {
    try {
      setIsSaving(true);
      const updatedPost = await updatePost(postId, updatedPostData);
      setPost(updatedPost);
      return true;
    } catch (error) {
      console.error('Error updating post:', error);
      return false;
    } finally {
      setIsSaving(false);
    }
  };

  // Handle deleting a post
  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await deletePost(postId);
      if (onPostDeleted) {
        onPostDeleted(postId);
      }
      return true;
    } catch (error) {
      console.error('Error deleting post:', error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  // Handle toggling like status
  const handleLike = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return false;

    // Handle optimistic UI update locally
    const postLikes = post.likes || [];
    const newLikes = postLikes.includes(currentUser.uid)
      ? postLikes.filter(id => id !== currentUser.uid)
      : [...postLikes, currentUser.uid];
    
    setPost(prevPost => ({ ...prevPost, likes: newLikes }));
  
    try {
      // The server call is handled by the hook with its own optimistic update
      const updatedPost = await (postLikes.includes(currentUser.uid) 
        ? unlikePost(postId) 
        : likePost(postId));
        
      // Update with server response
      setPost(updatedPost);
      return true;
    } catch (error) {
      // If error occurs, the hook's optimistic update will be reverted
      console.error("Error toggling like:", error);
      return false;
    }
  };

  // Handle submitting a comment
  const handleComment = async (commentText) => {
    if (!commentText.trim() || submittingComment) return false;
    
    setSubmittingComment(true);
    
    // Optimistic local UI update
    const currentUser = auth.currentUser;
    if (!currentUser) {
      setSubmittingComment(false);
      return false;
    }

    const tempComment = {
      content: commentText,
      userId: currentUser.uid,
      createdAt: Date.now(),
      replies: []
    };
    
    // Update UI immediately
    setPost(prevPost => ({
      ...prevPost,
      comments: [...(prevPost.comments || []), tempComment]
    }));
    
    try {
      // Server update
      const updatedPost = await commentOnPost(postId, commentText);
      setPost(updatedPost);
      
      // Update commenter usernames
      await fetchCommentersUsernames();
      return true;
    } catch (error) {
      console.error('Error commenting on post:', error);
      // The hook's optimistic update will revert if server call fails
      return false;
    } finally {
      setSubmittingComment(false);
    }
  };

  // Handle sharing a post
  const handleShare = async () => {
    // 1. Generate the share URL
    const postUrl = `${window.location.origin}/post/${postId}`;
    
    // 2. Immediate user action: share or copy to clipboard
    try {
      if (navigator.share) {
        // Use Web Share API if available
        await navigator.share({ url: postUrl });
        setShareMessage('shared_success');
      } else if (navigator.clipboard?.writeText) {
        // Copy to clipboard if Web Share API not available
        await navigator.clipboard.writeText(postUrl);
        setShareMessage('copy_success');
      } else {
        // Fallback copy approach
        const textArea = document.createElement('textarea');
        textArea.value = postUrl;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        setShareMessage('copy_success');
      }
    } catch (shareError) {
      console.error('Error with native share/copy:', shareError);
      setShareMessage('share_error');
      setTimeout(() => setShareMessage(''), 3000);
      return false;
    }
  
    // 3. Kick off server update in the background
    try {
      const updatedPost = await sharePost(postId);
      setPost(updatedPost);
      setTimeout(() => setShareMessage(''), 3000);
      return true;
    } catch (error) {
      console.error('Error sharing post (server):', error);
      setTimeout(() => setShareMessage(''), 3000);
      return false;
    }
  };

  // Handle saving/unsaving a post
  const handleSave = async () => {
    // Optimistic update
    setIsSaved(prevState => !prevState);
  
    try {
      if (isSaved) {
        await unsavePost(postId);
      } else {
        await savePost(postId);
      }
      return true;
    } catch (error) {
      // Revert optimistic update on error
      setIsSaved(prevState => !prevState);
      console.error("Error toggling save:", error);
      return false;
    }
  };

  // Handle comment deletion (optimistic update)
  const handleCommentDelete = (commentIndex) => {
    // Optimistically update the local state to remove the comment
    setPost(prevPost => ({
      ...prevPost,
      comments: prevPost.comments.filter((_, idx) => idx !== commentIndex)
    }));
  };

  // Handle reply added and update post data
  const handleReplyAdded = async () => {
    try {
      // Fetch updated post data from server
      const updatedPost = await getPost(postId);
      setPost(updatedPost);
      // Update commenters info
      await fetchCommentersUsernames();
      return true;
    } catch (error) {
      console.error('Error refreshing post after reply:', error);
      return false;
    }
  };

  return {
    post,
    postId,
    isCurrentUser,
    canInteract,
    isLoading,
    isSaved,
    commenters,
    posterInfo,
    shareMessage,
    submittingComment,
    isSaving,
    
    // Methods
    handleUpdate,
    handleDelete,
    handleLike,
    handleComment,
    handleShare,
    handleSave,
    handleCommentDelete,
    handleReplyAdded,
    
    // Helper getters
    hasLiked: auth.currentUser ? post.likes?.includes(auth.currentUser.uid) : false,
    commentCount: post.comments?.length || 0,
    likeCount: post.likes?.length || 0,
  };
};

export default usePostItem; 