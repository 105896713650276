// src/services/friendshipService.js
import { auth, db } from '../firebase';
import { 
  doc, 
  getDoc, 
  getDocs,
  collection,
  query,
  where,
  updateDoc, 
  setDoc,
  arrayUnion,
  arrayRemove,
  serverTimestamp,
  onSnapshot
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Initialize Firebase Functions
const functions = getFunctions();
const acceptFriendRequestFunction = httpsCallable(functions, 'acceptFriendRequest');
const declineFriendRequestFunction = httpsCallable(functions, 'declineFriendRequest');

/**
 * Get all friends for the current user
 * @returns {Promise<Array>} List of friend objects with user data
 * @throws {Error} If friends cannot be fetched
 */
export const getFriends = async () => {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error('User not authenticated');

  try {
    const userDocRef = doc(db, 'users', currentUser.uid);
    const docSnap = await getDoc(userDocRef);
    
    if (!docSnap.exists()) {
      return [];
    }

    const userData = docSnap.data();
    const friendIds = userData.friends || [];

    // Fetch the friend documents
    const friendsData = await Promise.all(
      friendIds.map(async (friendId) => {
        const friendDoc = await getDoc(doc(db, 'users', friendId));
        return friendDoc.exists() ? { id: friendId, ...friendDoc.data() } : null;
      })
    );

    // Filter out any null documents
    return friendsData.filter(Boolean);
  } catch (error) {
    console.error('Error fetching friends:', error);
    throw error;
  }
};

/**
 * Subscribe to friends list with real-time updates
 * @param {Function} onFriendsUpdate - Callback function for friend list updates
 * @returns {Function} Unsubscribe function
 */
export const subscribeFriends = (onFriendsUpdate) => {
  const currentUser = auth.currentUser;
  if (!currentUser) {
    onFriendsUpdate([]);
    return () => {};
  }

  const userDocRef = doc(db, 'users', currentUser.uid);
  
  return onSnapshot(userDocRef, async (docSnapshot) => {
    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();
      const friendIds = userData.friends || [];
      const friendsLastViewedAt = userData.friendsLastViewedAt || {};
      
      try {
        const friendsData = await Promise.all(
          friendIds.map(async (friendId) => {
            const friendDoc = await getDoc(doc(db, 'users', friendId));
            if (friendDoc.exists()) {
              return { 
                id: friendId, 
                ...friendDoc.data(),
                lastViewed: friendsLastViewedAt[friendId] || null
              };
            }
            return null;
          })
        );
        
        // Filter out any null values from non-existent users
        onFriendsUpdate(friendsData.filter(Boolean));
      } catch (error) {
        console.error("Error fetching friend documents:", error);
        onFriendsUpdate([]);
      }
    } else {
      onFriendsUpdate([]);
    }
  }, (error) => {
    console.error("Error listening to friend updates:", error);
    onFriendsUpdate([]);
  });
};

/**
 * Get all friend requests for the current user
 * @returns {Promise<Array>} List of user objects who sent friend requests
 * @throws {Error} If requests cannot be fetched
 */
export const getFriendRequests = async () => {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error('User not authenticated');

  try {
    const userDocRef = doc(db, 'users', currentUser.uid);
    const docSnap = await getDoc(userDocRef);
    
    if (!docSnap.exists()) {
      return [];
    }

    const userData = docSnap.data();
    const requestIds = userData.friendRequests || [];
    
    const requestsData = await Promise.all(
      requestIds.map(async (requestId) => {
        const requestDoc = await getDoc(doc(db, 'users', requestId));
        return requestDoc.exists() ? { id: requestId, ...requestDoc.data() } : null;
      })
    );
    
    // Filter out any null values from non-existent users
    return requestsData.filter(Boolean);
  } catch (error) {
    console.error('Error fetching friend requests:', error);
    throw error;
  }
};

/**
 * Subscribe to friend requests with real-time updates
 * @param {Function} onRequestsUpdate - Callback function for request updates
 * @returns {Function} Unsubscribe function
 */
export const subscribeFriendRequests = (onRequestsUpdate) => {
  const currentUser = auth.currentUser;
  if (!currentUser) {
    onRequestsUpdate([]);
    return () => {};
  }

  const userDocRef = doc(db, 'users', currentUser.uid);
  
  return onSnapshot(userDocRef, async (docSnapshot) => {
    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();
      const requestIds = userData.friendRequests || [];
      
      try {
        const requestsData = await Promise.all(
          requestIds.map(async (requestId) => {
            const requestDoc = await getDoc(doc(db, 'users', requestId));
            return requestDoc.exists() ? { id: requestId, ...requestDoc.data() } : null;
          })
        );
        
        // Filter out any null values from non-existent users
        onRequestsUpdate(requestsData.filter(Boolean));
      } catch (error) {
        console.error("Error fetching friend requests:", error);
        onRequestsUpdate([]);
      }
    } else {
      onRequestsUpdate([]);
    }
  }, (error) => {
    console.error("Error listening to friend request updates:", error);
    onRequestsUpdate([]);
  });
};

/**
 * Send a friend request to another user
 * @param {string} userId - The user ID to send the request to
 * @returns {Promise<boolean>} Success status
 * @throws {Error} If the request cannot be sent
 */
export const sendFriendRequest = async (userId) => {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error('User not authenticated');

  try {
    // Add current user to the recipient's friendRequests array
    await setDoc(doc(db, 'users', userId), {
      friendRequests: arrayUnion(currentUser.uid)
    }, { merge: true });

    // Add recipient to current user's sentFriendRequests array
    await setDoc(doc(db, 'users', currentUser.uid), {
      sentFriendRequests: arrayUnion(userId)
    }, { merge: true });

    return true;
  } catch (error) {
    console.error('Error sending friend request:', error);
    throw error;
  }
};

/**
 * Accept a friend request
 * @param {string} requesterId - The user ID of the requester
 * @returns {Promise<Object>} Result with success status
 * @throws {Error} If the request cannot be accepted
 */
export const acceptFriendRequest = async (requesterId) => {
  try {
    const result = await acceptFriendRequestFunction({ requesterId });
    return result.data;
  } catch (error) {
    console.error('Error accepting friend request:', error);
    throw error;
  }
};

/**
 * Decline a friend request
 * @param {string} requesterId - The user ID of the requester
 * @returns {Promise<Object>} Result with success status
 * @throws {Error} If the request cannot be declined
 */
export const declineFriendRequest = async (requesterId) => {
  try {
    const result = await declineFriendRequestFunction({ requesterId });
    return result.data;
  } catch (error) {
    console.error('Error declining friend request:', error);
    throw error;
  }
};

/**
 * Remove a friend
 * @param {string} friendId - The user ID of the friend to remove
 * @returns {Promise<boolean>} Success status
 * @throws {Error} If the friend cannot be removed
 */
export const removeFriend = async (friendId) => {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error('User not authenticated');

  try {
    // Remove friend from current user's friends array
    await updateDoc(doc(db, 'users', currentUser.uid), {
      friends: arrayRemove(friendId)
    });

    // Remove current user from friend's friends array
    await updateDoc(doc(db, 'users', friendId), {
      friends: arrayRemove(currentUser.uid)
    });

    return true;
  } catch (error) {
    console.error('Error removing friend:', error);
    throw error;
  }
};

/**
 * Update the timestamp of when the current user last viewed a friend's profile
 * @param {string} friendId - The user ID of the friend
 * @returns {Promise<boolean>} Success status
 * @throws {Error} If the timestamp cannot be updated
 */
export const updateFriendLastViewed = async (friendId) => {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error('User not authenticated');

  try {
    const userDocRef = doc(db, 'users', currentUser.uid);
    const docSnap = await getDoc(userDocRef);
    
    if (!docSnap.exists()) {
      throw new Error('User document not found');
    }

    const userData = docSnap.data();
    const updatedFriendsLastViewedAt = {
      ...(userData.friendsLastViewedAt || {}),
      [friendId]: serverTimestamp(),
    };

    await updateDoc(userDocRef, {
      friendsLastViewedAt: updatedFriendsLastViewedAt,
    });

    return true;
  } catch (error) {
    console.error('Error updating last viewed timestamp:', error);
    throw error;
  }
};

/**
 * Check if a friend has new content since last viewed
 * @param {Object} friend - Friend object with lastPostUpdatedAt and id
 * @param {Object} friendsLastViewedAt - Object mapping friend IDs to timestamps
 * @returns {boolean} True if friend has new content
 */
export const shouldHighlightFriend = (friend, friendsLastViewedAt = {}) => {
  const lastViewed = friendsLastViewedAt[friend.id];
  const lastPostUpdatedAt = friend.lastPostUpdatedAt;

  if (lastPostUpdatedAt && lastViewed) {
    return lastPostUpdatedAt.toMillis() > lastViewed.toMillis();
  } else if (lastPostUpdatedAt && !lastViewed) {
    return true;
  } else {
    return false;
  }
};

/**
 * Check the friendship status between the current user and another user
 * @param {string} userId - The user ID to check relationship with
 * @returns {Promise<string>} Relationship status ('friends', 'requested', 'pending', 'none')
 * @throws {Error} If the status cannot be determined
 */
export const checkFriendshipStatus = async (userId) => {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error('User not authenticated');
  if (currentUser.uid === userId) return 'self';

  try {
    const [userDoc, targetUserDoc] = await Promise.all([
      getDoc(doc(db, 'users', currentUser.uid)),
      getDoc(doc(db, 'users', userId))
    ]);

    if (!userDoc.exists() || !targetUserDoc.exists()) {
      throw new Error('One or both user documents not found');
    }

    const userData = userDoc.data();

    // Check if they are friends
    if (userData.friends?.includes(userId)) {
      return 'friends';
    }
    
    // Check if current user sent a request to the target user
    if (userData.sentFriendRequests?.includes(userId)) {
      return 'requested';
    }
    
    // Check if target user sent a request to current user
    if (userData.friendRequests?.includes(userId)) {
      return 'pending';
    }
    
    // No relationship
    return 'none';
  } catch (error) {
    console.error('Error checking friendship status:', error);
    throw error;
  }
};

/**
 * Search for users by name, username, or phone number
 * @param {string} searchTerm - The term to search for
 * @returns {Promise<Array>} List of user objects matching the search
 * @throws {Error} If the search fails
 */
export const searchUsers = async (searchTerm) => {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error('User not authenticated');
  if (!searchTerm.trim()) return [];

  try {
    // Create queries for different search criteria
    const queries = [
      // Search by username (case-insensitive)
      query(collection(db, "users"), 
        where("username", ">=", searchTerm.toLowerCase()),
        where("username", "<=", searchTerm.toLowerCase() + '\uf8ff')
      ),
      // Search by display name
      query(collection(db, "users"), 
        where("displayName", ">=", searchTerm),
        where("displayName", "<=", searchTerm + '\uf8ff')
      ),
      // Search by phone number
      query(collection(db, "users"), 
        where("phoneNumber", ">=", searchTerm),
        where("phoneNumber", "<=", searchTerm + '\uf8ff')
      )
    ];

    const querySnapshots = await Promise.all(queries.map(q => getDocs(q)));
    const userMap = new Map();
    
    // Combine and deduplicate results
    for (const snapshot of querySnapshots) {
      snapshot.docs.forEach(doc => {
        if (doc.id !== currentUser.uid) { // Exclude current user
          userMap.set(doc.id, { id: doc.id, ...doc.data() });
        }
      });
    }

    // Get current user's friends and requests to calculate relationship status
    const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
    const currentUserData = currentUserDoc.data();
    const existingFriends = new Set(currentUserData.friends || []);
    const sentRequests = new Set(currentUserData.sentFriendRequests || []);
    const pendingRequests = new Set(currentUserData.friendRequests || []);

    // Add relationship status and filter out existing friends if needed
    const results = Array.from(userMap.values()).map(user => {
      let relationshipStatus = 'none';
      
      if (existingFriends.has(user.id)) {
        relationshipStatus = 'friends';
      } else if (sentRequests.has(user.id)) {
        relationshipStatus = 'requested';
      } else if (pendingRequests.has(user.id)) {
        relationshipStatus = 'pending';
      }
      
      return { ...user, relationshipStatus };
    });

    // Calculate mutual friends
    const resultsWithMutual = await Promise.all(
      results.map(async (user) => {
        const userFriends = new Set(user.friends || []);
        const mutualFriends = [...existingFriends].filter(id => userFriends.has(id)).length;
        return { ...user, mutualFriends };
      })
    );

    return resultsWithMutual;
  } catch (error) {
    console.error('Error searching users:', error);
    throw error;
  }
};