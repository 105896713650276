// src/components/ImageUploader.js

import React from 'react';
import { useTranslation } from 'react-i18next';

const ImageUploader = ({ onFilesSelected, fileInputRef, currentImageCount, maxImages }) => {
  const { t } = useTranslation();

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      // Calculate how many images can still be uploaded
      const availableSlots = maxImages - currentImageCount;
      if (availableSlots <= 0) {
        alert(t('chat.max_images_reached', { max: maxImages }));
        return;
      }

      // Limit the number of files the user can upload at once
      const filesToUpload = Array.from(files).slice(0, availableSlots);
      
      // Pass the files to parent component for handling upload
      onFilesSelected(filesToUpload);
      
      // Reset the file input value to allow re-uploading the same file if needed
      event.target.value = '';
    }
  };

  return (
    <input
      type="file"
      ref={fileInputRef}
      onChange={handleFileChange}
      accept="image/*"
      multiple // Allow multiple files
      style={{ display: 'none' }}
      aria-label={t('chat.upload_images')}
    />
  );
};

export default ImageUploader;