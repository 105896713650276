// src/hooks/usePostSubscription.js
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { getUserProfile } from '../services/userService';

export const usePostSubscription = (postId) => {
  const { t } = useTranslation();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!postId) {
      setIsLoading(false);
      setPost(null);
      setError(null);
      return;
    }

    setIsLoading(true);
    setError(null);
    const postRef = doc(db, 'posts', postId);

    const unsubscribe = onSnapshot(postRef, async (docSnap) => {
      try {
        if (docSnap.exists()) {
          const postData = docSnap.data();
          let authorData = null;

          // Fetch the user data for the post's author if userId exists
          if (postData.userId) {
             try {
                authorData = await getUserProfile(postData.userId);
             } catch (userError) {
                console.error(`Error fetching author profile for userId ${postData.userId}:`, userError);
                authorData = null;
             }
          } else {
            console.warn(`Post ${postId} is missing userId.`);
          }


          // Combine post data with author data
          setPost({
            id: docSnap.id,
            ...postData,
            createdAt: postData.createdAt?.toDate ? postData.createdAt.toDate().getTime() : postData.createdAt,
            updatedAt: postData.updatedAt?.toDate ? postData.updatedAt.toDate().getTime() : postData.updatedAt,
            author: authorData ? {
              id: authorData.id,
              username: authorData.username,
              displayName: authorData.displayName,
              profilePictureUrl: authorData.profilePictureUrl
            } : null
          });
          setError(null);
        } else {
          setPost(null);
          setError(t('posts.not_found'));
        }
      } catch (err) {
        console.error('Error processing post snapshot:', err);
        setPost(null);
        setError(t('posts.load_error'));
      } finally {
        setIsLoading(false);
      }
    }, (err) => {
      console.error('Error in post snapshot listener:', err);
      setPost(null);
      setError(t('posts.load_error'));
      setIsLoading(false);
    });

    // Cleanup function to unsubscribe from the listener when the hook unmounts or postId changes
    return () => unsubscribe();
  }, [postId, t]); // Re-run effect if postId or translation function changes

  return { post, isLoading, error };
};

export default usePostSubscription; 