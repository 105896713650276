// src/hooks/usePost.js
import { usePostContext } from '../contexts/PostContext';
import {
  updatePost as updatePostService,
  likePost as likePostService,
  unlikePost as unlikePostService,
  commentOnPost as commentOnPostService,
  replyToComment as replyToCommentService,
  deleteComment as deleteCommentService,
  deleteReply as deleteReplyService,
  deletePost as deletePostService,
  sharePost as sharePostService,
  savePost as savePostService,
  unsavePost as unsavePostService,
  getUserPosts as getUserPostsService,
  getUserSavedPosts as getUserSavedPostsService,
  getPost as getPostService,
  getSpecificPostsByIds as getSpecificPostsByIdsService,
} from '../services/postService';
import { auth } from '../firebase';

export function usePost() {
  const { posts, setPosts } = usePostContext();

  const updatePostInState = (updatedPost) => {
    setPosts(currentPosts =>
      currentPosts.map(post =>
        post.id === updatedPost.id || post.objectID === updatedPost.objectID 
          ? { ...post, ...updatedPost } 
          : post
      )
    );
    return updatedPost;
  };

  const findPostById = (postId) => {
    return posts.find(post => post.id === postId || post.objectID === postId);
  };

  const updatePost = async (postId, updateData) => {
    // Find the post to update
    const postToUpdate = findPostById(postId);
    if (!postToUpdate) {
      console.warn(`Post with ID ${postId} not found in state for optimistic update`);
      // If post not in state, proceed with server update without optimistic update
      try {
        const updatedPost = await updatePostService(postId, updateData);
        return updatePostInState(updatedPost);
      } catch (error) {
        console.error('Error updating post:', error);
        throw error;
      }
    }
    
    // Save the original post for potential rollback
    const originalPost = { ...postToUpdate };

    // Optimistic update
    setPosts(currentPosts => 
      currentPosts.map(post => 
        (post.id === postId || post.objectID === postId) 
          ? { ...post, ...updateData } 
          : post
      )
    );

    try {
      const updatedPost = await updatePostService(postId, updateData);
      return updatePostInState(updatedPost);
    } catch (error) {
      // Revert optimistic update on error
      setPosts(currentPosts => 
        currentPosts.map(post => 
          (post.id === postId || post.objectID === postId) 
            ? originalPost 
            : post
        )
      );
      console.error('Error updating post:', error);
      throw error;
    }
  };

  const likePost = async (postId) => {
    const user = auth.currentUser;
    if (!user) throw new Error('User must be logged in to like a post');
    
    // Find the post to update
    const postToUpdate = findPostById(postId);
    if (!postToUpdate) {
      // If post not in state, proceed with server update without optimistic update
      try {
        const updatedPost = await likePostService(postId);
        return updatePostInState(updatedPost);
      } catch (error) {
        console.error('Error liking post:', error);
        throw error;
      }
    }
    
    // Save the original post for potential rollback
    const originalPost = { ...postToUpdate };
    
    // Optimistic update
    const newLikes = [...(postToUpdate.likes || [])];
    if (!newLikes.includes(user.uid)) {
      newLikes.push(user.uid);
    }
    
    setPosts(currentPosts => 
      currentPosts.map(post => 
        (post.id === postId || post.objectID === postId) 
          ? { ...post, likes: newLikes } 
          : post
      )
    );

    try {
      const updatedPost = await likePostService(postId);
      return updatePostInState(updatedPost);
    } catch (error) {
      // Revert optimistic update on error
      setPosts(currentPosts => 
        currentPosts.map(post => 
          (post.id === postId || post.objectID === postId) 
            ? originalPost 
            : post
        )
      );
      console.error('Error liking post:', error);
      throw error;
    }
  };

  const unlikePost = async (postId) => {
    const user = auth.currentUser;
    if (!user) throw new Error('User must be logged in to unlike a post');
    
    // Find the post to update
    const postToUpdate = findPostById(postId);
    if (!postToUpdate) {
      // If post not in state, proceed with server update without optimistic update
      try {
        const updatedPost = await unlikePostService(postId);
        return updatePostInState(updatedPost);
      } catch (error) {
        console.error('Error unliking post:', error);
        throw error;
      }
    }
    
    // Save the original post for potential rollback
    const originalPost = { ...postToUpdate };
    
    // Optimistic update
    const newLikes = (postToUpdate.likes || []).filter(id => id !== user.uid);
    
    setPosts(currentPosts => 
      currentPosts.map(post => 
        (post.id === postId || post.objectID === postId) 
          ? { ...post, likes: newLikes } 
          : post
      )
    );

    try {
      const updatedPost = await unlikePostService(postId);
      return updatePostInState(updatedPost);
    } catch (error) {
      // Revert optimistic update on error
      setPosts(currentPosts => 
        currentPosts.map(post => 
          (post.id === postId || post.objectID === postId) 
            ? originalPost 
            : post
        )
      );
      console.error('Error unliking post:', error);
      throw error;
    }
  };

  const commentOnPost = async (postId, comment) => {
    const user = auth.currentUser;
    if (!user) throw new Error('User must be logged in to comment on a post');
    
    // Find the post to update
    const postToUpdate = findPostById(postId);
    if (!postToUpdate) {
      // If post not in state, proceed with server update without optimistic update
      try {
        const updatedPost = await commentOnPostService(postId, comment);
        return updatePostInState(updatedPost);
      } catch (error) {
        console.error('Error commenting on post:', error);
        throw error;
      }
    }
    
    // Create a temporary comment object for optimistic update
    const tempComment = {
      content: comment,
      userId: user.uid,
      createdAt: Date.now(),
      replies: []
    };
    
    // Save the original post for potential rollback
    const originalPost = { ...postToUpdate };
    
    // Optimistic update
    const updatedComments = [...(postToUpdate.comments || []), tempComment];
    
    setPosts(currentPosts => 
      currentPosts.map(post => 
        (post.id === postId || post.objectID === postId) 
          ? { ...post, comments: updatedComments } 
          : post
      )
    );

    try {
      const updatedPost = await commentOnPostService(postId, comment);
      return updatePostInState(updatedPost);
    } catch (error) {
      // Revert optimistic update on error
      setPosts(currentPosts => 
        currentPosts.map(post => 
          (post.id === postId || post.objectID === postId) 
            ? originalPost 
            : post
        )
      );
      console.error('Error commenting on post:', error);
      throw error;
    }
  };

  const replyToComment = async (postId, commentIndex, replyContent, replyToUserId = null) => {
    const user = auth.currentUser;
    if (!user) throw new Error('User must be logged in to reply to a comment');
    
    // Find the post to update
    const postToUpdate = findPostById(postId);
    if (!postToUpdate) {
      // If post not in state, proceed with server update without optimistic update
      try {
        const updatedPost = await replyToCommentService(postId, commentIndex, replyContent, replyToUserId);
        return updatePostInState(updatedPost);
      } catch (error) {
        console.error('Error replying to comment:', error);
        throw error;
      }
    }
    
    // Create a temporary reply for optimistic update
    const tempReply = {
      content: replyContent,
      userId: user.uid,
      createdAt: Date.now(),
      replyToUserId
    };
    
    // Save the original post for potential rollback
    const originalPost = { ...postToUpdate };
    
    // Optimistic update
    const updatedComments = [...(postToUpdate.comments || [])];
    if (updatedComments[commentIndex]) {
      if (!updatedComments[commentIndex].replies) {
        updatedComments[commentIndex].replies = [];
      }
      updatedComments[commentIndex].replies.push(tempReply);
    }
    
    setPosts(currentPosts => 
      currentPosts.map(post => 
        (post.id === postId || post.objectID === postId) 
          ? { ...post, comments: updatedComments } 
          : post
      )
    );

    try {
      const updatedPost = await replyToCommentService(postId, commentIndex, replyContent, replyToUserId);
      return updatePostInState(updatedPost);
    } catch (error) {
      // Revert optimistic update on error
      setPosts(currentPosts => 
        currentPosts.map(post => 
          (post.id === postId || post.objectID === postId) 
            ? originalPost 
            : post
        )
      );
      console.error('Error replying to comment:', error);
      throw error;
    }
  };

  const deleteComment = async (postId, commentIndex) => {
    const user = auth.currentUser;
    if (!user) throw new Error('User must be logged in to delete a comment');
    
    // Find the post to update
    const postToUpdate = findPostById(postId);
    if (!postToUpdate) {
      // If post not in state, proceed with server update without optimistic update
      try {
        const updatedPost = await deleteCommentService(postId, commentIndex);
        return updatePostInState(updatedPost);
      } catch (error) {
        console.error('Error deleting comment:', error);
        throw error;
      }
    }
    
    // Save the original post for potential rollback
    const originalPost = { ...postToUpdate };
    
    // Optimistic update
    const updatedComments = [...(postToUpdate.comments || [])];
    updatedComments.splice(commentIndex, 1);
    
    setPosts(currentPosts => 
      currentPosts.map(post => 
        (post.id === postId || post.objectID === postId) 
          ? { ...post, comments: updatedComments } 
          : post
      )
    );

    try {
      const updatedPost = await deleteCommentService(postId, commentIndex);
      return updatePostInState(updatedPost);
    } catch (error) {
      // Revert optimistic update on error
      setPosts(currentPosts => 
        currentPosts.map(post => 
          (post.id === postId || post.objectID === postId) 
            ? originalPost 
            : post
        )
      );
      console.error('Error deleting comment:', error);
      throw error;
    }
  };

  const deleteReply = async (postId, commentIndex, replyIndex) => {
    const user = auth.currentUser;
    if (!user) throw new Error('User must be logged in to delete a reply');
    
    // Find the post to update
    const postToUpdate = findPostById(postId);
    if (!postToUpdate) {
      // If post not in state, proceed with server update without optimistic update
      try {
        const updatedPost = await deleteReplyService(postId, commentIndex, replyIndex);
        return updatePostInState(updatedPost);
      } catch (error) {
        console.error('Error deleting reply:', error);
        throw error;
      }
    }
    
    // Save the original post for potential rollback
    const originalPost = { ...postToUpdate };
    
    // Optimistic update
    const updatedComments = [...(postToUpdate.comments || [])];
    if (updatedComments[commentIndex] && 
        updatedComments[commentIndex].replies && 
        updatedComments[commentIndex].replies.length > replyIndex) {
      updatedComments[commentIndex].replies.splice(replyIndex, 1);
    }
    
    setPosts(currentPosts => 
      currentPosts.map(post => 
        (post.id === postId || post.objectID === postId) 
          ? { ...post, comments: updatedComments } 
          : post
      )
    );

    try {
      const updatedPost = await deleteReplyService(postId, commentIndex, replyIndex);
      return updatePostInState(updatedPost);
    } catch (error) {
      // Revert optimistic update on error
      setPosts(currentPosts => 
        currentPosts.map(post => 
          (post.id === postId || post.objectID === postId) 
            ? originalPost 
            : post
        )
      );
      console.error('Error deleting reply:', error);
      throw error;
    }
  };

  const deletePost = async (postId) => {
    // Optimistic update
    const removedPost = findPostById(postId);
    if (removedPost) {
      setPosts(currentPosts => currentPosts.filter(post => 
        post.id !== postId && post.objectID !== postId
      ));
    }

    try {
      await deletePostService(postId);
    } catch (error) {
      // Revert optimistic update on error
      if (removedPost) {
        setPosts(currentPosts => [...currentPosts, removedPost]);
      }
      console.error('Error deleting post:', error);
      throw error;
    }
  };

  const sharePost = async (postId) => {
    // Find the post to update
    const postToUpdate = findPostById(postId);
    if (!postToUpdate) {
      // If post not in state, proceed with server update without optimistic update
      try {
        const updatedPost = await sharePostService(postId);
        return updatePostInState(updatedPost);
      } catch (error) {
        console.error('Error sharing post:', error);
        throw error;
      }
    }
    
    // Save the original post for potential rollback
    const originalPost = { ...postToUpdate };
    
    // Optimistic update
    const newShares = (postToUpdate.shares || 0) + 1;
    
    setPosts(currentPosts => 
      currentPosts.map(post => 
        (post.id === postId || post.objectID === postId) 
          ? { ...post, shares: newShares } 
          : post
      )
    );

    try {
      const updatedPost = await sharePostService(postId);
      return updatePostInState(updatedPost);
    } catch (error) {
      // Revert optimistic update on error
      setPosts(currentPosts => 
        currentPosts.map(post => 
          (post.id === postId || post.objectID === postId) 
            ? originalPost 
            : post
        )
      );
      console.error('Error sharing post:', error);
      throw error;
    }
  };

  const savePost = async (postId) => {
    try {
      const updatedUserData = await savePostService(postId);
      return updatedUserData;
    } catch (error) {
      console.error('Error saving post:', error);
      throw error;
    }
  };
  
  const unsavePost = async (postId) => {
    try {
      const updatedUserData = await unsavePostService(postId);
      return updatedUserData;
    } catch (error) {
      console.error('Error unsaving post:', error);
      throw error;
    }
  };

  const getPost = async (postId) => {
    try {
      const post = await getPostService(postId);
      
      // Update the post in state if it exists
      updatePostInState(post);
      
      return post;
    } catch (error) {
      console.error('Error getting post:', error);
      throw error;
    }
  };

  return {
    posts,
    setPosts,
    updatePost,
    likePost,
    unlikePost,
    commentOnPost,
    replyToComment,
    deleteComment,
    deleteReply,
    deletePost,
    sharePost,
    savePost,
    unsavePost,
    getUserPosts: getUserPostsService,
    getUserSavedPosts: getUserSavedPostsService,
    getSpecificPostsByIds: getSpecificPostsByIdsService,
    getPost,
  };
}