// src/services/groupChatService.js
import { auth, db, storage } from '../firebase';
import { 
  doc, getDoc, collection, addDoc, query, where, orderBy, 
  limit, onSnapshot, serverTimestamp, updateDoc, 
  getDocs, startAfter, GeoPoint 
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getLocation } from '../utils/location';
import { compressImage } from '../utils/imageCompression';

// Initialize Firebase Functions
const functions = getFunctions();
const difyGroupChat = httpsCallable(functions, 'difyGroupChatWorkflow');

// User data cache
const userCache = new Map();

/**
 * Subscribe to group messages
 * @param {string} groupId - Group ID
 * @param {Date} joinedAt - When user joined group
 * @param {number} messagesLimit - Number of messages to load
 * @param {Function} onUpdate - Callback for updates
 * @param {Function} onError - Callback for errors
 * @returns {Function} Unsubscribe function
 */
export const subscribeToGroupMessages = (groupId, joinedAt, messagesLimit = 50, onUpdate, onError) => {
  if (!groupId || !joinedAt || !auth.currentUser) {
    onUpdate({ messages: [], lastVisible: null, hasMore: false });
    return () => {};
  }

  const messagesRef = collection(db, `groups/${groupId}/messages`);
  const q = query(
    messagesRef,
    where('timestamp', '>=', joinedAt),
    orderBy('timestamp', 'desc'),
    limit(messagesLimit)
  );

  return onSnapshot(
    q,
    (querySnapshot) => {
      const messages = [];
      const senderIds = new Set();

      querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        messages.push({ id: docSnap.id, ...data });
        if (data.senderId) senderIds.add(data.senderId);
      });

      onUpdate({
        messages: messages.reverse(),
        lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
        hasMore: querySnapshot.docs.length === messagesLimit,
        senderIds: [...senderIds]
      });
    },
    (error) => {
      console.error('Error subscribing to group messages:', error);
      if (onError) onError(error);
    }
  );
};

/**
 * Load more group messages (pagination)
 * @param {string} groupId - Group ID
 * @param {Date} joinedAt - When user joined group
 * @param {Object} lastVisible - Last document snapshot
 * @param {number} messagesLimit - Number of messages to load
 * @returns {Promise<Object>} Messages data with pagination info
 */
export const loadMoreGroupMessages = async (groupId, joinedAt, lastVisible, messagesLimit = 50) => {
  if (!groupId || !joinedAt || !lastVisible || !auth.currentUser) {
    return { messages: [], lastVisible: null, hasMore: false, senderIds: [] };
  }

  try {
    const messagesRef = collection(db, `groups/${groupId}/messages`);
    const q = query(
      messagesRef,
      where('timestamp', '>=', joinedAt),
      orderBy('timestamp', 'desc'),
      startAfter(lastVisible),
      limit(messagesLimit)
    );

    const querySnapshot = await getDocs(q);
    const messages = [];
    const senderIds = new Set();

    querySnapshot.forEach((docSnap) => {
      const data = docSnap.data();
      messages.push({ id: docSnap.id, ...data });
      if (data.senderId) senderIds.add(data.senderId);
    });

    return {
      messages: messages.reverse(),
      lastVisible: querySnapshot.docs.length > 0 ? 
        querySnapshot.docs[querySnapshot.docs.length - 1] : null,
      hasMore: querySnapshot.docs.length === messagesLimit,
      senderIds: [...senderIds]
    };
  } catch (error) {
    console.error('Error loading more group messages:', error);
    throw error;
  }
};

/**
 * Upload images for group chat
 * @param {string} groupId - Group ID
 * @param {Array<File>} files - Array of file objects
 * @returns {Promise<Array>} Uploaded image data
 */
export const uploadGroupChatImages = async (groupId, files) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User not authenticated');

  try {
    const imagePromises = Array.from(files).map(async (file) => {
      // Compress the image before uploading
      const compressedFile = await compressImage(file);
      
      // Use the same filename format as the ImageUploader component
      const fileName = `${Date.now()}_${file.name}`;
      
      // Use the same path structure as ImageUploader component
      const storagePath = `group_chat_images/${groupId}/${fileName}`;
      const storageRef = ref(storage, storagePath);
      
      // Define custom metadata
      const customMetadata = {
        uploaderId: user.uid,
        uploadTime: new Date().toISOString(),
      };
      
      // Upload with metadata
      await uploadBytes(storageRef, compressedFile, { customMetadata });
      const downloadUrl = await getDownloadURL(storageRef);
      
      return {
        url: downloadUrl,
        name: fileName,
        type: file.type,
        metadata: customMetadata
      };
    });

    return Promise.all(imagePromises);
  } catch (error) {
    console.error('Error uploading group chat images:', error);
    throw error;
  }
};

/**
 * Send a message in a group chat
 * @param {string} groupId - Group ID
 * @param {string} text - Message text
 * @param {Array} images - Array of image objects
 * @param {string} replyToId - ID of message being replied to
 * @returns {Promise<Object>} Message reference
 */
export const sendGroupMessage = async (groupId, text, images = [], replyToId = null) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User not authenticated');

  try {
    // Get location data
    const location = await getLocation();

    // Prepare message data
    const messageData = {
      text,
      senderId: user.uid,
      timestamp: serverTimestamp(),
      images: images.length > 0 ? images : null,
      type: 'user',
      location: location ? new GeoPoint(location.lat, location.lng) : null,
      inReplyTo: replyToId
    };

    // Add message to group
    const messagesRef = collection(db, `groups/${groupId}/messages`);
    const newMsgRef = await addDoc(messagesRef, messageData);

    // Update group with last message info
    const groupRef = doc(db, 'groups', groupId);
    await updateDoc(groupRef, {
      lastMessage: text || (images.length > 0 ? 'Image' : ''),
      lastMessageAt: serverTimestamp(),
      lastMessageSenderId: user.uid,
      lastMessageSenderName: user.displayName || 'Unknown',
      lastActivityAt: serverTimestamp(),
    });

    return { id: newMsgRef.id };
  } catch (error) {
    console.error('Error sending group message:', error);
    throw error;
  }
};

/**
 * Mark group messages as read
 * @param {string} groupId - Group ID
 * @returns {Promise<boolean>} Success status
 */
export const markGroupMessagesAsRead = async (groupId) => {
  const user = auth.currentUser;
  if (!user) return false;
  
  try {
    const memberRef = doc(db, `groups/${groupId}/members/${user.uid}`);
    await updateDoc(memberRef, {
      unreadCount: 0,
      lastReadAt: serverTimestamp()
    });
    return true;
  } catch (error) {
    console.error('Error marking messages as read:', error);
    return false;
  }
};

/**
 * Toggle notification settings for a group
 * @param {string} groupId - Group ID
 * @param {boolean} enabled - Whether notifications should be enabled
 * @returns {Promise<boolean>} Success status
 */
export const toggleGroupNotifications = async (groupId, enabled) => {
  const user = auth.currentUser;
  if (!user) return false;
  
  try {
    const memberRef = doc(db, `groups/${groupId}/members/${user.uid}`);
    await updateDoc(memberRef, {
      notificationsEnabled: enabled
    });
    return true;
  } catch (error) {
    console.error('Error toggling notifications:', error);
    return false;
  }
};

/**
 * Toggle bot response settings for a group
 * @param {string} groupId - Group ID
 * @param {boolean} enabled - Whether bot responses should be enabled
 * @returns {Promise<boolean>} Success status
 */
export const toggleGroupBotResponses = async (groupId, enabled) => {
  const user = auth.currentUser;
  if (!user) return false;
  
  try {
    const memberRef = doc(db, `groups/${groupId}/members/${user.uid}`);
    await updateDoc(memberRef, {
      botResponsesEnabled: enabled
    });
    return true;
  } catch (error) {
    console.error('Error toggling bot responses:', error);
    return false;
  }
};

/**
 * Trigger a bot response to a group message
 * @param {string} groupId - Group ID
 * @param {string} messageId - Message ID bot is responding to
 * @param {string} query - User's text query
 * @param {Array} images - Array of image objects
 */
export const triggerBotResponse = async (groupId, messageId, query, images = []) => {
  try {
    await difyGroupChat({
      query,
      groupId,
      images,
      messageId,
    });
  } catch (error) {
    console.error('Error triggering bot response:', error);
  }
};

/**
 * Get user data for a specific user
 * @param {string} userId - User ID
 * @returns {Promise<Object>} User data (displayName, profilePictureUrl)
 */
export const getUserData = async (userId) => {
  // Return from cache if available
  if (userCache.has(userId)) {
    return userCache.get(userId);
  }

  try {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);
    
    if (userSnap.exists()) {
      const userData = {
        displayName: userSnap.data().displayName,
        profilePictureUrl: userSnap.data().profilePictureUrl
      };
      
      // Update cache
      userCache.set(userId, userData);
      return userData;
    }
    
    return null;
  } catch (error) {
    console.error(`Error fetching user data for ${userId}:`, error);
    return null;
  }
};

/**
 * Get user data for multiple users
 * @param {Array<string>} userIds - Array of user IDs
 * @returns {Promise<Object>} Map of user IDs to user data
 */
export const getUsersData = async (userIds) => {
  if (!userIds || userIds.length === 0) return {};

  const uniqueIdsToFetch = [...new Set(userIds)].filter(id => !userCache.has(id));
  
  if (uniqueIdsToFetch.length === 0) {
    // Return data from cache
    return userIds.reduce((result, id) => {
      result[id] = userCache.get(id);
      return result;
    }, {});
  }

  try {
    const userData = {};
    
    // Fetch data in parallel
    await Promise.all(uniqueIdsToFetch.map(async (id) => {
      const data = await getUserData(id);
      if (data) {
        userData[id] = data;
      }
    }));
    
    // Return combined results
    return userIds.reduce((result, id) => {
      result[id] = userCache.get(id) || userData[id] || null;
      return result;
    }, {});
  } catch (error) {
    console.error('Error fetching multiple users data:', error);
    return {};
  }
};

/**
 * Subscribe to a specific group
 * @param {string} groupId - Group ID
 * @param {Function} onUpdate - Callback for group data updates
 * @param {Function} onError - Callback for errors
 * @returns {Function} Unsubscribe function
 */
export const subscribeToGroup = (groupId, onUpdate, onError) => {
  if (!groupId || !auth.currentUser) {
    return () => {};
  }

  try {
    const groupRef = doc(db, 'groups', groupId);
    return onSnapshot(
      groupRef,
      (snapshot) => {
        if (snapshot.exists()) {
          onUpdate({
            id: snapshot.id,
            ...snapshot.data()
          });
        }
      },
      (error) => {
        console.error('Error subscribing to group:', error);
        if (onError) onError(error);
      }
    );
  } catch (error) {
    console.error('Error setting up group subscription:', error);
    if (onError) onError(error);
    return () => {};
  }
};

/**
 * Subscribe to member data for a group
 * @param {string} groupId - Group ID 
 * @param {Function} onUpdate - Callback for member data updates
 * @param {Function} onError - Callback for errors
 * @returns {Function} Unsubscribe function
 */
export const subscribeToMembership = (groupId, onUpdate, onError) => {
  const user = auth.currentUser;
  if (!groupId || !user) {
    return () => {};
  }

  try {
    const memberRef = doc(db, `groups/${groupId}/members/${user.uid}`);
    return onSnapshot(
      memberRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const memberData = docSnap.data();
          onUpdate({
            role: memberData.role,
            joinedAt: memberData.joinedAt ? memberData.joinedAt.toDate() : null,
            notificationsEnabled: memberData.notificationsEnabled !== false,
            botResponsesEnabled: memberData.botResponsesEnabled !== false
          });
        } else {
          if (onError) onError(new Error('Not a member of this group'));
        }
      },
      (error) => {
        console.error('Error subscribing to member data:', error);
        if (onError) onError(error);
      }
    );
  } catch (error) {
    console.error('Error setting up membership subscription:', error);
    if (onError) onError(error);
    return () => {};
  }
}; 