// src/services/groupService.js
import { auth, db, storage } from '../firebase';
import { 
  doc, 
  getDoc,
  getDocs,
  collection,
  writeBatch,
  serverTimestamp,
  arrayUnion,
  updateDoc,
  arrayRemove,
  query,
  where,
  orderBy,
  onSnapshot,
  collectionGroup
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

/**
 * Subscribe to groups where the user is a member
 * @param {Function} onUpdate - Callback for updates
 * @param {Function} onError - Callback for errors
 * @returns {Function} Unsubscribe function
 */
export const subscribeToUserGroups = (onUpdate, onError) => {
  if (!auth.currentUser) {
    onUpdate([]);
    return () => {};
  }

  const userId = auth.currentUser.uid;
  const groupsQuery = query(
    collection(db, 'groups'),
    where('members', 'array-contains', userId),
    orderBy('lastActivityAt', 'desc')
  );

  return onSnapshot(
    groupsQuery,
    async (snapshot) => {
      try {
        // Get group data from snapshot
        const groupsList = await Promise.all(
          snapshot.docs.map(async (docSnap) => {
            const groupData = { id: docSnap.id, ...docSnap.data() };

            // Fetch lastReadAt and unreadCount from the member document
            const memberRef = doc(db, `groups/${groupData.id}/members/${userId}`);
            const memberSnap = await getDoc(memberRef);

            const lastReadAt = memberSnap.exists()
              ? memberSnap.data().lastReadAt?.toDate() || memberSnap.data().joinedAt?.toDate() || new Date(0)
              : new Date(0);

            const unreadCount = memberSnap.exists() ? memberSnap.data().unreadCount || 0 : 0;
            const notificationsEnabled = memberSnap.exists() ? memberSnap.data().notificationsEnabled ?? true : true;

            return {
              ...groupData,
              lastReadAt,
              unreadCount,
              notificationsEnabled
            };
          })
        );

        onUpdate(groupsList);
      } catch (err) {
        console.error('Error processing groups:', err);
        if (onError) onError(err);
      }
    },
    (err) => {
      console.error('Error subscribing to groups:', err);
      if (onError) onError(err);
    }
  );
};

/**
 * Subscribe to unread group count for the current user
 * @param {Function} onUpdate - Callback with the unread count
 * @param {Function} onError - Callback for errors
 * @returns {Function} Unsubscribe function
 */
export const subscribeToUnreadGroupCount = (onUpdate, onError) => {
  if (!auth.currentUser) {
    onUpdate(0);
    return () => {};
  }

  const userId = auth.currentUser.uid;
  
  // Query all member documents for the current user with unreadCount > 0
  const q = query(
    collectionGroup(db, 'members'),
    where('userId', '==', userId),
    where('unreadCount', '>', 0)
  );

  return onSnapshot(
    q,
    (querySnapshot) => {
      onUpdate(querySnapshot.size);
    },
    (error) => {
      console.error('Error fetching unread group count:', error);
      if (onError) onError(error);
      onUpdate(0);
    }
  );
};

/**
 * Reset unread count for a user in a group
 * @param {string} groupId - The group ID
 * @returns {Promise<boolean>} Success status
 */
export const resetUnreadCount = async (groupId) => {
  if (!auth.currentUser) return false;
  
  try {
    const userId = auth.currentUser.uid;
    const memberRef = doc(db, `groups/${groupId}/members/${userId}`);
    
    await updateDoc(memberRef, {
      unreadCount: 0,
      lastReadAt: serverTimestamp()
    });
    
    return true;
  } catch (error) {
    console.error('Error resetting unread count:', error);
    return false;
  }
};

/**
 * Create a new group
 * @param {string} name - Group name
 * @param {File} iconFile - Group icon file
 * @param {Array} memberIds - Member IDs to add to group
 * @returns {Promise<Object>} Newly created group
 * @throws {Error} If group creation fails
 */
export const createGroup = async (name, iconFile, memberIds) => {
  if (!name.trim() && memberIds.length === 0) {
    throw new Error('Group name or members are required');
  }

  try {
    const userId = auth.currentUser.uid;

    // Include the creator's UID and selected members' UIDs
    const allMemberIds = [userId, ...memberIds];

    // Define group data
    const groupData = {
      name: name.trim() || generateDefaultGroupName(memberIds),
      icon: null, // Will update after upload
      createdBy: userId,
      createdAt: serverTimestamp(),
      lastActivityAt: serverTimestamp(),
      memberCount: allMemberIds.length,
      members: allMemberIds,
    };

    // Initialize a new batch
    const batch = writeBatch(db);

    // Create a new group reference with a unique ID
    const groupRef = doc(collection(db, 'groups'));
    
    // Add the group document to the batch
    batch.set(groupRef, groupData);

    // Add the creator as a member with admin role
    const creatorMemberRef = doc(db, `groups/${groupRef.id}/members`, userId);
    batch.set(creatorMemberRef, {
      userId: userId,
      joinedAt: serverTimestamp(),
      lastReadAt: serverTimestamp(),
      role: 'admin',
      notificationsEnabled: true,
      unreadCount: 0
    });

    // Add selected members
    memberIds.forEach(memberId => {
      const memberRef = doc(db, `groups/${groupRef.id}/members`, memberId);
      batch.set(memberRef, {
        userId: memberId,
        joinedAt: serverTimestamp(),
        lastReadAt: serverTimestamp(),
        role: 'member',
        notificationsEnabled: true,
        unreadCount: 0
      });
    });

    // Commit the batch
    await batch.commit();

    // Upload the icon if provided
    let iconUrl = null;
    if (iconFile) {
      const iconRef = ref(storage, `group-icons/${groupRef.id}/${Date.now()}-${iconFile.name}`);
      await uploadBytes(iconRef, iconFile);
      iconUrl = await getDownloadURL(iconRef);

      // Update the group document with the icon URL
      await updateDoc(groupRef, { icon: iconUrl });
    }

    // Return the created group
    return {
      id: groupRef.id,
      ...groupData,
      icon: iconUrl,
      createdAt: new Date(),
      lastActivityAt: new Date()
    };
  } catch (error) {
    console.error('Error creating group:', error);
    throw error;
  }
};

/**
 * Get a group by ID
 * @param {string} groupId - Group ID
 * @returns {Promise<Object>} Group data
 * @throws {Error} If group cannot be found
 */
export const getGroupById = async (groupId) => {
  try {
    const groupDoc = await getDoc(doc(db, 'groups', groupId));
    
    if (!groupDoc.exists()) {
      throw new Error('Group not found');
    }

    return {
      id: groupDoc.id,
      ...groupDoc.data()
    };
  } catch (error) {
    console.error('Error loading group:', error);
    throw error;
  }
};

/**
 * Get group members
 * @param {string} groupId - Group ID
 * @returns {Promise<Array>} Group members with user data
 * @throws {Error} If members cannot be fetched
 */
export const getGroupMembers = async (groupId) => {
  try {
    const membersRef = collection(db, `groups/${groupId}/members`);
    const membersSnap = await getDocs(membersRef);
    
    const memberPromises = membersSnap.docs.map(async (memberDoc) => {
      const memberData = memberDoc.data();
      const userId = memberDoc.id;
      
      // Get user data
      const userRef = doc(db, 'users', userId);
      const userSnap = await getDoc(userRef);
      const userData = userSnap.exists() ? userSnap.data() : {};
      
      return {
        id: userId,
        userId: userId,
        ...memberData,
        displayName: userData.displayName || 'Anonymous',
        profilePictureUrl: userData.profilePictureUrl
      };
    });
    
    return Promise.all(memberPromises);
  } catch (error) {
    console.error('Error loading members:', error);
    throw error;
  }
};

/**
 * Update group settings
 * @param {string} groupId - Group ID
 * @param {Object} groupData - Group data to update
 * @param {File} iconFile - New group icon (optional)
 * @returns {Promise<Object>} Updated group data
 * @throws {Error} If update fails
 */
export const updateGroupSettings = async (groupId, groupData, iconFile) => {
  try {
    let iconUrl = groupData.icon;
    
    // Upload new icon if provided
    if (iconFile) {
      const iconRef = ref(storage, `group-icons/${groupId}/${Date.now()}-${iconFile.name}`);
      await uploadBytes(iconRef, iconFile);
      iconUrl = await getDownloadURL(iconRef);
    }
    
    const updates = {
      ...groupData,
      icon: iconUrl,
      updatedAt: serverTimestamp(),
      lastActivityAt: serverTimestamp()
    };
    
    await updateDoc(doc(db, 'groups', groupId), updates);
    
    return {
      id: groupId,
      ...updates,
      updatedAt: new Date(),
      lastActivityAt: new Date()
    };
  } catch (error) {
    console.error('Error updating group:', error);
    throw error;
  }
};

/**
 * Toggle notification settings for a user in a group
 * @param {string} groupId - Group ID
 * @param {string} userId - User ID
 * @param {boolean} enabled - Whether notifications should be enabled
 * @returns {Promise<boolean>} Success status
 * @throws {Error} If toggle fails
 */
export const toggleGroupNotifications = async (groupId, userId, enabled) => {
  try {
    const memberRef = doc(db, `groups/${groupId}/members/${userId}`);
    await updateDoc(memberRef, {
      notificationsEnabled: enabled
    });
    return true;
  } catch (error) {
    console.error('Error toggling notifications:', error);
    throw error;
  }
};

/**
 * Remove a member from a group
 * @param {Object} group - Group data
 * @param {string} memberId - Member ID to remove
 * @returns {Promise<Object>} Updated group
 * @throws {Error} If member removal fails
 */
export const removeGroupMember = async (group, memberId) => {
  try {
    const batch = writeBatch(db);
    
    // Delete member document
    batch.delete(doc(db, `groups/${group.id}/members/${memberId}`));
    
    // Update group member count and members array
    const groupRef = doc(db, 'groups', group.id);
    batch.update(groupRef, {
      memberCount: group.memberCount - 1,
      members: arrayRemove(memberId),
      lastActivityAt: serverTimestamp()
    });

    await batch.commit();

    return {
      ...group,
      memberCount: group.memberCount - 1,
      members: group.members.filter(id => id !== memberId),
      lastActivityAt: new Date()
    };
  } catch (error) {
    console.error('Error removing member:', error);
    throw error;
  }
};

/**
 * Leave a group (current user)
 * @param {Object} group - Group data
 * @returns {Promise<boolean>} Success status
 * @throws {Error} If leaving fails
 */
export const leaveGroup = async (group) => {
  const userId = auth.currentUser.uid;
  try {
    await removeGroupMember(group, userId);
    return true;
  } catch (error) {
    console.error('Error leaving group:', error);
    throw error;
  }
};

/**
 * Add members to a group
 * @param {Object} group - Group data
 * @param {Array} memberIds - Array of user IDs to add as members
 * @returns {Promise<Array>} Added members with user data
 * @throws {Error} If members cannot be added
 */
export const addGroupMembers = async (group, memberIds) => {
  if (!group || !group.id || memberIds.length === 0) {
    throw new Error('Invalid group or no members to add');
  }

  try {
    const batch = writeBatch(db);
    
    // Add each selected friend as a member
    memberIds.forEach(friendId => {
      const memberRef = doc(db, `groups/${group.id}/members/${friendId}`);
      batch.set(memberRef, {
        userId: friendId,
        joinedAt: serverTimestamp(),
        lastReadAt: serverTimestamp(),
        role: 'member',
        notificationsEnabled: true,
        unreadCount: 0
      });
    });

    // Update group member count and members array
    const groupRef = doc(db, 'groups', group.id);
    batch.update(groupRef, {
      memberCount: group.memberCount + memberIds.length,
      members: arrayUnion(...memberIds),
      lastActivityAt: serverTimestamp()
    });

    await batch.commit();

    // Get the user data for added members
    const addedMembers = await Promise.all(
      memberIds.map(async (userId) => {
        const userDoc = await getDoc(doc(db, 'users', userId));
        const userData = userDoc.exists() ? userDoc.data() : {};
        
        return {
          id: userId,
          userId: userId,
          role: 'member',
          joinedAt: new Date(),
          lastReadAt: new Date(),
          displayName: userData.displayName || 'Anonymous',
          profilePicture: userData.profilePicture
        };
      })
    );

    return addedMembers;
  } catch (error) {
    console.error('Error adding members:', error);
    throw error;
  }
};

/**
 * Get available friends for a group (friends who aren't members)
 * @param {Array} friends - User's friends list
 * @param {Array} existingMembers - Current group members
 * @returns {Array} Friends who aren't members
 */
export const getAvailableFriendsForGroup = (friends, existingMembers) => {
  if (!friends || !existingMembers) return [];
  
  const existingMemberIds = existingMembers.map(member => member.userId);
  return friends.filter(friend => !existingMemberIds.includes(friend.id));
};

/**
 * Format relative time for a group message
 * @param {Date} date - Date to format
 * @returns {string} Formatted time
 */
export const formatRelativeTime = (date) => {
  if (!date) return 'Never';
  
  const now = new Date();
  const diff = now - date;
  const minutes = Math.floor(diff / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days}d`;
  if (hours > 0) return `${hours}h`;
  if (minutes > 0) return `${minutes}m`;
  return 'Just now';
};

/**
 * Get initials from a name
 * @param {string} name - Name to get initials from
 * @returns {string} Initials (up to 2 characters)
 */
export const getGroupInitials = (name) => {
  if (!name) return '';
  return name
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);
};

/**
 * Generate a default group name from member names
 * @param {Array} memberIds - Member IDs to include in the name
 * @returns {string} Generated group name
 */
const generateDefaultGroupName = (memberIds) => {
  // Implementation would need access to member names
  // This is a simplified placeholder that would be replaced
  // with actual logic based on your app's requirements
  return "New Group";
};

/**
 * Fetch and verify post IDs associated with a specific group.
 * Gets post IDs from the group's 'posts' subcollection, ordered by creation time,
 * and verifies that each corresponding post document exists in the main 'posts' collection.
 * 
 * @param {string} groupId - The ID of the group.
 * @returns {Promise<string[]>} A promise that resolves to an array of verified post IDs.
 * @throws {Error} If fetching or verification fails.
 */
export const getVerifiedGroupPostIds = async (groupId) => {
  if (!groupId) {
    throw new Error("Group ID is required to fetch posts.");
  }

  try {
    // 1. Get post references from the group's posts subcollection
    const groupPostsRef = collection(db, `groups/${groupId}/posts`);
    const q = query(groupPostsRef, orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);

    // 2. Verify each post exists in the main 'posts' collection
    const postPromises = querySnapshot.docs.map(async (docSnap) => {
      const postId = docSnap.data().postId;
      if (!postId) return null; // Skip if postId is missing for some reason

      const postRef = doc(db, 'posts', postId);
      const postSnap = await getDoc(postRef);
      
      // Only return the ID if the post document actually exists
      return postSnap.exists() ? postId : null;
    });

    // 3. Wait for all verifications and filter out nulls (non-existent posts)
    const verifiedIds = (await Promise.all(postPromises)).filter(id => id !== null);
    return verifiedIds;

  } catch (error) {
    console.error(`Error fetching verified posts for group ${groupId}:`, error);
    // Re-throw the error to be caught by the calling hook/component
    throw new Error('Failed to load group posts. Please try again.'); 
  }
}; 