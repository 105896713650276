// src/pages/GroupPage.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useGroups from '../hooks/useGroups';
import GroupList from '../components/GroupList';
import GroupCreateModal from '../components/GroupCreateModal';
import '../styles/GroupPage.css';

const GroupPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { error: groupsError } = useGroups();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [error, setError] = useState(null);

  // Set error from hook
  useEffect(() => {
    if (groupsError) {
      setError(groupsError);
    }
  }, [groupsError]);

  const handleCreateClick = () => {
    setIsCreateModalOpen(true);
  };

  const handleModalClose = () => {
    setIsCreateModalOpen(false);
  };

  const handleGroupCreated = (newGroup) => {
    setIsCreateModalOpen(false);
    // Navigate to the newly created group
    navigate(`/groups/${newGroup.id}`);
  };

  return (
    <div className="group-page">
      {error && (
        <div className="error-banner">
          <p>{error}</p>
          <button 
            onClick={() => setError(null)}
            className="error-close"
            aria-label={t('common.dismiss')}
          >
            ×
          </button>
        </div>
      )}

      <GroupList onCreateGroup={handleCreateClick} />
      
      {isCreateModalOpen && (
        <GroupCreateModal
          onClose={handleModalClose}
          onGroupCreated={handleGroupCreated}
        />
      )}
    </div>
  );
};

export default GroupPage;