// src/components/ChatPostSkeleton.js
import React from 'react';
import './ChatPostSkeleton.css';

const ChatPostSkeleton = () => (
  <div className="post-skeleton">
    <div className="post-skeleton-header">
      <div className="post-skeleton-avatar"></div>
      <div className="post-skeleton-info">
        <div className="post-skeleton-username"></div>
        <div className="post-skeleton-timestamp"></div>
      </div>
    </div>
  </div>
);

const ChatPostListSkeleton = () => (
  <div className="chat-post-list">
    {[...Array(10)].map((_, i) => (
      <ChatPostSkeleton key={i} />
    ))}
  </div>
);

export { ChatPostSkeleton };
export default ChatPostListSkeleton;