// src/components/FriendRequests/FriendRequests.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DefaultAvatar from '../DefaultAvatar';
import useFriendRequests from '../../hooks/useFriendRequests';
import './FriendRequests.css';

const FriendRequests = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { 
    requests, 
    error, 
    isProcessingRequest, 
    acceptRequest, 
    declineRequest,
    hasRequests
  } = useFriendRequests();

  // Don't render anything if there are no requests
  if (!hasRequests) {
    return null;
  }

  const handleUserClick = (userId, e) => {
    // Prevent navigation if clicking on a button
    if (e.target.closest('button')) {
      return;
    }
    navigate(`/profile/${userId}`);
  };

  const getDisplayName = (user) => {
    if (user.displayName) return user.displayName;
    if (user.username) return user.username;
    return t('friends.anonymous_friend');
  };

  return (
    <div className="friend-requests">
      <h2 className="friend-requests-title">{t('friends.friend_requests')}</h2>
      {error && <div className="friend-requests-error">{error}</div>}
      
      <div className="friend-requests-list">
        {requests.map((request) => (
          <div 
            key={request.id} 
            className="friend-request-card"
            onClick={(e) => handleUserClick(request.id, e)}
            role="button"
            tabIndex="0"
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleUserClick(request.id, e);
              }
            }}
          >
            <div className="friend-request-user">
              <div className="friend-request-avatar">
                {request.profilePictureUrl ? (
                  <img 
                    src={request.profilePictureUrl} 
                    alt={t('friends.profile_picture_alt', { name: getDisplayName(request) })}
                    className="friend-request-avatar-image"
                    loading="lazy"
                  />
                ) : (
                  <DefaultAvatar
                    name={getDisplayName(request)}
                    size={45}
                    fallbackColor="var(--color-primary-light)"
                    textColor="var(--color-surface)"
                  />
                )}
              </div>
              <div className="friend-request-info">
                <h3>{getDisplayName(request)}</h3>
                {request.username && (
                  <p className="friend-request-username">@{request.username}</p>
                )}
              </div>
            </div>
            <div className="friend-request-actions">
              <button
                className="friend-request-button accept"
                onClick={(e) => {
                  e.stopPropagation();
                  acceptRequest(request.id);
                }}
                disabled={isProcessingRequest(request.id)}
                aria-label={t('friends.accept_request_aria', { name: getDisplayName(request) })}
              >
                {isProcessingRequest(request.id) ? t('friends.processing') : t('friends.accept')}
              </button>
              <button
                className="friend-request-button decline"
                onClick={(e) => {
                  e.stopPropagation();
                  declineRequest(request.id);
                }}
                disabled={isProcessingRequest(request.id)}
                aria-label={t('friends.decline_request_aria', { name: getDisplayName(request) })}
              >
                {t('friends.decline')}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FriendRequests;