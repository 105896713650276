// src/services/draftService.js
import { db, auth } from '../firebase';
import { doc, getDoc, updateDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';

/**
 * Get a single draft by ID
 * @param {string} draftId - The ID of the draft to fetch.
 * @returns {Promise<object|null>} The draft data object including its ID, or null if not found.
 * @throws {Error} If fetching fails.
 */
export const getDraft = async (draftId) => {
  if (!draftId) {
    throw new Error('Draft ID is required.');
  }
  try {
    const draftRef = doc(db, 'drafts', draftId);
    const draftSnap = await getDoc(draftRef);

    if (draftSnap.exists()) {
      const draftData = draftSnap.data();
      // Convert Firestore Timestamp to Date object for easier handling in components
      if (draftData.createdAt && typeof draftData.createdAt.toDate === 'function') {
        draftData.createdAt = draftData.createdAt.toDate();
      }
      if (draftData.updatedAt && typeof draftData.updatedAt.toDate === 'function') {
        draftData.updatedAt = draftData.updatedAt.toDate();
      }
      return { id: draftSnap.id, ...draftData };
    } else {
      throw new Error('Draft not found');
    }
  } catch (error) {
    console.error(`Error fetching draft ${draftId}:`, error);
    if (error.message === 'Draft not found') {
      throw error;
    }
    throw new Error('Failed to fetch draft.');
  }
};

/**
 * Update an existing draft document.
 * Note: This wasn't used in the original component but is good practice to include.
 * @param {string} draftId - The ID of the draft to update.
 * @param {object} updates - An object containing the fields to update.
 * @returns {Promise<void>}
 * @throws {Error} If update fails.
 */
export const updateDraft = async (draftId, updates) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to update a draft.');
  if (!draftId) throw new Error('Draft ID is required.');

  try {
    const draftRef = doc(db, 'drafts', draftId);
    // Ensure we only update allowed fields and add an updatedAt timestamp
    const validUpdates = { ...updates, updatedAt: serverTimestamp() };
    await updateDoc(draftRef, validUpdates);
  } catch (error) {
    console.error(`Error updating draft ${draftId}:`, error);
    throw new Error('Failed to update draft.');
  }
};


/**
 * Delete a draft document.
 * Note: The original component implicitly deletes the draft via the 'createPost' function.
 * This service function provides an explicit way if needed elsewhere.
 * @param {string} draftId - The ID of the draft to delete.
 * @returns {Promise<void>}
 * @throws {Error} If deletion fails.
 */
export const deleteDraft = async (draftId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to delete a draft.');
  if (!draftId) throw new Error('Draft ID is required.');

  try {
    const draftRef = doc(db, 'drafts', draftId);
    await deleteDoc(draftRef);
  } catch (error) {
    console.error(`Error deleting draft ${draftId}:`, error);
    throw new Error('Failed to delete draft.');
  }
};

// We keep createPost in postService as it creates a *post*, but it takes draft info.
// The responsibility split seems reasonable: postService creates posts, draftService manages drafts. 