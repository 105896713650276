// src/components/PostEditForm.js
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { 
  Edit2, Eye, ExternalLink, Globe, 
  Users, Lock, Info
} from 'lucide-react';
import Button from './Button';
import styles from './PostEditForm.module.css';

const PostEditForm = ({ 
  post,
  onCancel, 
  onSave,
  isSaving = false
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState(post.name || '');
  const [description, setDescription] = useState(post.description || '');
  const [link, setLink] = useState(post.link || '');
  const [privacy, setPrivacy] = useState(post.privacy || 'public');
  const [previewMode, setPreviewMode] = useState(false);
  const [charCount, setCharCount] = useState(description.length);
  const MAX_DESCRIPTION_LENGTH = 10000;
  
  const textareaRef = useRef(null);
  
  // Resize the textarea whenever description changes or when toggling between preview/edit modes
  useEffect(() => {
    if (textareaRef.current) {
      // Reset height to auto to get the correct scrollHeight
      textareaRef.current.style.height = 'auto';
      // Set the height to match content
      textareaRef.current.style.height = 
        Math.max(textareaRef.current.scrollHeight, 120) + 'px';
    }
  }, [description, previewMode]);
  
  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    if (newDescription.length <= MAX_DESCRIPTION_LENGTH) {
      setDescription(newDescription);
      setCharCount(newDescription.length);
    }
  };
  
  const handleSaveClick = () => {
    if (!name.trim() || !description.trim()) {
      // Don't proceed if title or description is empty
      return;
    }
    
    onSave({ 
      name, 
      description, 
      link, 
      privacy 
    });
  };

  // Helper function for formatting the description placeholder
  const getDescriptionPlaceholder = () => {
    if (previewMode) return '';
    
    return t('posts.post_description_placeholder') + 
      '\n\n' + t('markdown.basic_formatting') + 
      '\n**' + t('markdown.bold') + '**' +
      '\n*' + t('markdown.italic') + '*' +
      '\n## ' + t('markdown.heading') +
      '\n- ' + t('markdown.list_item');
  };

  // Check if form is valid
  const isFormValid = name.trim() && description.trim();

  return (
    <div className={styles.postEditForm}>
      <div className={styles.editFormHeader}>
        <h3 className={styles.editFormTitle}>{t('posts.edit_post')}</h3>
        <div className={styles.toggleSwitch}>
          <button 
            type="button"
            className={`${styles.toggleOption} ${!previewMode ? styles.toggleActive : ''}`}
            onClick={() => setPreviewMode(false)}
            aria-pressed={!previewMode}
          >
            <Edit2 size={16} />
            <span>{t('common.edit')}</span>
          </button>
          <button 
            type="button"
            className={`${styles.toggleOption} ${previewMode ? styles.toggleActive : ''}`}
            onClick={() => setPreviewMode(true)}
            aria-pressed={previewMode}
          >
            <Eye size={16} />
            <span>{t('common.preview')}</span>
          </button>
          <div className={`${styles.toggleSlider} ${previewMode ? styles.sliderRight : ''}`}></div>
        </div>
      </div>
      
      {previewMode ? (
        <div className={styles.previewContent}>
          <h3 className={styles.previewTitle}>{name || t('posts.untitled_post')}</h3>
          <div className={styles.previewDescription}>
            <ReactMarkdown>{description}</ReactMarkdown>
          </div>
          {link && (
            <a href={link} target="_blank" rel="noopener noreferrer" className={styles.postLink}>
              <ExternalLink size={14} />
              {link}
            </a>
          )}
          <div className={styles.previewFooter}>
            <div className={styles.postPrivacy}>
              {privacy === 'public' && <Globe size={14} />}
              {privacy === 'friends' && <Users size={14} />}
              {privacy === 'private' && <Lock size={14} />}
              {t(`posts.privacy.${privacy}`)}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.formGroup}>
            <label className={styles.formLabel} htmlFor="post-title">
              <span className={styles.labelText}>
                {t('posts.post_title')}
                <span className={styles.requiredFlag}>*</span>
              </span>
            </label>
            <input
              id="post-title"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={t('posts.post_title_placeholder')}
              aria-label={t('posts.post_title')}
              className={styles.formInput}
            />
            {!name.trim() && (
              <span className={styles.validationError}>
                {t('validation.title_required')}
              </span>
            )}
          </div>
          
          <div className={styles.formGroup}>
            <label className={styles.formLabel} htmlFor="post-description">
              <span className={styles.labelText}>
                {t('posts.post_description')}
                <span className={styles.requiredFlag}>*</span>
              </span>
              <div className={`${styles.markdownHelp} ${styles.markdownHelpContainer}`}>
                <Info size={14} />
                <span>{t('markdown.supports_markdown')}</span>
              </div>
            </label>
            <textarea
              id="post-description"
              ref={textareaRef}
              value={description}
              onChange={handleDescriptionChange}
              placeholder={getDescriptionPlaceholder()}
              aria-label={t('posts.post_description')}
              className={styles.formTextarea}
              rows={4}
            />
            {!description.trim() && (
              <span className={styles.validationError}>
                {t('validation.description_required')}
              </span>
            )}            
            <div className={styles.charCounter}>
              <span className={charCount > MAX_DESCRIPTION_LENGTH * 0.9 ? styles.charCountWarning : ''}>
                {charCount}
              </span>
              <span className={styles.charLimit}>/{MAX_DESCRIPTION_LENGTH}</span>
            </div>
          </div>
          
          <div className={styles.formGroup}>
            <label className={styles.formLabel} htmlFor="post-link">
              {t('posts.post_link')}
            </label>
            <div className={styles.linkInputWrapper}>
              <input
                id="post-link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                placeholder={t('posts.post_link_placeholder')}
                aria-label={t('posts.post_link')}
                className={styles.formInput}
              />
              {link && (
                <a 
                  href={link.startsWith('http') ? link : `https://${link}`} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className={styles.testLinkButton}
                  title={t('posts.test_link')}
                >
                  <ExternalLink size={16} />
                </a>
              )}
            </div>
          </div>
          
          <div className={styles.formGroup}>
            <label className={styles.formLabel} htmlFor="post-privacy">
              {t('posts.post_privacy')}
            </label>
            <div className={styles.privacySelector}>
              <button 
                type="button" 
                className={`${styles.privacyOption} ${privacy === 'public' ? styles.selected : ''}`}
                onClick={() => setPrivacy('public')}
                aria-pressed={privacy === 'public'}
              >
                <div className={styles.privacyIconContainer}>
                  <Globe size={18} />
                  <div className={styles.selectionIndicator}></div>
                </div>
                <div className={styles.privacyContent}>
                  <span className={styles.privacyLabel}>{t('posts.privacy.public')}</span>
                  <span className={styles.privacyDescription}>{t('posts.privacy.public_desc')}</span>
                </div>
              </button>
              
              <button 
                type="button" 
                className={`${styles.privacyOption} ${privacy === 'friends' ? styles.selected : ''}`}
                onClick={() => setPrivacy('friends')}
                aria-pressed={privacy === 'friends'}
              >
                <div className={styles.privacyIconContainer}>
                  <Users size={18} />
                  <div className={styles.selectionIndicator}></div>
                </div>
                <div className={styles.privacyContent}>
                  <span className={styles.privacyLabel}>{t('posts.privacy.friends')}</span>
                  <span className={styles.privacyDescription}>{t('posts.privacy.friends_desc')}</span>
                </div>
              </button>
              
              <button 
                type="button" 
                className={`${styles.privacyOption} ${privacy === 'private' ? styles.selected : ''}`}
                onClick={() => setPrivacy('private')}
                aria-pressed={privacy === 'private'}
              >
                <div className={styles.privacyIconContainer}>
                  <Lock size={18} />
                  <div className={styles.selectionIndicator}></div>
                </div>
                <div className={styles.privacyContent}>
                  <span className={styles.privacyLabel}>{t('posts.privacy.private')}</span>
                  <span className={styles.privacyDescription}>{t('posts.privacy.private_desc')}</span>
                </div>
              </button>
            </div>
          </div>
        </>
      )}
      
      <div className={styles.postEditActions}>
        <Button 
          onClick={onCancel} 
          variant="secondary"
          className={styles.cancelButton}
        >
          {t('common.cancel')}
        </Button>
        <Button 
          onClick={handleSaveClick} 
          variant="primary" 
          disabled={isSaving || !isFormValid}
          className={styles.saveButton}
        >
          {isSaving ? (
            <>
              <span className={styles.spinnerIcon}></span>
              {t('common.saving')}
            </>
          ) : (
            t('common.save')
          )}
        </Button>
      </div>
    </div>
  );
};

export default PostEditForm;