import React from 'react';
import './Button.css';

const Button = ({ 
  children, 
  variant = 'primary', 
  size = 'medium', 
  onClick, 
  disabled, 
  type = 'button', 
  fullWidth = false,
  loading = false,
  icon = null,
  iconPosition = 'left',
  className = '',
  pill = false
}) => {
  const classNames = `
    affin-button 
    ${variant} 
    ${size} 
    ${fullWidth ? 'full-width' : ''} 
    ${loading ? 'loading' : ''} 
    ${pill ? 'pill' : ''}
    ${className}
  `.trim().replace(/\s+/g, ' ');
  
  return (
    <button 
      className={classNames} 
      onClick={onClick} 
      disabled={disabled || loading}
      type={type}
      aria-busy={loading}
    >
      {loading && <span className="spinner"></span>}
      {icon && iconPosition === 'left' && !loading && icon}
      <span className="button-text">{children}</span>
      {icon && iconPosition === 'right' && !loading && icon}
    </button>
  );
};

export default Button;