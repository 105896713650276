// src/components/PostDetailView.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getPost } from '../services/postService';
import PostItem from './PostItem';
import { ArrowLeft, AlertCircle } from 'lucide-react';
import Button from './Button';
import styles from './PostDetailView.module.css';
import PostSkeleton from './PostSkeleton';

const PostDetailView = () => {
  const { t } = useTranslation();
  const { postId } = useParams();
  const location = useLocation();
  const commentIndex = location.state && location.state.commentIndex != null ? Number(location.state.commentIndex) : null;
  const replyIndex = location.state && location.state.replyIndex != null ? Number(location.state.replyIndex) : null;
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      setError(false);
      try {
        const fetchedPost = await getPost(postId);
        if (!fetchedPost) {
          setError('not_found');
        } else {
          setPost(fetchedPost);
        }
      } catch (err) {
        console.error('Error fetching post:', err);
        setError('fetch_error');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [postId]);

  const handleBackClick = () => {
    // Check if we can go back in history
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      // If not, go to home
      navigate('/');
    }
  };

  const handlePostUpdate = (updatedPost) => {
    setPost(updatedPost);
  };

  const handlePostDelete = () => {
    navigate('/'); // Redirect to home page after post is deleted
  };

  const renderErrorState = () => (
    <div className={styles.errorState}>
      <AlertCircle size={48} color="var(--color-error)" />
      <h2>{t(`posts.${error}_title`)}</h2>
      <p>{t(`posts.${error}_message`)}</p>
      <Button onClick={() => navigate('/')} variant="primary">
        {t('common.go_home')}
      </Button>
    </div>
  );

  return (
    <div className={styles.postDetailView} key={postId}>
      <div className={styles.detailHeader}>
        <div 
          onClick={handleBackClick}
          className={styles.backButton}
          role="button"
          tabIndex={0}
          aria-label={t('common.back')}
        >
          <ArrowLeft size={22} />
        </div>
      </div>
      
      {loading ? (
        <PostSkeleton isDetailView={true} />
      ) : error ? (
        renderErrorState()
      ) : post ? (
        <PostItem 
          post={post} 
          onPostUpdated={handlePostUpdate} 
          onPostDeleted={handlePostDelete}
          isDetailView={true}
          showFullContent={true}
          highlightedCommentIndex={commentIndex}
          highlightedReplyIndex={replyIndex}
        />
      ) : null}
    </div>
  );
};

export default PostDetailView;