import { useState, useEffect, useCallback } from 'react';
import { auth } from '../firebase';
import { 
  getGroupById, 
  getGroupMembers, 
  updateGroupSettings,
  toggleGroupNotifications,
  removeGroupMember,
  leaveGroup
} from '../services/groupService';

/**
 * Hook for managing a single group
 * @param {string} groupId - Group ID to load
 * @returns {Object} Group data and management functions
 */
const useGroup = (groupId) => {
  const [group, setGroup] = useState(null);
  const [members, setMembers] = useState([]);
  const [currentUserMember, setCurrentUserMember] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  // Load group data
  useEffect(() => {
    const loadGroup = async () => {
      if (!groupId) return;
      
      setLoading(true);
      setError(null);
      
      try {
        const groupData = await getGroupById(groupId);
        setGroup(groupData);
        
        // Load members
        const membersData = await getGroupMembers(groupId);
        setMembers(membersData);
        
        // Find current user's member data
        const currentUserId = auth.currentUser?.uid;
        const userMember = membersData.find(m => m.userId === currentUserId);
        
        if (userMember) {
          setCurrentUserMember(userMember);
          setNotificationsEnabled(userMember.notificationsEnabled);
          setIsAdmin(userMember.role === 'admin');
        }
      } catch (err) {
        console.error('Error loading group:', err);
        setError(err.message || 'Failed to load group');
      } finally {
        setLoading(false);
      }
    };
    
    loadGroup();
  }, [groupId]);

  /**
   * Update group settings
   * @param {Object} groupData - Group data to update
   * @param {File} iconFile - New group icon (optional)
   */
  const updateGroup = useCallback(async (groupData, iconFile) => {
    if (!group || !group.id) return;
    
    setLoading(true);
    setError(null);
    
    try {
      const updatedGroup = await updateGroupSettings(group.id, groupData, iconFile);
      setGroup(updatedGroup);
      return updatedGroup;
    } catch (err) {
      console.error('Error updating group:', err);
      setError(err.message || 'Failed to update group');
      throw err;
    } finally {
      setLoading(false);
    }
  }, [group]);

  /**
   * Toggle notifications for the current user
   */
  const toggleNotifications = useCallback(async () => {
    if (!group || !group.id || !currentUserMember) return;
    
    const newNotificationState = !notificationsEnabled;
    
    try {
      await toggleGroupNotifications(
        group.id, 
        auth.currentUser.uid, 
        newNotificationState
      );
      
      setNotificationsEnabled(newNotificationState);
      setCurrentUserMember(prev => ({
        ...prev,
        notificationsEnabled: newNotificationState
      }));
      
      return newNotificationState;
    } catch (err) {
      console.error('Error toggling notifications:', err);
      setError(err.message || 'Failed to update notification settings');
      throw err;
    }
  }, [group, currentUserMember, notificationsEnabled]);

  /**
   * Remove a member from the group
   * @param {string} memberId - Member ID to remove
   */
  const removeMember = useCallback(async (memberId) => {
    if (!group || !isAdmin || memberId === auth.currentUser.uid) return;
    
    try {
      const updatedGroup = await removeGroupMember(group, memberId);
      
      // Update local state
      setGroup(updatedGroup);
      setMembers(prev => prev.filter(m => m.id !== memberId));
      
      return updatedGroup;
    } catch (err) {
      console.error('Error removing member:', err);
      setError(err.message || 'Failed to remove member');
      throw err;
    }
  }, [group, isAdmin]);

  /**
   * Leave the group (current user)
   */
  const handleLeaveGroup = useCallback(async () => {
    if (!group) return;
    
    // If user is the last admin, prevent leaving
    if (isAdmin && members.filter(m => m.role === 'admin').length === 1) {
      setError('You are the last admin. Make someone else an admin before leaving.');
      return false;
    }
    
    try {
      await leaveGroup(group);
      return true;
    } catch (err) {
      console.error('Error leaving group:', err);
      setError(err.message || 'Failed to leave group');
      return false;
    }
  }, [group, isAdmin, members]);

  /**
   * Update members or group data
   * @param {Object} updatedGroup - Updated group data
   */
  const updateGroupData = useCallback((updatedGroup) => {
    if (updatedGroup) {
      setGroup(prev => ({...prev, ...updatedGroup}));
    }
  }, []);

  /**
   * Add members to the members list
   * @param {Array} newMembers - New members to add
   */
  const addMembersToList = useCallback((newMembers) => {
    if (newMembers && newMembers.length > 0) {
      setMembers(prev => [...prev, ...newMembers]);
    }
  }, []);

  return {
    group,
    members,
    currentUserMember,
    loading,
    error,
    isAdmin,
    notificationsEnabled,
    updateGroup,
    toggleNotifications,
    removeMember,
    leaveGroup: handleLeaveGroup,
    updateGroupData,
    addMembersToList
  };
};

export default useGroup; 