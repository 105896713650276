// src/components/GroupButton.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGroupNotifications } from '../hooks';
import './GroupButton.css';
import GroupIcon from '../assets/group-icon.png';

const GroupButton = ({ onClick }) => {
  const { t } = useTranslation();
  const { unreadGroupCount } = useGroupNotifications();

  return (
    <button 
      className="group-button"
      onClick={onClick}
      aria-label={t('chat.view_groups')}
    >
      <div className="group-button-container">
        <div className="group-button-icon">
          <img 
            src={GroupIcon} 
            alt={t('chat.groups')}
            className="group-button-img"
          />
        </div>
        {unreadGroupCount > 0 && (
          <div className="group-button-badge">
            {unreadGroupCount}
          </div>
        )}
      </div>
      
      <span className="group-button-text">
        {t('chat.groups')}
      </span>
    </button>
  );
};

export default GroupButton;