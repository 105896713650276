// src/hooks/useUserPosts.js
import { useState, useEffect, useCallback } from 'react';
import { usePost } from './usePost';
import { auth } from '../firebase';
import i18n from 'i18next';

const POSTS_PER_PAGE = 5;

const useUserPosts = (userId) => {
  const [userPosts, setUserPosts] = useState([]);
  const [lastDocRef, setLastDocRef] = useState(null);
  
  // Saved posts state
  const [savedPosts, setSavedPosts] = useState([]);
  const [savedPostIds, setSavedPostIds] = useState([]);
  const [savedPostsLastDocRef, setSavedPostsLastDocRef] = useState(null);
  const [hasMoreSavedPosts, setHasMoreSavedPosts] = useState(false);
  
  const [initialPostsLoading, setInitialPostsLoading] = useState(true);
  const [loadingMorePosts, setLoadingMorePosts] = useState(false);
  const [postsError, setPostsError] = useState(null);

  const { getUserPosts, getUserSavedPosts } = usePost();

  // Fetch initial saved posts - using useCallback to memoize the function
  const fetchInitialSavedPosts = useCallback(async () => {
    try {
      const { savedPosts: posts, lastDocRef, hasMore } = await getUserSavedPosts(POSTS_PER_PAGE);
      
      setSavedPosts(posts);
      setSavedPostIds(posts.map(post => post.postId));
      setSavedPostsLastDocRef(lastDocRef);
      setHasMoreSavedPosts(hasMore);
    } catch (error) {
      console.error('Error fetching saved posts:', error);
      setPostsError(i18n.t('profile.fetch_saved_posts_error'));
    }
  }, [getUserSavedPosts, setPostsError]);

  // Fetch initial user posts
  useEffect(() => {
    const fetchInitialPosts = async () => {
      setInitialPostsLoading(true);
      setLastDocRef(null);
      setUserPosts([]);
      
      const currentUser = auth.currentUser;
      if (!currentUser) {
        setPostsError(i18n.t('errors.user_not_authenticated'));
        setInitialPostsLoading(false);
        return;
      }

      try {
        // Fetch user's own posts
        const { posts, lastDocRef: newLastDoc } = await getUserPosts({
          userId,
          currentUserId: currentUser.uid,
          limitCount: POSTS_PER_PAGE,
          lastDocRef: null,
        });

        setUserPosts(posts);
        setLastDocRef(newLastDoc);

        // Initialize saved posts for current user
        if (userId === currentUser.uid) {
          await fetchInitialSavedPosts();
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
        setPostsError(i18n.t('profile.fetch_error'));
      } finally {
        setInitialPostsLoading(false);
      }
    };

    fetchInitialPosts();
  }, [userId, getUserPosts, fetchInitialSavedPosts]);

  // Fetch more user posts
  const fetchMorePosts = async () => {
    if (!lastDocRef || loadingMorePosts) return;

    try {
      setLoadingMorePosts(true);
      const currentUser = auth.currentUser;
      
      const { posts: nextBatch, lastDocRef: newLastDoc } = await getUserPosts({
        userId,
        currentUserId: currentUser.uid,
        limitCount: POSTS_PER_PAGE,
        lastDocRef,
      });

      if (nextBatch.length > 0) {
        setUserPosts(prev => [...prev, ...nextBatch]);
        setLastDocRef(newLastDoc);
      } else {
        setLastDocRef(null);
      }
    } catch (error) {
      console.error('Error fetching more posts:', error);
      setPostsError(i18n.t('profile.fetch_error'));
    } finally {
      setLoadingMorePosts(false);
    }
  };

  // Fetch more saved posts
  const fetchMoreSavedPosts = async () => {
    if (loadingMorePosts || !hasMoreSavedPosts || !savedPostsLastDocRef) return;
    
    try {
      setLoadingMorePosts(true);
      
      const { savedPosts: nextBatch, lastDocRef: newLastDoc, hasMore } = 
        await getUserSavedPosts(POSTS_PER_PAGE, savedPostsLastDocRef);

      if (nextBatch.length > 0) {
        setSavedPosts(prev => [...prev, ...nextBatch]);
        setSavedPostIds(prev => [...prev, ...nextBatch.map(post => post.postId)]);
        setSavedPostsLastDocRef(newLastDoc);
        setHasMoreSavedPosts(hasMore);
      } else {
        setSavedPostsLastDocRef(null);
        setHasMoreSavedPosts(false);
      }
    } catch (error) {
      console.error('Error fetching more saved posts:', error);
      setPostsError(i18n.t('profile.fetch_saved_posts_error'));
    } finally {
      setLoadingMorePosts(false);
    }
  };

  return {
    userPosts,
    savedPosts,
    savedPostIds,
    initialPostsLoading,
    loadingMorePosts,
    postsError,
    setUserPosts,
    fetchMorePosts,
    hasMorePosts: !!lastDocRef,
    fetchMoreSavedPosts,
    hasMoreSavedPosts,
  };
};

export default useUserPosts;