// src/components/PostMessage.js

import React from 'react';
import { usePostSubscription } from '../hooks';
import ChatPostItem from './ChatPostItem';
import './PostMessage.css';

const PostMessage = ({ postId }) => {
  const { post, isLoading, error } = usePostSubscription(postId);

  if (isLoading) {
    return (
      <div className="post-message loading post-message-loading">
        <div className="loading-skeleton">
          <div className="loading-avatar loading-skeleton" />
          <div className="loading-text">
            <div className="loading-name loading-skeleton" />
            <div className="loading-title loading-skeleton" />
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="post-message error">{error}</div>;
  }

  if (!post) {
    return null;
  }

  return (
    <div className="post-message">
      <ChatPostItem post={post} />
    </div>
  );
};

export default PostMessage;