// src/components/ChatPostList.js

import React from 'react';
import ChatPostItem from './ChatPostItem';
import { ChatPostSkeleton } from './ChatPostSkeleton';
import { usePostsSubscription } from '../hooks';
import './ChatPostList.css';

const ChatPostList = ({ postIds }) => {
  const { postsById, error } = usePostsSubscription(postIds);

  if (!Array.isArray(postIds) || postIds.length === 0) {
    return null;
  }

  if (error) {
    return <div className="chat-post-list-error">Error loading posts: {error}</div>;
  }

  return (
    <div className="chat-post-list">
      {postIds.map((id) => {
        const post = postsById[id];
        if (!post) {
          return <ChatPostSkeleton key={id} />;
        }
        return (
          <ChatPostItem 
            key={id} 
            post={post} 
          />
        );
      })}
    </div>
  );
};

export default ChatPostList;
