// src/hooks/useFriends.js
import { useState, useEffect, useCallback } from 'react';
import { 
  subscribeFriends, 
  updateFriendLastViewed,
  removeFriend, 
  shouldHighlightFriend 
} from '../services/friendshipService';

/**
 * Hook for managing friends list with real-time updates
 */
const useFriends = () => {
  const [friends, setFriends] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [friendsLastViewedAt, setFriendsLastViewedAt] = useState({});

  useEffect(() => {
    setLoading(true);
    
    // Subscribe to real-time friends list updates
    const unsubscribe = subscribeFriends((friendsList) => {
      setFriends(friendsList);
      
      // Extract lastViewed timestamps from friend objects
      const lastViewedMap = {};
      friendsList.forEach(friend => {
        if (friend.lastViewed) {
          lastViewedMap[friend.id] = friend.lastViewed;
        }
      });
      
      setFriendsLastViewedAt(lastViewedMap);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  /**
   * Mark a friend as viewed (update last viewed timestamp)
   * @param {string} friendId - The ID of the friend to mark as viewed
   */
  const markFriendAsViewed = useCallback(async (friendId) => {
    try {
      await updateFriendLastViewed(friendId);
      // State will update via subscription
    } catch (err) {
      console.error('Error updating friend last viewed:', err);
      setError(err.message || 'Failed to update view status');
    }
  }, []);

  /**
   * Remove a friend from friends list
   * @param {string} friendId - The ID of the friend to remove
   */
  const removeFriendById = useCallback(async (friendId) => {
    try {
      await removeFriend(friendId);
      // State will update automatically via the subscription
    } catch (err) {
      console.error('Error removing friend:', err);
      setError(err.message || 'Failed to remove friend');
      throw err;
    }
  }, []);

  /**
   * Check if a friend should be highlighted (has new content)
   * @param {Object} friend - Friend object to check
   * @returns {boolean} Whether friend should be highlighted
   */
  const checkShouldHighlight = useCallback((friend) => {
    return shouldHighlightFriend(friend, friendsLastViewedAt);
  }, [friendsLastViewedAt]);

  /**
   * Sort friends by various criteria
   * @param {string} sortBy - Field to sort by ('recent', 'name')
   */
  const sortFriends = useCallback((sortBy = 'recent') => {
    let sortedFriends = [...friends];
    
    switch (sortBy) {
      case 'recent':
        sortedFriends.sort((a, b) => {
          const timeA = a.lastPostUpdatedAt ? a.lastPostUpdatedAt.toMillis() : 0;
          const timeB = b.lastPostUpdatedAt ? b.lastPostUpdatedAt.toMillis() : 0;
          return timeB - timeA; // Descending
        });
        break;
      case 'name':
        sortedFriends.sort((a, b) => {
          const nameA = a.displayName || a.username || '';
          const nameB = b.displayName || b.username || '';
          return nameA.localeCompare(nameB);
        });
        break;
      default:
        break;
    }
    
    setFriends(sortedFriends);
  }, [friends]);

  return {
    friends,
    loading,
    error,
    markFriendAsViewed,
    removeFriend: removeFriendById,
    shouldHighlightFriend: checkShouldHighlight,
    friendsLastViewedAt,
    sortFriends,
    hasFriends: friends.length > 0
  };
};

export default useFriends; 