// src/components/MessageContextMenu.js

import React, { useEffect, useRef } from 'react';
import { Reply, Copy } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const MessageContextMenu = ({ position, onReply, onCopy, onClose, targetMessage }) => {
  const { t } = useTranslation();
  const menuRef = useRef(null);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  // Close menu when ESC key is pressed
  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [onClose]);

  // Close menu when scrolling
  useEffect(() => {
    const handleScroll = () => {
      onClose();
    };

    document.addEventListener('scroll', handleScroll, true);
    return () => {
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [onClose]);

  // Adjust position to ensure menu stays within viewport
  const getAdjustedPosition = () => {
    const menuWidth = 180; // Approximate width of the menu
    const menuHeight = 96; // Approximate height of the menu

    let x = position.x;
    let y = position.y;

    // Check if menu would extend beyond right edge of screen
    if (x + menuWidth > window.innerWidth) {
      x = window.innerWidth - menuWidth - 10; // 10px margin
    }

    // Check if menu would extend below bottom of screen
    if (y + menuHeight > window.innerHeight) {
      y = window.innerHeight - menuHeight - 10; // 10px margin
    }

    // Check if menu would extend above top of screen
    if (y < 10) {
      y = 10; // 10px margin
    }

    return { x, y };
  };

  const adjustedPosition = getAdjustedPosition();

  return (
    <div 
      className="message-context-menu"
      style={{
        top: `${adjustedPosition.y}px`,
        left: `${adjustedPosition.x}px`,
      }}
      ref={menuRef}
    >
      <button 
        className="context-menu-item" 
        onClick={onReply}
        aria-label={t('chat.reply')}
      >
        <Reply size={18} />
        <span>{t('chat.reply')}</span>
      </button>
      <button 
        className="context-menu-item" 
        onClick={onCopy}
        aria-label={t('chat.copy')}
      >
        <Copy size={18} />
        <span>{t('chat.copy')}</span>
      </button>
    </div>
  );
};

export default MessageContextMenu;