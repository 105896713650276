// src/components/Header.js
import React, { useState, useEffect, useCallback } from 'react';
import { Bell } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NotificationsDropdown from './NotificationsDropdown';
import GroupButton from './GroupButton';
import { getUnreadNotificationsCount, markAllNotificationsAsRead } from '../services/notificationService';
import logo from '../assets/logo.png';
import './Header.css';

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  // Fetch unread notifications count
  const fetchUnreadCount = useCallback(async () => {
    const count = await getUnreadNotificationsCount();
    setUnreadCount(count);
  }, []);

  useEffect(() => {
    fetchUnreadCount();
    // Re-check every minute
    const timer = setInterval(fetchUnreadCount, 60000);

    return () => {
      clearInterval(timer);
    };
  }, [fetchUnreadCount]);

  // Toggle notifications dropdown
  const handleNotificationClick = async () => {
    if (!isNotificationsOpen) {
      // Optimistically update the UI
      setUnreadCount(0);
      // Fire-and-forget the async call with error handling
      markAllNotificationsAsRead().catch((error) => {
        console.error('Error marking notifications as read:', error);
      });
    }
    setIsNotificationsOpen(prev => !prev);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <header className="app-header">
      <button onClick={handleRefresh} className="logo-button" aria-label="Refresh app">
        <img src={logo} alt="Company Logo" className="logo" />
      </button>
      <div className="header-actions">
        <button
          className="header-button notifications-button"
          onClick={handleNotificationClick}
          aria-label={t('notifications.title')}
        >
          <Bell size={24} />
          {unreadCount > 0 && (
            <span className="notification-badge">
              {unreadCount > 99 ? '99+' : unreadCount}
            </span>
          )}
        </button>

        {/* Only render NotificationsDropdown if isNotificationsOpen is true */}
        {isNotificationsOpen && (
          <NotificationsDropdown onClose={() => setIsNotificationsOpen(false)} />
        )}

        <GroupButton onClick={() => navigate('/groups')} />
      </div>
    </header>
  );
};

export default Header;