// src/components/FriendSearch/FriendSearch.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DefaultAvatar from '../DefaultAvatar';
import FriendRequests from '../FriendRequests/FriendRequests';
import useFriendSearch from '../../hooks/useFriendSearch';
import './FriendSearch.css';

const FriendSearch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { 
    searchTerm, 
    searchResults, 
    loading, 
    error, 
    setSearchTerm, 
    search,
    sendRequest,
    isRequestPending,
    clearSearch
  } = useFriendSearch();

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    search(value);
  };

  const handleSendRequest = async (e, userId) => {
    e.stopPropagation(); // Prevent navigation when clicking the button
    await sendRequest(userId);
  };

  const handleUserClick = (userId) => {
    navigate(`/profile/${userId}`);
  };

  const getDisplayName = (user) => {
    if (user.displayName) return user.displayName;
    if (user.username) return user.username;
    return t('friends.anonymous_friend');
  };

  return (
    <div className="friend-search-container">
      <FriendRequests />
      
      <div className="friend-search-section">
        <div className="friend-search-wrapper">
          <input
            type="text"
            placeholder={t('friends.search_placeholder')}
            value={searchTerm}
            onChange={handleSearch}
            className="friend-search-input"
          />
          {searchTerm && (
            <button
              onClick={clearSearch}
              className="friend-search-clear"
            >
              ✕
            </button>
          )}
        </div>

        {error && <div className="friend-search-error">{error}</div>}

        <div className="friend-search-results">
          {loading ? (
            <div className="friend-search-loading">{t('friends.searching')}</div>
          ) : (
            searchResults.map((user) => (
              <div 
                key={user.id} 
                className="friend-search-card"
                onClick={() => handleUserClick(user.id)}
                role="button"
                tabIndex="0"
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleUserClick(user.id);
                  }
                }}
              >
                <div className="friend-search-user-info">
                  <div className="friend-search-avatar">
                    {user.profilePictureUrl ? (
                      <img
                        src={user.profilePictureUrl}
                        alt={t('friends.profile_picture_alt', { name: getDisplayName(user) })}
                        className="friend-search-avatar-image"
                        loading="lazy"
                      />
                    ) : (
                      <DefaultAvatar
                        name={getDisplayName(user)}
                        size={48}
                        fallbackColor="var(--color-primary-light)"
                        textColor="var(--color-surface)"
                      />
                    )}
                  </div>
                  <div className="friend-search-details">
                    <h3>{getDisplayName(user)}</h3>
                    {user.username && (
                      <p className="friend-search-username">@{user.username}</p>
                    )}
                    {user.mutualFriends > 0 && (
                      <p className="friend-search-mutual">
                        {user.mutualFriends} mutual friend{user.mutualFriends !== 1 ? 's' : ''}
                      </p>
                    )}
                  </div>
                </div>
                <button
                  className={`friend-search-add-btn ${isRequestPending(user.id) ? 'requested' : ''}`}
                  disabled={isRequestPending(user.id)}
                  onClick={(e) => handleSendRequest(e, user.id)}
                >
                  {isRequestPending(user.id) ? t('friends.requested') : t('friends.add_friend')}
                </button>
              </div>
            ))
          )}
          
          {searchTerm && !loading && searchResults.length === 0 && (
            <div className="friend-search-no-results">
              {t('friends.no_results', { searchTerm })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FriendSearch;