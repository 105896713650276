// src/pages/GroupChatPage.js

import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useGroup from '../hooks/useGroup';
import GroupChatInterface from '../components/GroupChatInterface';
import SkeletonGroupChat from '../components/SkeletonGroupChat';
import '../styles/GroupChatPage.css';

const GroupChatPage = () => {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { 
    group, 
    loading, 
    error 
  } = useGroup(groupId);

  const handleBack = () => {
    navigate('/groups');
  };

  if (loading) {
    return <SkeletonGroupChat />;
  }

  if (error || !group) {
    return (
      <div className="error-container">
        <p className="error-message">{error || t('groups.not_found')}</p>
        <button className="back-button" onClick={handleBack}>
          {t('common.back')}
        </button>
      </div>
    );
  }

  return (
    <div className="group-chat-page">
      <GroupChatInterface 
        group={group}
        onBack={handleBack}
      />
    </div>
  );
};

export default GroupChatPage;