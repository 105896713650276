// src/components/CommentItem.js
import React, { useState, useRef, useEffect, useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from './ConfirmationModal';
import styles from './CommentItem.module.css';
import { usePost } from '../hooks/usePost';
import useUser from '../hooks/useUser';
import { getUserProfile } from '../services/userService';
import { Send } from 'lucide-react';

const CommentItem = ({ 
  comment, 
  commenters, 
  postId, 
  commentIndex, 
  onReplyAdded, 
  onDeleteComment, 
  initialShowReplies = false, 
  replyToHighlight = null, 
}) => {
  // Create a local mutable copy of the comment to avoid modifying props directly
  const commentState = useRef(comment).current;
  const { t } = useTranslation();
  const { userId } = useUser();
  const [showReplies, setShowReplies] = useState(initialShowReplies);
  const [replyContent, setReplyContent] = useState('');
  const [replying, setReplying] = useState(false);
  const [replyToUserId, setReplyToUserId] = useState(null);
  const [replyCommenters, setReplyCommenters] = useState({});
  const [isSubmittingReply, setIsSubmittingReply] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const replyInputRef = useRef(null);
  const { replyToComment, deleteComment, deleteReply } = usePost();

  const [isDeleteCommentModalOpen, setIsDeleteCommentModalOpen] = useState(false);
  const [isDeleteReplyModalOpen, setIsDeleteReplyModalOpen] = useState(false);
  const [replyToDeleteIndex, setReplyToDeleteIndex] = useState(null);

  const [repliesRendered, setRepliesRendered] = useState(false);
  const replyRefs = useRef([]);
  const commentRef = useRef(null);

  const fetchReplyCommentersUsernames = useCallback(async () => {
    if (!commentState.replies || commentState.replies.length === 0) {
      setReplyCommenters({});
      return;
    }
    
    const replyCommenterIds = [...new Set(commentState.replies.map(reply => reply.userId))];
    const fetchedReplyCommenters = {};
  
    for (const id of replyCommenterIds) {
      try {
        const userProfile = await getUserProfile(id);
        fetchedReplyCommenters[id] = userProfile.username || 'Anonymous';
      } catch (error) {
        console.error(`Error fetching reply commenter info for user ${id}:`, error);
        fetchedReplyCommenters[id] = 'Anonymous';
      }
    }
  
    setReplyCommenters(fetchedReplyCommenters);
  }, [commentState.replies]);

  useEffect(() => {
    if (showReplies) {
      fetchReplyCommentersUsernames();
    }
  }, [showReplies, fetchReplyCommentersUsernames]);

  useEffect(() => {
    if (replying && replyInputRef.current) {
      replyInputRef.current.focus();
    }
  }, [replying]);

  // Set showReplies to true when replyToHighlight is provided
  useEffect(() => {
    if (replyToHighlight !== null && !showReplies) {
      setShowReplies(true);
    }
  }, [replyToHighlight, showReplies]);

  // Update repliesRendered when replies are assigned refs
  useEffect(() => {
    if (showReplies && commentState.replies && commentState.replies.length > 0) {
      setRepliesRendered(false);
      const timer = setTimeout(() => {
        setRepliesRendered(true);
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [showReplies, commentState.replies]);

  // Scroll to and highlight the reply or comment
  useLayoutEffect(() => {
    const scrollAndHighlight = () => {
      const replyIndex = Number(replyToHighlight);
      const replyElement = replyRefs.current[replyIndex];
      if (replyElement) {
        replyElement.scrollIntoView({ behavior: 'smooth' });
        replyElement.classList.add(styles.highlight);
        setTimeout(() => {
          replyElement.classList.remove(styles.highlight);
        }, 2000);
      }
    };

    if (showReplies && replyToHighlight !== null && repliesRendered) {
      scrollAndHighlight();
    } else if (replyToHighlight === null && initialShowReplies && commentRef.current) {
      commentRef.current.scrollIntoView({ behavior: 'smooth' });
      commentRef.current.classList.add(styles.highlight);
      setTimeout(() => {
        commentRef.current.classList.remove(styles.highlight);
      }, 2000);
    }
  }, [showReplies, replyToHighlight, repliesRendered, initialShowReplies]);
  
  // Update local commentState when parent prop changes
  useEffect(() => {
    Object.assign(commentState, comment);
  }, [comment, commentState]);

  const handleReplyChange = useCallback((e) => {
    setReplyContent(e.target.value);
    autoResizeTextarea(e);
  }, []);

  const handleReplySubmit = async (e) => {
    e.preventDefault();
    if (!replyContent.trim() || isSubmittingReply) return;
    
    setIsSubmittingReply(true);
    
    // Store the current reply content and userId
    const currentReplyContent = replyContent;
    const currentReplyToUserId = replyToUserId;
    
    // Create a temporary reply for optimistic update
    const tempReply = {
      content: currentReplyContent,
      userId: userId || '',
      createdAt: Date.now(),
      replyToUserId: currentReplyToUserId
    };
    
    // Optimistically update the UI
    if (!commentState.replies) {
      commentState.replies = [];
    }
    commentState.replies.push(tempReply);
    
    // Clear the input immediately for better UX
    setReplyContent('');
    setReplying(false);
    
    // Reset textarea height
    if (replyInputRef.current) {
      replyInputRef.current.style.height = 'auto';
    }
    
    try {
      await replyToComment(postId, commentIndex, currentReplyContent, currentReplyToUserId);
      
      // Notify parent component to get the server data
      if (onReplyAdded) {
        onReplyAdded();
      }
    } catch (error) {
      console.error('Error replying to comment:', error);
      // If there's an error, remove the optimistic update
      if (commentState.replies) {
        commentState.replies.pop();
      }
      // Restore the form state
      setReplying(true);
      setReplyContent(currentReplyContent);
      setReplyToUserId(currentReplyToUserId);
    } finally {
      setIsSubmittingReply(false);
    }
  };

  const handleReplyClick = () => {
    setReplying(true);
    const mentionText = `@${commenters[commentState.userId] || 'Anonymous'} `;
    setReplyContent(mentionText);
    setReplyToUserId(commentState.userId);
    
    // Use setTimeout to ensure the textarea is rendered before we try to focus it
    setTimeout(() => {
      if (replyInputRef.current) {
        replyInputRef.current.focus();
        replyInputRef.current.setSelectionRange(mentionText.length, mentionText.length);
      }
    }, 0);
  };

  const handleReplyToReplyClick = (userId, username) => {
    setReplying(true);
    const mentionText = `@${username} `;
    setReplyContent(mentionText);
    setReplyToUserId(userId);
    
    setTimeout(() => {
      if (replyInputRef.current) {
        replyInputRef.current.focus();
        replyInputRef.current.setSelectionRange(mentionText.length, mentionText.length);
      }
    }, 0);
  };

  const autoResizeTextarea = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight + 'px';
  };

  const toggleReplies = () => {
    setShowReplies(prev => !prev);
  };

  const canDeleteComment = userId && userId === commentState.userId;

  // Comment Deletion Handlers
  const handleDeleteCommentClick = () => {
    setIsDeleteCommentModalOpen(true);
  };

  const handleDeleteCommentConfirm = async () => {
    if (isDeleting) return;
    
    setIsDeleting(true);
    
    // First, notify parent component to update its state immediately
    if (onDeleteComment) {
      onDeleteComment(commentIndex);
    }
    
    setIsDeleteCommentModalOpen(false);
    
    try {
      // Then make the server call
      await deleteComment(postId, commentIndex);
    } catch (error) {
      console.error('Error deleting comment:', error);
      // If the server call fails, we should ideally restore the comment
      // This would require passing more complex callbacks to restore state
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteCommentCancel = () => {
    setIsDeleteCommentModalOpen(false);
  };

  // Reply Deletion Handlers
  const handleDeleteReplyClick = (replyIndex, replyUserId) => {
    if (userId !== replyUserId) {
      alert('You do not have permission to delete this reply.');
      return;
    }
    setReplyToDeleteIndex(replyIndex);
    setIsDeleteReplyModalOpen(true);
  };

  const handleDeleteReplyConfirm = async () => {
    if (isDeleting) return;
    
    setIsDeleting(true);
    setIsDeleteReplyModalOpen(false);
    
    // Store the reply for potential restoration in case of error
    const deletedReply = commentState.replies[replyToDeleteIndex];
    
    // Optimistically update the UI by removing the reply
    const updatedReplies = [...commentState.replies];
    updatedReplies.splice(replyToDeleteIndex, 1);
    commentState.replies = updatedReplies;
    
    // Force a re-render
    setRepliesRendered(false);
    setTimeout(() => setRepliesRendered(true), 0);
    
    try {
      // Make the server call
      await deleteReply(postId, commentIndex, replyToDeleteIndex);
      
      // Notify parent component to get server data after successful deletion
      if (onReplyAdded) {
        onReplyAdded();
      }
    } catch (error) {
      console.error('Error deleting reply:', error);
      
      // Restore the deleted reply if there was an error
      if (deletedReply) {
        commentState.replies.splice(replyToDeleteIndex, 0, deletedReply);
        // Force a re-render
        setRepliesRendered(false);
        setTimeout(() => setRepliesRendered(true), 0);
      }
    } finally {
      setReplyToDeleteIndex(null);
      setIsDeleting(false);
    }
  };

  const handleDeleteReplyCancel = () => {
    setIsDeleteReplyModalOpen(false);
    setReplyToDeleteIndex(null);
  }; 

  const formatContent = (content) => {
    const mentionRegex = /@([a-z0-9_]{3,30})\b/g;
    return content.split('\n').map((line, lineIndex) => (
      <React.Fragment key={lineIndex}>
        {line.split(mentionRegex).map((part, partIndex) => 
          partIndex % 2 === 0 ? (
            <span key={partIndex}>{part}</span>
          ) : (
            <span key={partIndex} className={styles.mention}>@{part}</span>
          )
        )}
        {lineIndex < content.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <div className={styles.commentItem} id={`comment-${commentIndex}`} ref={commentRef}>
      {/* Comment Content */}
      <div className={styles.commentContent}>
        <strong>{commenters[commentState.userId] || t('common.anonymous')}: </strong>
        <span>{formatContent(commentState.content)}</span>
      </div>
      <div className={styles.commentMeta}>
        <span className={styles.commentTimestamp}>{new Date(commentState.createdAt).toLocaleString()}</span>
        <div className={styles.commentActions}>
          <button 
            onClick={handleReplyClick} 
            className={styles.replyButton}
            disabled={isDeleting}
          >
            {t('comments.reply')}
          </button>
          {canDeleteComment && (
            <button 
              onClick={handleDeleteCommentClick} 
              className={styles.deleteButton}
              disabled={isDeleting}
            >
              {isDeleting ? t('common.deleting') : t('comments.delete')}
            </button>
          )}
        </div>
      </div>
      {/* Replies */}
      {commentState.replies && commentState.replies.length > 0 && (
        <button onClick={toggleReplies} className={styles.toggleRepliesButton}>
          {showReplies 
            ? t('comments.hide_replies') 
            : t(`comments.view_replies_${commentState.replies.length === 1 ? 'one' : 'other'}`, { count: commentState.replies.length })}
        </button>
      )}
      {showReplies && (
        <div className={styles.replies}>
          {commentState.replies.map((reply, idx) => (
            <div
              key={`reply-${commentIndex}-${idx}-${reply.createdAt}`}
              className={styles.reply}
              id={`reply-${commentIndex}-${idx}`}
              ref={(el) => {
                replyRefs.current[idx] = el;
                if (idx === comment.replies.length - 1) {
                  setRepliesRendered(true);
                }
              }}
            >
              <div className={styles.replyContent}>
                <strong>{replyCommenters[reply.userId] || t('common.anonymous')}: </strong>
                <span>{formatContent(reply.content)}</span>
              </div>
              <div className={styles.replyMeta}>
                <span className={styles.replyTimestamp}>{new Date(reply.createdAt).toLocaleString()}</span>
                <div className={styles.commentActions}>
                  <button 
                    onClick={() => handleReplyToReplyClick(reply.userId, replyCommenters[reply.userId])} 
                    className={styles.replyButton}
                    disabled={isDeleting}
                  >
                    {t('comments.reply')}
                  </button>
                  {userId === reply.userId && (
                    <button 
                      onClick={() => handleDeleteReplyClick(idx, reply.userId)} 
                      className={styles.deleteButton}
                      disabled={isDeleting}
                    >
                      {isDeleting && replyToDeleteIndex === idx ? t('common.deleting') : t('comments.delete')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {/* Reply Form */}
      {replying && (
        <form onSubmit={handleReplySubmit} className={styles.replyForm}>
          <div className={styles.inputWrapper}>
            <textarea
              ref={replyInputRef}
              value={replyContent}
              onChange={handleReplyChange}
              onInput={autoResizeTextarea}
              placeholder={t('comments.add_reply')}
              aria-label={t('comments.add_reply')}
              rows="1"
              className={styles.replyInput}
              disabled={isSubmittingReply}
            />
            {replyContent.trim() && (
              <button 
                type="submit" 
                className={styles.sendButton} 
                aria-label={t('comments.send_reply')}
                disabled={isSubmittingReply}
              >
                <Send size={18} />
              </button>
            )}
          </div>
        </form>
      )}
      {/* Confirmation Modals */}
      <ConfirmationModal
        isOpen={isDeleteCommentModalOpen}
        onClose={handleDeleteCommentCancel}
        onConfirm={handleDeleteCommentConfirm}
        message={t('comments.confirm_delete_comment')}
      />
      <ConfirmationModal
        isOpen={isDeleteReplyModalOpen}
        onClose={handleDeleteReplyCancel}
        onConfirm={handleDeleteReplyConfirm}
        message={t('comments.confirm_delete_reply')}
      />
    </div>
  );
};

export default CommentItem;