// src/components/DraftItem.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { auth } from '../firebase';
import useUserProfile from '../hooks/useUserProfile';
import useDraft from '../hooks/useDraft';
import Button from './Button';
import {
  ChevronDown,
  ChevronUp,
  Edit,
  Save,
  X
} from 'lucide-react';
import './DraftItem.css';
import { createPost } from '../services/postService';

const DraftItem = ({ draftId, groupId = null }) => {
  const { t } = useTranslation();
  const {
    draft: fetchedDraft,
    isLoading: isLoadingDraft,
    error: fetchError
  } = useDraft(draftId);

  const [editMode, setEditMode] = useState(false);
  const [editedDraft, setEditedDraft] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);

  const textareaRef = useRef(null);
  const { profile, loadingProfile } = useUserProfile(auth.currentUser?.uid);

  useEffect(() => {
    if (fetchedDraft) {
      setEditedDraft({ ...fetchedDraft });
    } else {
      setEditedDraft(null);
    }
  }, [fetchedDraft]);

  const autoResizeTextarea = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, []);

  useEffect(() => {
    if (editMode) {
      requestAnimationFrame(autoResizeTextarea);
    }
  }, [editMode, autoResizeTextarea]);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleEdit = () => {
    setEditMode(true);
    setIsExpanded(true);
  };

  const handleCancel = () => {
    setEditedDraft(fetchedDraft ? { ...fetchedDraft } : null);
    setEditMode(false);
    setSaveError(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedDraft(prev => (prev ? { ...prev, [name]: value } : null));

    if (name === 'description' && textareaRef.current) {
      autoResizeTextarea();
    }
  };

  const handleSaveAsPost = async () => {
    const draftDataToSave = editMode ? editedDraft : fetchedDraft;

    if (!draftDataToSave) return;

    setIsSaving(true);
    setSaveError(null);

    if (loadingProfile) {
      setSaveError(t('errors.loading_profile'));
      setIsSaving(false);
      return;
    }

    if (!profile) {
      setSaveError(t('errors.profile_not_found'));
      setIsSaving(false);
      return;
    }

    if (!draftDataToSave.name || !draftDataToSave.description) {
        setSaveError(t('drafts.validation.name_desc_required'));
        setIsSaving(false);
        return;
    }

    try {
      await createPost(
        draftDataToSave.name,
        draftDataToSave.description,
        draftDataToSave.link || '',
        draftDataToSave.privacy || 'public',
        draftDataToSave.searchMetadata || [],
        !!groupId,
        groupId,
        draftId
      );

      setEditMode(false);
      setIsExpanded(false);

    } catch (error) {
      console.error('Error saving draft as post:', error);
      setSaveError(error.message || t('drafts.save_error'));
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoadingDraft) {
    return <div className="draft-item loading">{t('common.loading')}...</div>;
  }

  if (fetchError) {
    return <div className="draft-item error">{fetchError}</div>;
  }

  if (!fetchedDraft) {
    return null;
  }

  if (editMode && !editedDraft) {
      console.warn("Attempting to render edit mode without editedDraft state.");
      return <div className="draft-item error">{t('errors.unexpected_state')}</div>;
  }

  const renderContent = () => {
    if (editMode) {
      if (!editedDraft) return null;

      return (
        <div className="draft-edit-form">
          <input
            name="name"
            value={editedDraft.name || ''}
            onChange={handleChange}
            placeholder={t('posts.post_title')}
            className="draft-input"
            aria-label={t('posts.post_title')}
          />
          <textarea
            ref={textareaRef}
            name="description"
            value={editedDraft.description || ''}
            onChange={handleChange}
            placeholder={t('posts.post_description')}
            className="draft-textarea"
            onFocus={autoResizeTextarea}
            rows={3}
            aria-label={t('posts.post_description')}
          />
          <input
            name="link"
            type="url"
            value={editedDraft.link || ''}
            onChange={handleChange}
            placeholder={t('posts.post_link')}
            className="draft-input"
            aria-label={t('posts.post_link')}
          />
          <select
            name="privacy"
            value={editedDraft.privacy || 'public'}
            onChange={handleChange}
            className="draft-select"
            aria-label={t('posts.privacy_label')}
          >
            <option value="public">{t('posts.privacy.public')}</option>
            <option value="friends">{t('posts.privacy.friends')}</option>
            <option value="private">{t('posts.privacy.private')}</option>
          </select>
          {editedDraft.searchMetadata && editedDraft.searchMetadata.length > 0 && (
            <div className="draft-tags read-only">
              <strong>{t('common.tags')}:</strong>
              {editedDraft.searchMetadata.map((tag, index) => (
                <span key={index} className="tag">
                  {tag}
                </span>
              ))}
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="draft-content">
        <ReactMarkdown className="markdown-content">{fetchedDraft.description || ''}</ReactMarkdown>
        {fetchedDraft.link && (
          <a
            href={fetchedDraft.link}
            target="_blank"
            rel="noopener noreferrer"
            className="draft-link"
          >
            {fetchedDraft.link}
          </a>
        )}
        {fetchedDraft.searchMetadata && fetchedDraft.searchMetadata.length > 0 && (
          <div className="draft-tags">
            {fetchedDraft.searchMetadata.map((tag, index) => (
              <span key={index} className="tag">
                {tag}
              </span>
            ))}
          </div>
        )}
      </div>
    );
  };

  const formattedDate = fetchedDraft.createdAt instanceof Date
    ? fetchedDraft.createdAt.toLocaleDateString()
    : (fetchedDraft.createdAt ? new Date(fetchedDraft.createdAt).toLocaleDateString() : t('common.unknown_date'));

  const currentTitle = editMode
      ? (editedDraft?.name || t('drafts.untitled'))
      : (fetchedDraft.name || t('drafts.untitled'));

  const isDescriptionValid = editMode
      ? !!editedDraft?.description
      : !!fetchedDraft.description;

  const isNameValid = editMode
      ? !!editedDraft?.name
      : !!fetchedDraft.name;

  return (
    <div className={`draft-item ${editMode ? 'editing' : ''}`}>
      <div className="draft-header" onClick={!editMode ? handleToggleExpand : undefined} style={{ cursor: editMode ? 'default' : 'pointer' }}>
        <div className="draft-title">
          <h3>{currentTitle}</h3>
          <span className="draft-timestamp">
            {formattedDate}
          </span>
        </div>
        {!editMode && (
            <button
                type="button"
                onClick={handleToggleExpand}
                className="expand-button"
                aria-label={isExpanded ? t('common.collapse') : t('common.expand')}
                aria-expanded={isExpanded}
            >
                {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
        )}
      </div>

      {isExpanded && (
        <div className="draft-details">
          {renderContent()}

          <div className="draft-actions">
            {editMode ? (
              <>
                <Button
                  onClick={handleCancel}
                  variant="secondary"
                  size="small"
                  icon={<X size={16} />}
                  disabled={isSaving}
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  onClick={handleSaveAsPost}
                  disabled={isSaving || !isNameValid || !isDescriptionValid}
                  variant="primary"
                  size="small"
                  icon={<Save size={16} />}
                  loading={isSaving}
                >
                  {isSaving ? t('drafts.saving') : t('drafts.save_as_post')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handleEdit}
                  variant="secondary"
                  size="small"
                  icon={<Edit size={16} />}
                  disabled={isSaving}
                >
                  {t('drafts.edit')}
                </Button>
                <Button
                  onClick={handleSaveAsPost}
                  disabled={isSaving || !isNameValid || !isDescriptionValid}
                  variant="primary"
                  size="small"
                  icon={<Save size={16} />}
                  loading={isSaving}
                >
                   {isSaving ? t('drafts.saving') : t('drafts.save_as_post')}
                </Button>
              </>
            )}
          </div>

          {saveError && (
            <div className="error-message save-error">
              {saveError}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DraftItem;