// src/components/PostItem.js
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Heart, MessageCircle, Share2, Bookmark, Edit2, Trash2, 
  Send, ExternalLink, Clock, Globe, Lock, Users, MoreHorizontal
} from 'lucide-react';
import ConfirmationModal from './ConfirmationModal';
import styles from './PostItem.module.css';
import { usePostItem } from '../hooks';
import CommentItem from './CommentItem';
import DefaultAvatar from './DefaultAvatar';
import PostSkeleton from './PostSkeleton';
import PostEditForm from './PostEditForm';

const PostItem = ({ 
  post: initialPost, 
  isDetailView = false, 
  onPostDeleted, 
  highlightedCommentIndex = null, 
  highlightedReplyIndex = null,
  showFullContent = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  // State from the usePostItem hook
  const {
    post,
    postId,
    isCurrentUser,
    canInteract,
    isLoading,
    isSaved,
    commenters,
    posterInfo,
    shareMessage,
    submittingComment,
    isSaving,
    handleUpdate,
    handleDelete,
    handleLike,
    handleComment,
    handleShare,
    handleSave,
    handleCommentDelete,
    handleReplyAdded,
    hasLiked,
    commentCount,
    likeCount
  } = usePostItem(initialPost, onPostDeleted);

  // Local component UI state
  const [isPostExpanded, setIsPostExpanded] = useState(isDetailView);
  const [editing, setEditing] = useState(false);
  const [comment, setComment] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCommentSectionVisible, setIsCommentSectionVisible] = useState(
    highlightedCommentIndex !== null || isDetailView
  );
  const [showActionMenu, setShowActionMenu] = useState(false);
  
  // Refs
  const commentInputRef = useRef(null);
  const actionMenuRef = useRef(null);
  const postRef = useRef(null);

  // Handle clicks outside the action menu and scrolling
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionMenuRef.current && !actionMenuRef.current.contains(event.target)) {
        setShowActionMenu(false);
      }
    };

    const handleScroll = () => {
      // Close the menu when scrolling occurs
      if (showActionMenu) {
        setShowActionMenu(false);
      }
    };

    // Only add the listeners if the menu is shown
    if (showActionMenu) {
      document.addEventListener('mousedown', handleClickOutside);
      // Add scroll event listener
      window.addEventListener('scroll', handleScroll, { passive: true });
    }

    return () => {
      // Clean up the event listeners
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [showActionMenu]);

  useEffect(() => {
    if (isCommentSectionVisible && commentInputRef.current) {
      commentInputRef.current.focus();
    }
  }, [isCommentSectionVisible]);

  // Format the created date nicely
  const formatPostDate = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    const diffInHours = Math.floor((now - date) / (1000 * 60 * 60));
    
    if (diffInHours < 1) {
      return t('common.time_just_now');
    } else if (diffInHours < 24) {
      return t('common.time_hours_ago', { count: diffInHours });
    } else if (diffInHours < 48) {
      return t('common.time_yesterday');
    } else {
      return date.toLocaleDateString(undefined, { 
        month: 'short', 
        day: 'numeric',
        year: date.getFullYear() !== now.getFullYear() ? 'numeric' : undefined
      });
    }
  };

  // Get privacy icon based on privacy setting
  const getPrivacyIcon = () => {
    switch(post.privacy) {
      case 'public': return <Globe size={14} />;
      case 'friends': return <Users size={14} />;
      case 'private': return <Lock size={14} />;
      default: return <Globe size={14} />;
    }
  };

  const handleUpdateSubmit = async (updatedPostData) => {
    const success = await handleUpdate(updatedPostData);
    if (success) {
      setEditing(false);
    }
  };

  const handleDeleteClick = () => {
    setShowActionMenu(false);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    const success = await handleDelete();
    if (success) {
      setIsDeleteModalOpen(false);
      if (isDetailView) {
        navigate('/');
      }
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const handleCommentSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (!comment.trim() || submittingComment) return;
    
    // Store the current comment value before clearing it
    const commentValue = comment;
    
    // Clear input
    setComment('');
    
    // Reset textarea height
    if (commentInputRef.current) {
      commentInputRef.current.style.height = 'auto';
    }
    
    // Submit the comment through the hook
    await handleComment(commentValue);
  }, [comment, handleComment, submittingComment]);

  const autoResizeTextarea = useCallback((e) => {
    e.target.style.height = 'auto';
    e.target.style.height = Math.min(e.target.scrollHeight, 150) + 'px';
  }, []);

  const handleCommentChange = useCallback((e) => {
    setComment(e.target.value);
    autoResizeTextarea(e);
  }, [autoResizeTextarea]);

  const togglePostExpansion = useCallback(() => {
    if (isDetailView) return; // Don't toggle if in detail view
    
    setIsPostExpanded(prev => {
      if (!prev) {
        return true; // If we're expanding the post, just return true
      } else {
        // If we're collapsing the post, also collapse the comment section
        setIsCommentSectionVisible(false);
        return false;
      }
    });
  }, [isDetailView]);

  const toggleCommentSection = useCallback(() => {
    setIsCommentSectionVisible(prev => !prev);
    setIsPostExpanded(true); // Always expand the post when toggling comments
  }, []);

  const handleEditClick = () => {
    setShowActionMenu(false);
    setEditing(true);
  };

  const renderPostContent = () => (
    <div className={`${styles.postContent} ${!isPostExpanded && !isDetailView ? styles.collapsed : ''}`}>
      {posterInfo && (
        <div className={styles.posterInfo}>
          <Link 
            to={`/profile/${posterInfo.id}`} 
            onClick={e => e.stopPropagation()}
            className={styles.avatarLink}
          >
            {posterInfo.profilePictureUrl ? (
              <img
                src={posterInfo.profilePictureUrl}
                alt={posterInfo.displayName || t('common.anonymous')}
                className={styles.avatar}
              />
            ) : (
              <DefaultAvatar
                name={posterInfo.displayName || t('common.anonymous')}
                size={36}
                fallbackColor="var(--color-primary-light)"
                textColor="var(--color-surface)"
              />
            )}
          </Link>
          <div className={styles.posterInfoText}>
            <span className={styles.posterName}>{posterInfo.displayName}</span>
            <span className={styles.posterUsername}>@{posterInfo.username}</span>
          </div>
          {posterInfo.createdAt && (
            <span className={styles.postCreatedAt}>
              <Clock size={12} />
              {" "}
              {formatPostDate(posterInfo.createdAt)}
            </span>
          )}
        </div>
      )}
      
      <div className={styles.postDescription}>
        <ReactMarkdown>{post.description}</ReactMarkdown>
      </div>
      
      {post.link && (
        <a href={post.link} target="_blank" rel="noopener noreferrer" className={styles.postLink}>
          <ExternalLink size={14} />
          {t('posts.view_link')}
        </a>
      )}
      
      <div className={styles.postMetadata}>
        <div className={styles.postPrivacy}>
          {getPrivacyIcon()}
          {t(`posts.privacy.${post.privacy}`)}
        </div>
      </div>
    </div>
  );

  const renderActionMenu = () => (
    <div className={`${styles.actionMenu} ${showActionMenu ? styles.actionMenuVisible : ''}`} ref={actionMenuRef}>
      <button onClick={handleShare} className={styles.actionMenuItem}>
        <Share2 size={20} />
        {t('posts.share_post')}
      </button>
      <button onClick={handleSave} className={styles.actionMenuItem}>
        <Bookmark size={20} />
        {isSaved ? t('posts.unsave_post') : t('posts.save_post')}
      </button>
      {isCurrentUser && (
        <>
          <button onClick={handleEditClick} className={styles.actionMenuItem}>
            <Edit2 size={20} />
            {t('posts.edit_post')}
          </button>
          <button onClick={handleDeleteClick} className={`${styles.actionMenuItem} ${styles.deleteAction}`}>
            <Trash2 size={20} />
            {t('posts.delete_post')}
          </button>
        </>
      )}
    </div>
  );

  const renderPostActions = () => {
    return (
      <div className={styles.postActions}>
        <button
          onClick={handleLike}
          className={`${styles.actionButton} ${hasLiked ? styles.active : ''}`}
          aria-label={hasLiked ? t('posts.unlike_post') : t('posts.like_post')}
        >
          <Heart 
            fill={hasLiked ? 'var(--color-primary)' : 'none'}
            stroke={hasLiked ? 'var(--color-primary)' : 'currentColor'}
            size={22}
          />
          <span>{likeCount}</span>
        </button>
        
        <button 
          onClick={toggleCommentSection} 
          className={`${styles.actionButton} ${isCommentSectionVisible ? styles.active : ''}`}
          aria-label={isCommentSectionVisible ? t('posts.hide_comments') : t('posts.show_comments')}
        >
          <MessageCircle size={22} />
          <span>{commentCount}</span>
        </button>
        
        <button 
          onClick={handleShare}
          className={styles.actionButton}
          aria-label={t('posts.share_post')}
        >
          <Share2 size={22} />
        </button>
        
        <button 
          onClick={handleSave} 
          className={`${styles.actionButton} ${isSaved ? styles.active : ''}`}
          aria-label={isSaved ? t('posts.unsave_post') : t('posts.save_post')}
        >
          <Bookmark 
            fill={isSaved ? 'var(--color-primary)' : 'none'}
            stroke={isSaved ? 'var(--color-primary)' : 'currentColor'}
            size={22}
          />
        </button>
      </div>
    );
  };

  const memoizedComments = useMemo(() => {
    // Ensure post.comments is an array
    const postComments = post.comments || [];
    
    return (
      <>
        <div className={styles.commentsHeading}>
          {t('comments.title')}
          <span className={styles.count}>({postComments.length})</span>
        </div>
        
        {postComments.map((comment, index) => (
          <CommentItem
            key={`${postId}-comment-${index}-${comment.createdAt}`}
            comment={comment}
            commenters={commenters}
            postId={postId}
            commentIndex={index}
            onReplyAdded={handleReplyAdded}
            onDeleteComment={handleCommentDelete}
            initialShowReplies={highlightedCommentIndex === index}
            replyToHighlight={highlightedCommentIndex === index ? highlightedReplyIndex : null}
          />
        ))}
        
        <form onSubmit={handleCommentSubmit} className={styles.commentForm}>
          <div className={styles.inputWrapper}>
            <textarea
              ref={commentInputRef}
              value={comment}
              onChange={handleCommentChange}
              placeholder={t('comments.add_comment_placeholder')}
              aria-label={t('comments.add_comment_aria')}
              rows="1"
              className={styles.commentInput}
              disabled={submittingComment}
            />
            {comment.trim() && (
              <button 
                type="submit" 
                className={styles.sendButton} 
                aria-label={t('comments.send_comment')}
                disabled={submittingComment}
              >
                <Send size={18} />
              </button>
            )}
          </div>
        </form>
      </>
    );
  }, [
    post.comments, 
    commenters, 
    comment, 
    handleCommentSubmit, 
    postId, 
    handleReplyAdded, 
    handleCommentDelete, 
    highlightedCommentIndex, 
    highlightedReplyIndex, 
    handleCommentChange,
    t,
    submittingComment
  ]);

  // Loading skeleton
  if (isLoading) {
    return <PostSkeleton isDetailView={isDetailView} />;
  }

  return (
    <>
      <div 
        className={`${styles.postItem} ${isPostExpanded || isDetailView ? styles.expanded : ''}`}
        ref={postRef}
      >
        {editing && isCurrentUser ? (
          <PostEditForm 
            post={post}
            onCancel={() => setEditing(false)}
            onSave={handleUpdateSubmit}
            isSaving={isSaving}
          />
        ) : (
          <>
            <div
              className={styles.postHeader}
              onClick={() => !isDetailView && togglePostExpansion()}
              role={isDetailView ? undefined : "button"}
              tabIndex={isDetailView ? undefined : 0}
              aria-expanded={isPostExpanded}
              aria-label={`${post.name}. ${isPostExpanded && !isDetailView ? t('posts.click_to_collapse') : t('posts.click_to_expand')}`}
            >
              <div className={styles.postTitleWrapper}>
                <h3 className={styles.postTitle}>
                  {isDetailView ? post.name : <Link to={`/post/${postId}`}>{post.name}</Link>}
                </h3>
              </div>
              
              {/* Menu icon in top right corner */}
              {isCurrentUser && (
                <>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering post expansion
                      setShowActionMenu(!showActionMenu);
                    }} 
                    className={`${styles.menuIcon} ${showActionMenu ? styles.menuIconActive : ''}`}
                    aria-label={t('common.more_options')}
                  >
                    <MoreHorizontal size={20} />
                  </button>
                  {renderActionMenu()}
                </>
              )}
              {renderPostContent()}
            </div>
            
            {canInteract && (
              <>
                {renderPostActions()}
                
                {shareMessage && <div className={styles.shareMessage}>{t(`posts.${shareMessage}`)}</div>}
                
                {(isPostExpanded || isDetailView) && isCommentSectionVisible && (
                  <div className={`${styles.postComments}`}>
                    {memoizedComments}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        message={t('posts.confirm_delete')}
      />
    </>
  );
};

export default PostItem;