import React from 'react';
import { useTranslation } from 'react-i18next';
import { X } from 'lucide-react';
import ChatPostList from './ChatPostList';
import ChatPostListSkeleton from './ChatPostSkeleton';
import useGroupPosts from '../hooks/useGroupPosts';
import './AllPostsInGroup.css';

const AllPostsInGroup = ({ groupId, onClose }) => {
  const { t } = useTranslation();
  const { postIds, isLoading, error } = useGroupPosts(groupId);

  return (
    <div className="all-posts-overlay">
      <div className="all-posts-modal">
        <div className="all-posts-header">
          <h3 className="all-posts-title">{t('groups.all_posts_title')}</h3>
          <button
            onClick={onClose}
            className="all-posts-close-button"
            aria-label={t('common.close')}
          >
            <X size={24} />
          </button>
        </div>
        
        <div className="all-posts-content">
          {error ? (
            <div className="all-posts-error">{error}</div>
          ) : isLoading ? (
            <ChatPostListSkeleton />
          ) : postIds.length === 0 ? (
            <div className="all-posts-empty">{t('groups.no_posts')}</div>
          ) : (
            <ChatPostList postIds={postIds} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AllPostsInGroup;