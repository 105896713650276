// src/components/NotificationsDropdown.js
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { isToday, isYesterday, isThisWeek } from 'date-fns';
import { X, Loader2 } from 'lucide-react';
import NotificationItem from './NotificationItem';
import { getNotifications } from '../services/notificationService';
import './NotificationsDropdown.css';

const groupNotifications = (notifications, t) => {
  const groups = {
    [t('notifications.today')]: [],
    [t('notifications.yesterday')]: [],
    [t('notifications.this_week')]: [],
    [t('notifications.earlier')]: []
  };

  notifications.forEach(notification => {
    // If createdAt is a Firebase timestamp, convert it to a Date
    const date = notification.createdAt && notification.createdAt.toDate ?
      notification.createdAt.toDate() :
      new Date();

    if (isToday(date)) {
      groups[t('notifications.today')].push(notification);
    } else if (isYesterday(date)) {
      groups[t('notifications.yesterday')].push(notification);
    } else if (isThisWeek(date)) {
      groups[t('notifications.this_week')].push(notification);
    } else {
      groups[t('notifications.earlier')].push(notification);
    }
  });

  return groups;
};

const NotificationsDropdown = ({ onClose }) => {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const dropdownRef = useRef(null);

  // Fetch notifications when the component mounts
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        setIsLoading(true);
        const fetchedNotifications = await getNotifications();
        setNotifications(fetchedNotifications);
        setError(null);
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setError(t('notifications.error_loading'));
      } finally {
        setIsLoading(false);
      }
    };
    fetchNotifications();
  }, [t]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const groupedNotifications = groupNotifications(notifications, t);

  return (
    <div className="notifications-dropdown" ref={dropdownRef}>
      <div className="notifications-header">
        <h2>{t('notifications.notifications')}</h2>
        <button className="close-button" onClick={onClose}>
          <X size={18} />
        </button>
      </div>

      {isLoading ? (
        <div className="loading-state">
          <Loader2 className="loading-spinner" size={32} />
          <p>{t('notifications.loading')}</p>
        </div>
      ) : error ? (
        <div className="error-state">
          <p>{error}</p>
          <button 
            className="retry-button"
            onClick={() => {
              setIsLoading(true);
              setError(null);
              getNotifications()
                .then(fetchedNotifications => {
                  setNotifications(fetchedNotifications);
                })
                .catch(err => {
                  console.error('Error retrying fetch:', err);
                  setError(t('notifications.error_loading'));
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            {t('notifications.retry')}
          </button>
        </div>
      ) : notifications.length > 0 ? (
        <div className="notifications-content">
          {Object.entries(groupedNotifications).map(([group, groupNotifications]) => (
            groupNotifications.length > 0 && (
              <div key={group} className="notification-group">
                <h3 className="group-header">{group}</h3>
                {groupNotifications.map(notification => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    onClose={onClose}
                  />
                ))}
              </div>
            )
          ))}
        </div>
      ) : (
        <div className="empty-state">
          <div className="empty-icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
          <p>{t('notifications.caught_up')}</p>
          <p>{t('notifications.check_back_later')}</p>
        </div>
      )}
    </div>
  );
};

export default NotificationsDropdown;