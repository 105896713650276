// src/hooks/useFriendSearch.js
import { useState, useEffect, useCallback } from 'react';
import { auth, db } from '../firebase';
import { 
  searchUsers, 
  sendFriendRequest 
} from '../services/friendshipService';
import { doc, onSnapshot } from 'firebase/firestore';

/**
 * Hook for searching users and sending friend requests
 */
const useFriendSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pendingRequests, setPendingRequests] = useState(new Set());

  // Listen to real-time updates for sent friend requests
  useEffect(() => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    const userDocRef = doc(db, 'users', currentUser.uid);
    
    const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        setPendingRequests(new Set(userData.sentFriendRequests || []));
      }
    }, (err) => {
      console.error("Error listening to user updates:", err);
      setError('Failed to get realtime updates');
    });

    return () => unsubscribe();
  }, []);

  /**
   * Search for users by name, username, or phone
   * @param {string} term - Search term (optional, uses state if not provided)
   */
  const handleSearch = useCallback(async (term) => {
    const value = term ?? searchTerm;
    if (term !== undefined) setSearchTerm(value);
    setError(null);
    
    if (!value.trim()) {
      setSearchResults([]);
      return [];
    }

    setLoading(true);
    try {
      const results = await searchUsers(value);
      setSearchResults(results);
      return results;
    } catch (err) {
      console.error("Search error:", err);
      setError(err.message || 'Search failed');
      return [];
    } finally {
      setLoading(false);
    }
  }, [searchTerm]);

  /**
   * Send a friend request to a user
   * @param {string} userId - ID of user to send request to
   */
  const handleSendRequest = useCallback(async (userId) => {
    setError(null);
    
    try {
      await sendFriendRequest(userId);
      // pendingRequests will update automatically via the subscription
      return true;
    } catch (err) {
      console.error("Error sending friend request:", err);
      setError(err.message || 'Failed to send request');
      return false;
    }
  }, []);

  /**
   * Check if a request has been sent to a user
   * @param {string} userId - User ID to check
   * @returns {boolean} Whether request is pending
   */
  const isRequestPending = useCallback((userId) => {
    return pendingRequests.has(userId);
  }, [pendingRequests]);

  return {
    searchTerm,
    searchResults,
    loading,
    error,
    pendingRequests,
    setSearchTerm,
    search: handleSearch,
    sendRequest: handleSendRequest,
    isRequestPending,
    clearSearch: () => {
      setSearchTerm('');
      setSearchResults([]);
    }
  };
};

export default useFriendSearch; 