// src/components/GroupChatInterface.js

import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { Send, Loader, Image, X } from 'lucide-react';
import { format, isToday, isYesterday, formatDistanceToNow } from 'date-fns';
import { auth } from '../firebase';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';

// Import chat service functions
import {
  subscribeToGroupMessages,
  loadMoreGroupMessages,
  sendGroupMessage,
  uploadGroupChatImages,
  markGroupMessagesAsRead,
  toggleGroupNotifications,
  toggleGroupBotResponses,
  getUsersData,
  triggerBotResponse,
  subscribeToGroup,
  subscribeToMembership
} from '../services/groupChatService';

import GroupHeader from './GroupHeader';
import GroupSettings from './GroupSettings';
import ImageUploader from './ImageUploader';
import DefaultAvatar from './DefaultAvatar';
import ChatPostList from './ChatPostList';
import DraftItem from './DraftItem';
import PostMessage from './PostMessage';
import AllPostsInGroup from './AllPostsInGroup';
import SkeletonGroupChat from './SkeletonGroupChat';
import MessageContextMenu from './MessageContextMenu';
import ReplyIndicator from './ReplyIndicator';
import ImagePreviewModal from './ImagePreviewModal';
import botIcon from '../assets/icon.png';
import './GroupChatInterface.css';
import './MessageContextMenu.css';

const MAX_IMAGES = 6;
const BATCH_SIZE = 50; // how many messages to load at a time
const LONG_PRESS_DURATION = 500; // Duration for long press in ms

/****************************************
 * Utilities for timestamp / separators *
 ****************************************/
const MessageTimestamp = ({ timestamp }) => {
  if (!timestamp) return null;
  
  const date = timestamp.toDate();
  let timeString = '';

  // For messages less than 1 hour old, show relative time
  const minutesAgo = Math.floor((Date.now() - date.getTime()) / (1000 * 60));
  if (minutesAgo < 60) {
    timeString = formatDistanceToNow(date, { addSuffix: true });
  } else {
    // For older messages, show time in h:mm a
    timeString = format(date, 'h:mm a');
  }

  return <span className="message-timestamp">{timeString}</span>;
};

const DateSeparator = ({ timestamp, t }) => {
  if (!timestamp) return null;
  
  const date = timestamp.toDate();
  let dateString = '';

  if (isToday(date)) {
    dateString = t('chat.today');
  } else if (isYesterday(date)) {
    dateString = t('chat.yesterday');
  } else {
    dateString = format(date, 'MMMM d');
  }

  return (
    <div className="date-separator">
      <div className="date-separator-content">{dateString}</div>
    </div>
  );
};

const MessageReply = ({ messageId, messages }) => {
  const replyTo = messages.find(msg => msg.id === messageId);
  
  if (!replyTo) return null;

  const handleReplyClick = (e) => {
    // Prevent event propagation to stop any parent handlers from interfering
    e.stopPropagation();
    
    const messageElement = document.getElementById(`message-${messageId}`);
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  // Handle touch for mobile devices
  const handleTouchStart = (e) => {
    // Prevent the long press from starting
    e.stopPropagation();
  };

  const handleTouchEnd = (e) => {
    // Prevent default behavior
    e.preventDefault();
    // Prevent propagation to parent elements
    e.stopPropagation();
    // Call the click handler
    handleReplyClick(e);
  };

  return (
    <div 
      className="message-reply-reference"
      onClick={handleReplyClick}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleReplyClick(e);
        }
      }}
    >
      <div className="reply-line" />
      <div className="reply-content">
        {replyTo.images?.length > 0 && (
          <Image size={14} className="reply-image-icon" />
        )}
        <span className="reply-text">{replyTo.text}</span>
      </div>
    </div>
  );
};

// Toast notification for copied text
const CopyToast = ({ text, onDismiss }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onDismiss();
    }, 2000);
    
    return () => clearTimeout(timer);
  }, [onDismiss]);
  
  return <div className="copy-toast">{text}</div>;
};

// Haptic feedback utility function
const triggerHapticFeedback = () => {
  if (navigator.vibrate) {
    // Light vibration for 20ms
    navigator.vibrate(20);
  }
};

/****************************************
 * Main GroupChatInterface component    *
 ****************************************/
const GroupChatInterface = ({ group: initialGroup, onBack }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [group, setGroup] = useState(initialGroup);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [error, setError] = useState(null);

  // Settings-related states
  const [isAdmin, setIsAdmin] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);
  const [botResponsesEnabled, setBotResponsesEnabled] = useState(true);

  // UI states
  const [showSettings, setShowSettings] = useState(false);
  const [showAllPosts, setShowAllPosts] = useState(false);

  // Pagination and references
  const [lastVisible, setLastVisible] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [joinedAt, setJoinedAt] = useState(null);

  // For user data caching
  const [users, setUsers] = useState({});

  // Image upload states
  const [uploadedImages, setUploadedImages] = useState([]);
  const [showSendButton, setShowSendButton] = useState(false);

  // Context menu and reply states
  const [contextMenuPosition, setContextMenuPosition] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [replyingTo, setReplyingTo] = useState(null);
  const [showCopyToast, setShowCopyToast] = useState(false);
  const [pressingMessageId, setPressingMessageId] = useState(null);
  
  // Image preview state
  const [previewImageUrl, setPreviewImageUrl] = useState(null);

  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const messageListRef = useRef(null);
  const topOfListRef = useRef(null);
  const textareaRef = useRef(null);
  const longPressTimeoutRef = useRef(null);
  const pressingElementRef = useRef(null);

  // Memoize stable callback functions
  const handleCancelReply = useCallback(() => {
    setReplyingTo(null);
  }, []);

  // Memoize the sender data for the ReplyIndicator to prevent re-renders
  const replyingSenderData = useMemo(() => {
    if (!replyingTo) return null;
    return users[replyingTo.senderId] || {};
  }, [replyingTo, users]);
  
  /****************************************
   * Link rendering for Markdown          *
   ****************************************/
  const isInternalLink = (href) => {
    try {
      const baseURL = window.location.origin;
      const linkUrl = new URL(href, baseURL);
      return linkUrl.origin === baseURL;
    } catch (error) {
      // If parsing fails, assume internal
      return true;
    }
  };

  const getInternalPath = (href) => {
    try {
      const baseURL = window.location.origin;
      const linkUrl = new URL(href, baseURL);
      return linkUrl.pathname + linkUrl.search + linkUrl.hash;
    } catch (error) {
      return href;
    }
  };

  const CustomLinkRenderer = ({ href, children, ...props }) => {
    const isInternal = isInternalLink(href);

    if (isInternal) {
      const internalPath = getInternalPath(href);
      return (
        <Link to={internalPath} {...props}>
          {children}
        </Link>
      );
    } else {
      return (
        <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
          {children}
        </a>
      );
    }
  };

  /****************************************
   * Displaying All Posts in a group      *
   ****************************************/
  const handleShowAllPosts = () => {
    setShowAllPosts(true);
  };
  const handleHideAllPosts = () => {
    setShowAllPosts(false);
  };

  /****************************************
   * Membership and admin checks          *
   ****************************************/
  useEffect(() => {
    if (!group) return;
  
    const handleMemberUpdate = (memberData) => {
      setIsAdmin(memberData.role === 'admin');
      setJoinedAt(memberData.joinedAt);
      setNotificationsEnabled(memberData.notificationsEnabled);
      setBotResponsesEnabled(memberData.botResponsesEnabled);
      setError(null);
    };
    
    const handleError = (error) => {
      console.error('Error fetching member data:', error);
      setError('Failed to fetch membership data.');
    };
    
    const unsubscribe = subscribeToMembership(
      group.id,
      handleMemberUpdate,
      handleError
    );
  
    return () => unsubscribe();
  }, [group]);

  /****************************************
   * Sync group data                      *
   ****************************************/
  useEffect(() => {
    if (!group?.id) return;
    
    const handleGroupUpdate = (updatedGroup) => {
      setGroup(updatedGroup);
    };
    
    const handleError = (error) => {
      console.error('Error syncing group data:', error);
    };
    
    const unsubscribe = subscribeToGroup(
      group.id,
      handleGroupUpdate,
      handleError
    );
    
    return () => unsubscribe();
  }, [group?.id]);

  /****************************************
   * Subscribe to initial messages        *
   ****************************************/
  useEffect(() => {
    if (!group?.id || !joinedAt) return;

    const handleMessagesUpdate = ({ messages: newMessages, lastVisible, hasMore, senderIds }) => {
      setMessages(newMessages);
      setLastVisible(lastVisible);
      setHasMoreMessages(hasMore);
      
      // Use the service to fetch user data for all senders
      getUsersData(senderIds).then(usersData => {
        setUsers(prev => ({ ...prev, ...usersData }));
      });
      
      if (!initialLoadComplete) {
        setInitialLoadComplete(true);
      }
    };
    
    const handleError = (error) => {
      console.error('Error fetching group messages:', error);
      setError(t('chat.load_error'));
    };

    const unsubscribe = subscribeToGroupMessages(
      group.id,
      joinedAt,
      BATCH_SIZE,
      handleMessagesUpdate,
      handleError
    );

    return () => unsubscribe();
  }, [group?.id, joinedAt, t, initialLoadComplete]);

  /****************************************
   * Scroll after initial load            *
   ****************************************/
  useEffect(() => {
    if (initialLoadComplete && messageListRef.current && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [initialLoadComplete]);

  /****************************************
   * Load more messages when user scrolls *
   ****************************************/
  const loadMoreMessages = useCallback(async () => {
    if (!group?.id || !lastVisible || isLoadingMore || !hasMoreMessages || !joinedAt) {
      return;
    }

    setIsLoadingMore(true);

    try {
      // Remember the current scroll position
      const listElem = messageListRef.current;
      const prevScrollHeight = listElem.scrollHeight;
      const prevScrollTop = listElem.scrollTop;

      // Load more messages using our service
      const { messages: olderMessages, lastVisible: newLastVisible, hasMore, senderIds } = 
        await loadMoreGroupMessages(group.id, joinedAt, lastVisible, BATCH_SIZE);

      if (olderMessages.length > 0) {
        // Fetch user data using our service
        const userData = await getUsersData(senderIds);
        setUsers(prev => ({ ...prev, ...userData }));
        
        // Prepend older messages
        setMessages((prev) => [...olderMessages, ...prev]);
        setLastVisible(newLastVisible);
        setHasMoreMessages(hasMore);

        // Adjust scroll so user remains at the same position
        requestAnimationFrame(() => {
          const newScrollHeight = listElem.scrollHeight;
          listElem.scrollTop = newScrollHeight - prevScrollHeight + prevScrollTop;
        });
      } else {
        setHasMoreMessages(false);
      }
    } catch (err) {
      console.error('Error loading more messages:', err);
      setError(t('chat.load_more_error'));
    } finally {
      setIsLoadingMore(false);
    }
  }, [group?.id, lastVisible, isLoadingMore, hasMoreMessages, joinedAt, t]);

  /****************************************
   * IntersectionObserver to load older   *
   ****************************************/
  useEffect(() => {
    if (!topOfListRef.current || !hasMoreMessages) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoadingMore) {
          loadMoreMessages();
        }
      },
      { root: null, rootMargin: '100px', threshold: 0.1 }
    );

    observer.observe(topOfListRef.current);

    return () => {
      observer.disconnect();
    };
  }, [topOfListRef, hasMoreMessages, isLoadingMore, loadMoreMessages]);

  /****************************************
   * Auto-scroll to bottom only if near   *
   ****************************************/
  useEffect(() => {
    if (!initialLoadComplete) return; // skip if not fully loaded

    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      const nearBottom = scrollHeight - scrollTop - clientHeight < 300;
      if (nearBottom) {
        requestAnimationFrame(() => {
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        });
      }
    }
  }, [messages.length, initialLoadComplete]);

  /****************************************
   * Update lastReadAt when focusing      *
   ****************************************/
  useEffect(() => {
    const updateLastReadAt = async () => {
      if (group?.id) {
        try {
          await markGroupMessagesAsRead(group.id);
        } catch (err) {
          console.error('Error updating read status:', err);
        }
      }
    };

    updateLastReadAt();

    const handleFocus = () => {
      updateLastReadAt();
    };

    const textarea = textareaRef.current;
    if (textarea) {
      textarea.addEventListener('focus', handleFocus);
    }
    return () => {
      if (textarea) {
        textarea.removeEventListener('focus', handleFocus);
      }
    };
  }, [group?.id]);

  /****************************************
   * Auto-resize textarea                 *
   ****************************************/
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '48px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  /****************************************
   * Image upload handlers                *
   ****************************************/
  const handleFileSelect = async (files) => {
    try {
      const imagesData = await uploadGroupChatImages(group.id, files);
      setUploadedImages((prevImages) => [...prevImages, ...imagesData]);
      setShowSendButton(true);
    } catch (error) {
      console.error('Error uploading images:', error);
      setError(t('chat.image_upload_error'));
    }
  };

  const handleRemoveImage = (index) => {
    setUploadedImages((prevImages) => {
      const newImages = prevImages.filter((_, i) => i !== index);
      // Update send button state based on input and images
      setShowSendButton(input.trim().length > 0 || newImages.length > 0);
      return newImages;
    });
  };

  const handleImageIconClick = () => {
    fileInputRef.current.click();
  };

  /****************************************
   * Group Settings actions               *
   ****************************************/
  const handleOpenSettings = () => {
    setShowSettings(true);
  };
  const handleCloseSettings = () => {
    setShowSettings(false);
  };

  const handleUpdateGroup = (updatedGroup) => {
    setGroup(updatedGroup);
  };

  const handleLeaveGroup = () => {
    navigate('/groups');
  };

  const handleToggleNotifications = async () => {
    try {
      const success = await toggleGroupNotifications(group.id, !notificationsEnabled);
      if (success) {
        setNotificationsEnabled(!notificationsEnabled);
      }
    } catch (err) {
      console.error('Error toggling notifications:', err);
      setError(t('groups.notification_toggle_error'));
    }
  };

  const handleToggleBotResponses = async () => {
    try {
      const success = await toggleGroupBotResponses(group.id, !botResponsesEnabled);
      if (success) {
        setBotResponsesEnabled(!botResponsesEnabled);
      }
    } catch (err) {
      console.error('Error toggling bot responses:', err);
      setError(t('groups.bot_toggle_error'));
    }
  };

  /****************************************
   * Image load callback to help scroll   *
   ****************************************/
  const handleImageLoad = useCallback(() => {
    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      const nearBottom = scrollHeight - scrollTop - clientHeight < 300;
      if (nearBottom) {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
      }
    }
  }, []);

  /****************************************
   * Helper to clear pressing state       *
   ****************************************/
  const clearPressingState = useCallback(() => {
    if (pressingElementRef.current) {
      pressingElementRef.current.classList.remove('pressing');
      pressingElementRef.current = null;
    }
    setPressingMessageId(null);
    
    if (longPressTimeoutRef.current) {
      clearTimeout(longPressTimeoutRef.current);
      longPressTimeoutRef.current = null;
    }
  }, []);

  /****************************************
   * Context Menu / Long Press Handlers   *
   ****************************************/
  // Handle message press start (for both long-press and right-click)
  const handleMessageMouseDown = useCallback((message, e, element) => {
    // Check if user clicked on a link or image - if so, allow normal behavior
    if (e.target.closest('a') || e.target.closest('img')) {
      return;
    }
    
    // Clear any existing press state
    clearPressingState();
    
    // Set pressing state immediately for visual feedback
    if (element) {
      element.classList.add('pressing');
      pressingElementRef.current = element;
      setPressingMessageId(message.id);
    }
    
    // Set a timeout for long press
    longPressTimeoutRef.current = setTimeout(() => {
      // Trigger haptic feedback
      triggerHapticFeedback();
      
      // Set context menu
      setSelectedMessage(message);
      setContextMenuPosition({
        x: e.clientX,
        y: e.clientY
      });
      
      // Prevent default text selection
      e.preventDefault();
      
      // Clear pressing state
      if (pressingElementRef.current) {
        pressingElementRef.current.classList.remove('pressing');
      }
    }, LONG_PRESS_DURATION);
  }, [clearPressingState]);

  // Handle mouse up to cancel long press
  const handleMessageMouseUp = useCallback(() => {
    clearPressingState();
  }, [clearPressingState]);

  // Handle right-click on message for desktop context menu
  const handleMessageContextMenu = useCallback((message, e) => {
    e.preventDefault(); // Prevent the default browser context menu
    
    // Clear any existing press state
    clearPressingState();
    
    // Trigger haptic feedback
    triggerHapticFeedback();
    
    // Get the message element's position
    const messageElement = e.currentTarget;
    const messageRect = messageElement.getBoundingClientRect();
    
    // Determine if the message is on the left or right side
    const isRightSide = messageRect.right > window.innerWidth / 2;
    
    // Position the context menu near the clicked message
    // If the message is on the right side, position the menu on the left of the click point
    // If the message is on the left side, position the menu on the right of the click point
    const menuX = isRightSide ? 
      Math.max(e.clientX - 180 + 20, 10) : // 180 is approx menu width, 10 is a margin
      Math.min(e.clientX - 20, window.innerWidth - 190); // 190 is menu width + margin
    
    setSelectedMessage(message);
    setContextMenuPosition({
      x: menuX,
      y: e.clientY - 10 // Position just above the click point
    });
  }, [clearPressingState]);

  // Handle start reply
  const handleReply = useCallback(() => {
    setReplyingTo(selectedMessage);
    setContextMenuPosition(null);
    setSelectedMessage(null);
    
    // Focus on the input after setting up reply
    setTimeout(() => {
      textareaRef.current?.focus();
    }, 100);
  }, [selectedMessage]);

  // Handle copy text
  const handleCopyText = useCallback(() => {
    if (selectedMessage && selectedMessage.text) {
      navigator.clipboard.writeText(selectedMessage.text)
        .then(() => {
          // Show toast notification
          setShowCopyToast(true);
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
        });
    }
    setContextMenuPosition(null);
    setSelectedMessage(null);
  }, [selectedMessage]);

  // Close context menu
  const closeContextMenu = useCallback(() => {
    setContextMenuPosition(null);
    setSelectedMessage(null);
  }, []);

  /****************************************
   * Touch event handlers for mobile      *
   ****************************************/
  useEffect(() => {
    const handleTouchStart = (e) => {
      // Check if user is tapping on a link or image - if so, allow normal behavior
      if (e.target.closest('a') || e.target.closest('img')) {
        return;
      }
      
      const messageElement = e.target.closest('.message');
      if (messageElement) {
        const messageId = messageElement.getAttribute('data-message-id');
        const message = messages.find(m => m.id === messageId);
        if (message) {
          // Set visual feedback immediately
          messageElement.classList.add('pressing');
          pressingElementRef.current = messageElement;
          setPressingMessageId(message.id);
          
          // Set a timeout for long press
          longPressTimeoutRef.current = setTimeout(() => {
            // Trigger haptic feedback
            triggerHapticFeedback();
            
            setSelectedMessage(message);
            
            // Position context menu near the touch position
            const touch = e.touches[0];
            const touchX = touch.clientX;
            const touchY = touch.clientY;
            
            // Get message position
            const messageRect = messageElement.getBoundingClientRect();
            const isRightSide = messageRect.right > window.innerWidth / 2;
            
            // Set optimized menu position
            const menuX = isRightSide ? 
              Math.max(touchX - 180 + 20, 10) : 
              Math.min(touchX - 20, window.innerWidth - 190);
              
            setContextMenuPosition({
              x: menuX,
              y: touchY - 10
            });
            
            // Remove pressing class
            messageElement.classList.remove('pressing');
            
            // Prevent default behavior
            e.preventDefault();
          }, LONG_PRESS_DURATION);
        }
      }
    };

    const handleTouchEnd = () => {
      clearPressingState();
    };

    const handleTouchMove = () => {
      // If the user moves their finger, cancel long press and visual feedback
      clearPressingState();
    };

    document.addEventListener('touchstart', handleTouchStart, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
    document.addEventListener('touchmove', handleTouchMove);
    
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [messages, clearPressingState]);

  /****************************************
   * Sending a message                    *
   ****************************************/
  const sendMessage = async (e) => {
    e.preventDefault();
    if ((input.trim() === '' && uploadedImages.length === 0) || !group) return;

    if (!auth.currentUser) return;

    setIsLoading(true);
    setError(null);

    try {
      // Send message using our service
      const { id: messageId } = await sendGroupMessage(
        group.id,
        input,
        uploadedImages,
        replyingTo?.id || null
      );

      // Reset form and reply state
      setInput('');
      setUploadedImages([]);
      setShowSendButton(false);
      setReplyingTo(null);
      
      // Force scroll to bottom immediately after sending
      requestAnimationFrame(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      });

      // If bot is enabled, trigger bot response
      if (botResponsesEnabled) {
        triggerBotResponse(group.id, messageId, input, uploadedImages);
      }
    } catch (err) {
      console.error('Error sending message:', err);
      setError(t('chat.send_error'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const val = e.target.value;
    setInput(val);
    setShowSendButton(val.trim().length > 0 || uploadedImages.length > 0);
  };

  /****************************************
   * Render skeleton while loading        *
   ****************************************/
  if (!group || !joinedAt || !initialLoadComplete) {
    return <SkeletonGroupChat />;
  }

  /****************************************
   * MAIN RENDER                          *
   ****************************************/
  return (
    <div className="group-chat-interface">

      <GroupHeader
        group={group}
        onBack={onBack}
        onOpenSettings={handleOpenSettings}
        onToggleBotResponses={handleToggleBotResponses}
        botResponsesEnabled={botResponsesEnabled}
        onShowAllPosts={handleShowAllPosts}
      />

      {/* Messages Container */}
      <div className="message-list" ref={messageListRef}>

        {/* Sentinel div for IntersectionObserver */}
        <div ref={topOfListRef} />

        {isLoadingMore && (
          <div className="loading-more">
            <div className="spinner" />
            <span>{t('chat.loading_more')}</span>
          </div>
        )}
        {hasMoreMessages && !isLoadingMore && (
          <div className="scroll-indicator">{t('chat.scroll_to_load')}</div>
        )}

        {messages.map((message, index) => {
          const isCurrentUser = message.senderId === auth.currentUser?.uid;
          const isBot = message.senderId === 'bot';
          const sender = users[message.senderId] || {};

          // Decide if we show a date separator
          // e.g., new day or the very first message
          let showDateSeparator = false;
          if (index === 0) {
            showDateSeparator = true;
          } else {
            const currentMsgDate = message.timestamp?.toDate();
            const prevMsgDate = messages[index - 1].timestamp?.toDate();
            if (!isToday(currentMsgDate) && isToday(prevMsgDate)) {
              showDateSeparator = true;
            }
            if (isYesterday(currentMsgDate) && !isYesterday(prevMsgDate)) {
              showDateSeparator = true;
            }
            // Or any other custom check for day changes
            if (
              currentMsgDate &&
              prevMsgDate &&
              currentMsgDate.getDate() !== prevMsgDate.getDate()
            ) {
              showDateSeparator = true;
            }
          }

          // Is this the first message from this sender in a consecutive sequence?
          const isFirstInSequence =
            index === 0 ||
            messages[index - 1].senderId !== message.senderId;

          return (
            <React.Fragment key={message.id}>
              {showDateSeparator && message.timestamp && (
                <DateSeparator timestamp={message.timestamp} t={t} />
              )}

              <div
                id={`message-${message.id}`}
                className={`message-container ${
                  isCurrentUser ? 'sent' : 'received'
                } ${isBot ? 'bot' : ''}`}
              >
                {/* Sender avatar and display name (non-user or bot) */}
                {!isCurrentUser && !isBot && isFirstInSequence && (
                  <>
                    <div className="avatar-container">
                      <Link
                        to={`/profile/${message.senderId}`}
                        aria-label={`${sender.displayName} profile`}
                      >
                        {sender.profilePictureUrl ? (
                          <img
                            src={sender.profilePictureUrl}
                            alt={sender.displayName || t('chat.user')}
                            className="sender-profile-picture"
                          />
                        ) : (
                          <DefaultAvatar
                            name={sender.displayName}
                            size={32}
                            fallbackColor="var(--color-primary-light)"
                            textColor="var(--color-surface)"
                            className="sender-profile-picture"
                          />
                        )}
                      </Link>
                    </div>
                    <div className="sender-display-name">
                      {sender.displayName || t('chat.user')}
                    </div>
                  </>
                )}

                {/* Bot messages avatar/name */}
                {!isCurrentUser && isBot && isFirstInSequence && (
                  <>
                    <div className="avatar-container">
                      <Link to="/" aria-label={t('chat.view_home')}>
                        <img
                          src={botIcon}
                          alt={t('chat.affin_icon')}
                          className="sender-profile-picture"
                        />
                      </Link>
                    </div>
                    <div className="sender-display-name">
                      {t('app_name')}
                    </div>
                  </>
                )}

                {/* Actual message bubble */}
                {(message.type === 'user' || message.type === 'bot') && (
                  <div
                    className={`message ${isCurrentUser ? 'sent' : 'received'} ${
                      replyingTo?.id === message.id ? 'replying-to' : ''
                    } ${pressingMessageId === message.id ? 'pressing' : ''}`}
                    data-message-id={message.id}
                    onMouseDown={(e) => handleMessageMouseDown(message, e, e.currentTarget)}
                    onMouseUp={handleMessageMouseUp}
                    onMouseLeave={handleMessageMouseUp}
                    onContextMenu={(e) => handleMessageContextMenu(message, e)}
                  >
                    {message.inReplyTo && (
                      <MessageReply messageId={message.inReplyTo} messages={messages} />
                    )}
                    <div className="message-content">
                      {message.images?.map((img, idx) => (
                        <img
                          key={idx}
                          src={img.url}
                          alt={
                            img.name || t('chat.uploaded_image', { index: idx + 1 })
                          }
                          className="message-image"
                          loading="lazy"
                          onLoad={handleImageLoad}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering parent click handlers
                            setPreviewImageUrl(img.url);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      ))}
                      <ReactMarkdown
                        components={{ a: CustomLinkRenderer }}
                        remarkPlugins={[remarkGfm, remarkBreaks]}
                      >
                        {message.text}
                      </ReactMarkdown>
                    </div>
                    {message.timestamp && (
                      <MessageTimestamp timestamp={message.timestamp} />
                    )}
                  </div>
                )}

                {/* Bot "search_results" type messages */}
                {isBot && message.type === 'search_results' && message.postIds && (
                  <ChatPostList postIds={message.postIds} />
                )}

                {/* Bot "draft_post" type messages */}
                {isBot && message.type === 'draft_post' && message.draftId && (
                  <DraftItem draftId={message.draftId} groupId={group.id} />
                )}

                {/* PostMessage for "post" type */}
                {message.type === 'post' && message.postId && (
                  <PostMessage postId={message.postId} />
                )}
              </div>
            </React.Fragment>
          );
        })}

        <div ref={messagesEndRef} />
      </div>

      {/* Context Menu */}
      {contextMenuPosition && (
        <MessageContextMenu
          position={contextMenuPosition}
          onReply={handleReply}
          onCopy={handleCopyText}
          onClose={closeContextMenu}
          targetMessage={selectedMessage}
        />
      )}
      
      {/* Copy toast notification */}
      {showCopyToast && (
        <CopyToast 
          text={t('chat.text_copied')} 
          onDismiss={() => setShowCopyToast(false)} 
        />
      )}

      {/* Error display */}
      {error && <div className="error-message">{error}</div>}

      {/* Input area + images preview + reply indicator */}
      <div className="message-input-container">
        {/* Use our memoized ReplyIndicator component */}
        {replyingTo && (
          <ReplyIndicator
            message={replyingTo}
            sender={replyingSenderData}
            onCancel={handleCancelReply}
          />
        )}
        
        {uploadedImages.length > 0 && (
          <div className="uploaded-images-preview">
            {uploadedImages.map((img, index) => (
              <div key={index} className="uploaded-image-container">
                <img
                  src={img.url}
                  alt={t('chat.uploaded_image', { index: index + 1 })}
                  className="uploaded-image"
                />
                <button
                  onClick={() => handleRemoveImage(index)}
                  className="remove-image-button"
                  aria-label={t('chat.remove_image')}
                >
                  <X size={20} />
                </button>
              </div>
            ))}
          </div>
        )}

        <form onSubmit={sendMessage} className="message-input">
          <button
            type="button"
            onClick={handleImageIconClick}
            className="image-upload-button"
            aria-label={t('chat.upload_images')}
            disabled={uploadedImages.length >= MAX_IMAGES || isLoading}
            title={
              uploadedImages.length >= MAX_IMAGES
                ? t('chat.max_images_reached', { max: MAX_IMAGES })
                : t('chat.upload_images')
            }
          >
            <Image size={22} />
          </button>

          <textarea
            ref={textareaRef}
            value={input}
            onChange={handleInputChange}
            placeholder={
              replyingTo 
                ? t('chat.reply_placeholder') 
                : t('chat.type_message')
            }
            disabled={isLoading}
            rows={1}
          />

          <button
            type="submit"
            disabled={
              isLoading || (input.trim().length === 0 && uploadedImages.length === 0)
            }
            className={`send-button ${showSendButton ? 'visible' : ''} ${
              isLoading ? 'loading' : ''
            }`}
            aria-label={
              isLoading ? t('chat.processing_message') : t('chat.send_message')
            }
          >
            {isLoading ? <Loader size={20} className="loader" /> : <Send size={20} />}
          </button>
        </form>
      </div>

      {/* Hidden file input for image uploading */}
      <ImageUploader
        onFilesSelected={handleFileSelect}
        fileInputRef={fileInputRef}
        currentImageCount={uploadedImages.length}
        maxImages={MAX_IMAGES}
      />

      {/* Settings pane */}
      {showSettings && (
        <GroupSettings
          group={group}
          onClose={handleCloseSettings}
          onUpdate={handleUpdateGroup}
          onLeaveGroup={handleLeaveGroup}
          notificationsEnabled={notificationsEnabled}
          onToggleNotifications={handleToggleNotifications}
          isAdmin={isAdmin}
        />
      )}

      {/* All posts view */}
      {showAllPosts && (
        <AllPostsInGroup
          groupId={group.id}
          onClose={handleHideAllPosts}
        />
      )}
      
      {/* Image Preview Modal */}
      {previewImageUrl && (
        <ImagePreviewModal
          imageUrl={previewImageUrl}
          onClose={() => setPreviewImageUrl(null)}
        />
      )}
    </div>
  );
};

export default GroupChatInterface;