// src/hooks/useDraft.js
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getDraft } from '../services/draftService'; // Use the new service

/**
 * Hook to fetch and manage state for a single draft.
 * @param {string} draftId - The ID of the draft to manage.
 * @returns {object} { draft: object | null, isLoading: boolean, error: string | null, refetch: function, setDraft: function }
 */
const useDraft = (draftId) => {
  const { t } = useTranslation();
  const [draft, setDraft] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDraftData = useCallback(async () => {
    if (!draftId) {
      setDraft(null);
      setIsLoading(false);
      setError(null);
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      const draftData = await getDraft(draftId);
      setDraft(draftData);
    } catch (err) {
      console.error('Error in useDraft hook:', err);
      // Use specific translations based on error message from service
      setError(
        err.message === 'Draft not found'
          ? t('drafts.not_found')
          : t('drafts.fetch_error')
      );
      setDraft(null); // Ensure draft is null on error
    } finally {
      setIsLoading(false);
    }
  }, [draftId, t]); // t is included as it's used for error messages

  useEffect(() => {
    fetchDraftData();
  }, [fetchDraftData]); // Depend on the memoized fetch function

  // Provide a way to manually refetch the draft if needed
  const refetch = () => {
    fetchDraftData();
  };

  return { draft, isLoading, error, refetch, setDraft }; // Return setDraft for optimistic updates if needed later
};

export default useDraft; 