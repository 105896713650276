// src/components/PostList.js
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PostItem from './PostItem';
import { useInfiniteHits, useSearchBox, useInstantSearch } from 'react-instantsearch';

const PostList = ({
  defaultPosts,
  isCurrentUser,
  activeTab,
  onPostDeleted,
  fetchMorePosts,
  hasMorePosts,
  fetchMoreSavedPosts,
  hasMoreSavedPosts,
  initialLoading,
  loadingMore
}) => {
  const { t } = useTranslation();
  const { hits, isLastPage, showMore } = useInfiniteHits();
  const { query } = useSearchBox();
  const { refresh } = useInstantSearch();

  const [deletedPostIds, setDeletedPostIds] = useState([]);
  const observerRef = useRef(null);
  
  useEffect(() => {
    const currentObserverRef = observerRef.current;
    if (!currentObserverRef) return;    

    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting) {
          if (query) {
            if (!isLastPage) {
              showMore();
            }
          } else if (activeTab === 'posts') {
            if (hasMorePosts && !loadingMore) {
              fetchMorePosts();
            }
          } else if (activeTab === 'saved') {
            if (hasMoreSavedPosts && !loadingMore) {
              fetchMoreSavedPosts();
            }
          }
        }
      },
      {
        root: null,
        rootMargin: '100px',
        threshold: 0.1,
      }
    );

    observer.observe(currentObserverRef);
    return () => {
      if (currentObserverRef) {
        observer.unobserve(currentObserverRef);
      }
    };
  }, [
    query,
    isLastPage,
    showMore,
    hasMorePosts,
    hasMoreSavedPosts,
    fetchMorePosts,
    fetchMoreSavedPosts,
    loadingMore,
    activeTab
  ]);

  const handlePostDelete = (deletedPostId) => {
    onPostDeleted(deletedPostId);
    setDeletedPostIds((prevIds) => [...prevIds, deletedPostId]);
    refresh();
  };

  const showLoader = loadingMore && (activeTab === 'posts' || activeTab === 'saved');

  const displayPosts = query ? hits : defaultPosts;
  const filteredPosts = displayPosts.filter(
    post => !deletedPostIds.includes(post.objectID || post.id)
  );

  if (initialLoading) {
    return (
      <div className="space-y-4">
        {[...Array(3)].map((_, index) => (
          <div key={index} className="animate-pulse">
            <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
            <div className="h-20 bg-gray-200 rounded"></div>
          </div>
        ))}
      </div>
    );
  }  

  return (
    <div className="post-list">
      {filteredPosts.length === 0 ? (
        <p className="text-center py-4">
          {activeTab === 'posts' ? t('posts.no_posts') : t('profile.no_saved_posts')}
        </p>
      ) : (
        <>
          <div className="space-y-4">
            {filteredPosts.map(post => (
              <PostItem
                key={post.objectID || post.id}
                post={post}
                isCurrentUser={isCurrentUser}
                onPostDeleted={handlePostDelete}
              />
            ))}
          </div>

          {(activeTab === 'posts' || activeTab === 'saved' || query) && (
            <div 
              ref={observerRef} 
              className="h-4 mt-4"
            />
          )}
          
          {showLoader && (
            <div className="flex justify-center py-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
          )}          
        </>
      )}
    </div>
  );
};

export default PostList;