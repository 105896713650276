// src/hooks/useUserProfile.js
import { useState, useEffect } from 'react';
import { getCurrentUser, getUserProfile } from '../services/userService';
import { checkFriendshipStatus } from '../services/friendshipService';
import { useTranslation } from 'react-i18next';

/**
 * Hook for viewing and managing another user's profile
 * @param {string} userId - The user ID to fetch profile for
 * @returns {Object} Profile data and management functions
 */
const useUserProfile = (userId) => {
  const { t } = useTranslation();
  const [profile, setProfile] = useState(null);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [profileError, setProfileError] = useState(null);
  const [friendStatus, setFriendStatus] = useState('none');

  useEffect(() => {
    const fetchProfile = async () => {
      setLoadingProfile(true);
      const currentUser = getCurrentUser();

      if (!currentUser) {
        setProfileError(t('errors.user_not_authenticated'));
        setLoadingProfile(false);
        return;
      }

      const isOwnProfile = userId === currentUser.uid;
      setIsCurrentUser(isOwnProfile);

      try {
        // Fetch the user profile
        const userProfile = await getUserProfile(userId);
        setProfile(userProfile);
        
        // Check friendship status if not viewing own profile
        if (!isOwnProfile) {
          const status = await checkFriendshipStatus(userId);
          setFriendStatus(status);
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        setProfileError(
          error.message === 'User not found' 
            ? t('profile.not_found') 
            : t('profile.fetch_error')
        );
        setProfile(null);
      } finally {
        setLoadingProfile(false);
      }
    };

    if (userId) {
      fetchProfile();
    }
  }, [userId, t]);

  /**
   * Send friend request to this profile's user
   * @returns {Promise<boolean>} Success status of the friend request
   */
  const sendFriendRequest = async () => {
    try {
      const { sendFriendRequest } = await import('../services/friendshipService');
      await sendFriendRequest(userId);
      setFriendStatus('requested');
      return true;
    } catch (error) {
      console.error('Error sending friend request:', error);
      return false;
    }
  };

  /**
   * Remove friendship with this profile's user
   * @returns {Promise<boolean>} Success status of the friend removal
   */
  const removeFriendship = async () => {
    try {
      const { removeFriend } = await import('../services/friendshipService');
      await removeFriend(userId);
      setFriendStatus('none');
      return true;
    } catch (error) {
      console.error('Error removing friend:', error);
      return false;
    }
  };

  return { 
    profile, 
    isCurrentUser, 
    loadingProfile, 
    profileError, 
    setProfile,
    friendStatus,
    sendFriendRequest,
    removeFriendship
  };
};

export default useUserProfile;