import { useState, useCallback } from 'react';
import { addGroupMembers, getAvailableFriendsForGroup } from '../services/groupService';

/**
 * Hook for managing group members
 */
const useGroupMembers = (group, existingMembers) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Filter friends who aren't already members of the group
   * @param {Array} friendsList - List of friends
   * @returns {Array} Friends who can be added to the group
   */
  const getAvailableFriends = useCallback((friendsList) => {
    return getAvailableFriendsForGroup(friendsList, existingMembers);
  }, [existingMembers]);

  /**
   * Add members to the group
   * @param {Array} memberIds - Array of user IDs to add as members
   * @returns {Promise<Array>} Added members with user data
   */
  const addMembers = useCallback(async (memberIds) => {
    if (!memberIds || memberIds.length === 0) {
      return [];
    }

    setLoading(true);
    setError(null);

    try {
      const addedMembers = await addGroupMembers(group, memberIds);
      return addedMembers;
    } catch (err) {
      console.error('Error adding members:', err);
      setError(err.message || 'Failed to add members');
      throw err;
    } finally {
      setLoading(false);
    }
  }, [group]);

  return {
    loading,
    error,
    getAvailableFriends,
    addMembers
  };
};

export default useGroupMembers; 