// src/components/ChatPostItem.js

import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Heart, MessageCircle, Share2, ChevronDown, ChevronUp, Send, Bookmark } from 'lucide-react';
import Button from './Button';
import DefaultAvatar from './DefaultAvatar';
import { auth } from '../firebase';
import { usePost } from '../hooks/usePost';
import { checkIfPostIsSaved } from '../services/postService';
import { getUserProfile } from '../services/userService';
import './ChatPostItem.css';

const ChatPostItem = ({ post }) => {
  const { t } = useTranslation();
  const currentUser = auth.currentUser;
  const { likePost, unlikePost, commentOnPost, sharePost, savePost, unsavePost } = usePost();
  
  // State management
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [likesCount, setLikesCount] = useState(post.likes?.length || 0);
  const [isCommentSectionVisible, setIsCommentSectionVisible] = useState(false);
  const [commentInput, setCommentInput] = useState('');
  const [shareMessage, setShareMessage] = useState('');
  const [commenters, setCommenters] = useState({});
  const [visibleComments, setVisibleComments] = useState([]);
  const [commentsPage, setCommentsPage] = useState(1);
  
  const commentsPerPage = 5;

  // Initialize states based on current user and post data
  useEffect(() => {
    if (currentUser && post.likes) {
      setIsLiked(post.likes.includes(currentUser.uid));
    }
  }, [currentUser, post.likes]);

  useEffect(() => {
    setLikesCount(post.likes?.length || 0);
  }, [post.likes]);

  useEffect(() => {
    const fetchSavedStatus = async () => {
      if (!currentUser) return;
      
      try {
        const saved = await checkIfPostIsSaved(post.id);
        setIsSaved(saved);
      } catch (error) {
        console.error('Error checking saved status:', error);
        setIsSaved(false);
      }
    };
    
    fetchSavedStatus();
  }, [currentUser, post.id]);

  useEffect(() => {
    const fetchCommenters = async () => {
      const commenterIds = [...new Set(post.comments?.map(comment => comment.userId) || [])];
      const newCommenters = {};
      
      for (const userId of commenterIds) {
        try {
          const userProfile = await getUserProfile(userId);
          newCommenters[userId] = userProfile.displayName || t('common.anonymous');
        } catch (error) {
          console.error(`Error fetching profile for commenter ${userId}:`, error);
          newCommenters[userId] = t('common.anonymous');
        }
      }
      
      setCommenters(newCommenters);
    };

    if (post.comments?.length > 0) {
      fetchCommenters();
    }
  }, [post.comments, t]);

  useEffect(() => {
    setVisibleComments(post.comments?.slice(0, commentsPage * commentsPerPage) || []);
  }, [post.comments, commentsPage]);

  const handleLike = useCallback(async () => {
    if (!currentUser) return;

    setIsLiked(prev => !prev);
    setLikesCount(prev => isLiked ? prev - 1 : prev + 1);

    try {
      if (isLiked) {
        await unlikePost(post.id);
      } else {
        await likePost(post.id);
      }
    } catch (error) {
      console.error('Error updating likes:', error);
      setIsLiked(prev => !prev);
      setLikesCount(prev => isLiked ? prev + 1 : prev - 1);
    }
  }, [currentUser, isLiked, post.id, likePost, unlikePost]);

  const handleSave = useCallback(async () => {
    if (!currentUser) return;

    setIsSaved(prev => !prev);

    try {
      if (isSaved) {
        await unsavePost(post.id);
      } else {
        await savePost(post.id);
      }
    } catch (error) {
      console.error('Error updating saved posts:', error);
      setIsSaved(prev => !prev);
    }
  }, [currentUser, isSaved, post.id, savePost, unsavePost]);

  const handleShare = useCallback(async () => {
    const shareableLink = `${window.location.origin}/post/${post.id}`;
    
    try {
      if (navigator.share) {
        await navigator.share({
          url: shareableLink,
        });
        setShareMessage(t('posts.share_success'));
      } else if (navigator.clipboard) {
        await navigator.clipboard.writeText(shareableLink);
        setShareMessage(t('posts.link_copied'));
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = shareableLink;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        setShareMessage(t('posts.link_copied'));
      }
    } catch (shareError) {
      console.error('Error with native share/copy:', shareError);
      setShareMessage(t('posts.share_error'));
      setTimeout(() => setShareMessage(''), 3000);
      return;
    }
    
    try {
      await sharePost(post.id);
    } catch (error) {
      console.error('Error updating share count on server:', error);
    }

    setTimeout(() => setShareMessage(''), 3000);
  }, [post.id, sharePost, t]);

  const autoResizeTextarea = useCallback((e) => {
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight + 'px';
  }, []);

  const handleCommentChange = useCallback((e) => {
    setCommentInput(e.target.value);
    autoResizeTextarea(e);
  }, [autoResizeTextarea]);

  const handleComment = useCallback(async (e) => {
    e.preventDefault();
    if (!currentUser || !commentInput.trim()) return;

    const newComment = {
      userId: currentUser.uid,
      content: commentInput.trim(),
      createdAt: new Date().toISOString(),
      replies: []
    };
    setVisibleComments(prev => [...prev, newComment]);
    setCommentInput('');

    try {
      await commentOnPost(post.id, newComment.content);
    } catch (error) {
      console.error('Error adding comment:', error);
      setVisibleComments(prev => prev.slice(0, -1));
    }
  }, [currentUser, commentInput, post.id, commentOnPost]);

  const formatDate = (dateString) => {
    try {
      if (dateString?.seconds) {
        return format(new Date(dateString.seconds * 1000), 'MMM d, yyyy HH:mm');
      }
      return format(new Date(dateString), 'MMM d, yyyy HH:mm');
    } catch (error) {
      console.error('Error formatting date:', error);
      return t('common.invalid_date');
    }
  };

  const loadMoreComments = useCallback(() => {
    setCommentsPage(prev => prev + 1);
  }, []);

  return (
    <div className="chat-post-item">
      <div className="post-header" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="post-header-content">
          <div className="post-author">
            <Link to={`/profile/${post.author?.id}`} onClick={e => e.stopPropagation()}>
              {post.author?.profilePictureUrl ? (
                <img
                  src={post.author.profilePictureUrl}
                  alt={post.author?.displayName || t('common.anonymous')}
                  className="author-avatar"
                />
              ) : (
                <DefaultAvatar
                  name={post.author?.displayName || t('common.anonymous')}
                  size={36}
                  fallbackColor="var(--color-primary-light)"
                  textColor="var(--color-surface)"
                />
              )}
            </Link>
            <div className="author-info">
              <h5 className="author-name">{post.author
                ? `${post.author.displayName} (@${post.author.username})`
                : t('common.anonymous')}
              </h5>
              <h4 className="post-title">{post.name}</h4>
            </div>
          </div>
          {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </div>
      </div>

      {isExpanded && (
        <div className="post-content">
          <div className="post-description">
            <ReactMarkdown>{post.description}</ReactMarkdown>
          </div>

          {post.link && (
            <a
              href={post.link}
              target="_blank"
              rel="noopener noreferrer"
              className="post-link"
            >
              {post.link}
            </a>
          )}

          <div className="post-meta">
            <span className="post-date">{formatDate(post.createdAt)}</span>
          </div>

          <div className="post-actions">
            <Button
              variant="tertiary"
              size="small"
              aria-label={isLiked ? t('posts.unlike_post') : t('posts.like_post')}
              onClick={handleLike}
              className="action-button"
            >
              <Heart fill={isLiked ? 'var(--color-primary)' : 'none'} />
              <span>{likesCount}</span>
            </Button>

            <Button
              variant="tertiary"
              size="small"
              aria-label={isCommentSectionVisible ? t('posts.hide_comments') : t('posts.show_comments')}
              onClick={() => setIsCommentSectionVisible(!isCommentSectionVisible)}
              className="action-button"
            >
              <MessageCircle />
              <span>{post.comments?.length || 0}</span>
            </Button>

            <Button
              variant="tertiary"
              size="small"
              aria-label={t('posts.share_post')}
              onClick={handleShare}
              className="action-button"
            >
              <Share2 />
            </Button>

            <Button
              variant="tertiary"
              size="small"
              aria-label={isSaved ? t('posts.unsave_post') : t('posts.save_post')}
              onClick={handleSave}
              className="action-button"
            >
              <Bookmark fill={isSaved ? 'var(--color-primary)' : 'none'} />
            </Button>
          </div>

          {shareMessage && (
            <div className="share-message">{shareMessage}</div>
          )}

          {isCommentSectionVisible && (
            <div className="comments-section">
              <h5>{t('comments.title')}</h5>
              
              <div className="comments-list">
                {visibleComments.map((comment, index) => (
                  <div key={index} className="comment">
                    <p>
                      <strong>{commenters[comment.userId] || t('common.anonymous')}: </strong>
                      {comment.content}
                    </p>
                    <small>{formatDate(comment.createdAt)}</small>
                  </div>
                ))}
              </div>

              {post.comments?.length > visibleComments.length && (
                <Button
                  variant="secondary"
                  size="small"
                  onClick={loadMoreComments}
                  className="load-more-button"
                >
                  {t('comments.load_more')}
                </Button>
              )}

              <form onSubmit={handleComment} className="comment-form">
                <div className="input-wrapper">
                  <textarea
                    value={commentInput}
                    onChange={handleCommentChange}
                    placeholder={t('comments.add_comment_placeholder')}
                    aria-label={t('comments.add_comment_aria')}
                    rows="1"
                    className="comment-input"
                  />
                  {commentInput.trim() && (
                    <button type="submit" className="send-button" aria-label={t('comments.send_comment')}>
                      <Send size={18} />
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatPostItem;