// src/hooks/usePostsSubscription.js
import { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { getUserProfile } from '../services/userService';

const usePostsSubscription = (postIds) => {
  const [postsById, setPostsById] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!postIds || postIds.length === 0) {
      setIsLoading(false);
      setPostsById({}); // Reset posts if postIds become empty
      return;
    }

    setIsLoading(true);
    setError(null);

    const unsubscribes = new Map();
    const authorDataCache = new Map();

    // Function to fetch author data
    const fetchAuthorData = async (userId) => {
      if (authorDataCache.has(userId)) {
        return authorDataCache.get(userId);
      }
      
      try {
        const authorData = await getUserProfile(userId);
        authorDataCache.set(userId, authorData);
        return authorData;
      } catch (err) {
        console.error(`Error fetching author data for ${userId}:`, err);
        // Return a default structure or null if author fetch fails
        return {
          id: userId,
          displayName: 'Unknown User',
          username: 'unknown',
          profilePictureUrl: null
        }; 
      }
    };

    postIds.forEach((postId) => {
      // Avoid setting up listener if already exists (e.g., postIds duplication)
      if (unsubscribes.has(postId)) return;

      const postRef = doc(db, 'posts', postId);
      
      const unsubscribe = onSnapshot(postRef, async (postSnap) => {
        if (postSnap.exists()) {
          const postData = postSnap.data();
          
          try {
            const authorData = await fetchAuthorData(postData.userId);
            
            const updatedPost = {
              id: postSnap.id,
              ...postData,
              // Safely access author properties
              author: authorData ? {
                id: authorData.id,
                username: authorData.username,
                displayName: authorData.displayName,
                profilePictureUrl: authorData.profilePictureUrl
              } : null // Handle case where authorData might be null/undefined
            };

            setPostsById(current => ({
              ...current,
              [postId]: updatedPost
            }));
          } catch (error) {
            console.error(`Error processing post ${postId}:`, error);
            setError(prevError => prevError || 'Error fetching post details'); // Set general error
          }
        } else {
          console.warn(`Post with ID ${postId} does not exist.`);
          // Remove the post from state if it no longer exists
          setPostsById(current => {
            const { [postId]: removed, ...rest } = current;
            return rest;
          });
        }
      }, (err) => {
        console.error(`Error listening to post ${postId}:`, err);
        setError(prevError => prevError || 'Error subscribing to posts'); // Set subscription error
        // Consider removing the specific post on error or keeping stale data
        setPostsById(current => {
          const { [postId]: removed, ...rest } = current;
          return rest;
        });
      });

      unsubscribes.set(postId, unsubscribe);
    });

    // Check if all initial posts have been loaded
    // This might need refinement depending on how "loading" should behave
    // For now, set loading to false once listeners are attached
    setIsLoading(false); 

    // Cleanup function
    return () => {
      unsubscribes.forEach(unsubscribe => unsubscribe());
    };
  }, [postIds]);

  return { postsById, isLoading, error };
};

export default usePostsSubscription; 