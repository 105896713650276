// src/pages/Friends.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import FriendSearch from '../components/FriendSearch/FriendSearch';
import FriendList from '../components/FriendList/FriendList';
import '../styles/Friends.css';
import useFriends from '../hooks/useFriends';

const Friends = () => {
  const navigate = useNavigate();
  const { 
    friends,
    loading, 
    markFriendAsViewed, 
    shouldHighlightFriend 
  } = useFriends();

  const handleFriendClick = async (friendId) => {
    try {
      // Update last viewed timestamp
      await markFriendAsViewed(friendId);
      
      // Navigate to the friend's profile
      navigate(`/profile/${friendId}`);
    } catch (error) {
      console.error('Error updating last viewed timestamp:', error);
      // Still navigate even if the timestamp update fails
      navigate(`/profile/${friendId}`);
    }
  };

  return (
    <div className="friends-page">
      <FriendSearch />
      <FriendList
        friends={friends}
        isLoading={loading}
        shouldHighlightFriend={shouldHighlightFriend}
        onFriendClick={handleFriendClick}
      />
    </div>
  );
};

export default Friends;
