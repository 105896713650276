import { useState, useEffect, useCallback } from 'react';
import { subscribeToUnreadGroupCount, resetUnreadCount } from '../services/groupService';

/**
 * Hook for managing group notifications and unread counts
 */
const useGroupNotifications = () => {
  const [unreadGroupCount, setUnreadGroupCount] = useState(0);
  const [error, setError] = useState(null);

  // Subscribe to unread group count
  useEffect(() => {
    const handleError = (err) => {
      console.error('Error in useGroupNotifications:', err);
      setError(err.message || 'Failed to load unread counts');
      setUnreadGroupCount(0);
    };

    const handleUpdate = (count) => {
      setUnreadGroupCount(count);
    };
    
    // Use the service to subscribe to unread counts
    const unsubscribe = subscribeToUnreadGroupCount(handleUpdate, handleError);
    
    return () => unsubscribe();
  }, []);

  /**
   * Reset unread count for a group
   * @param {string} groupId - The group ID to reset
   * @returns {Promise<boolean>} Success status
   */
  const markGroupAsRead = useCallback(async (groupId) => {
    try {
      const success = await resetUnreadCount(groupId);
      return success;
    } catch (err) {
      console.error('Error resetting unread count:', err);
      setError(err.message || 'Failed to mark as read');
      return false;
    }
  }, []);

  return {
    unreadGroupCount,
    error,
    markGroupAsRead
  };
};

export default useGroupNotifications; 