// src/utils/secureSearch.js
import { getFunctions, httpsCallable } from 'firebase/functions';
import { liteClient as algoliasearch } from 'algoliasearch/lite';

let cachedClient = null;
let cachedKeyExpirationTime = null;

export async function getSecuredSearchClient() {
  const currentTime = Date.now() / 1000;
  
  // Return cached client if it exists and isn't expired
  if (cachedClient && cachedKeyExpirationTime && currentTime < cachedKeyExpirationTime - 300) { // 5 min buffer
    return cachedClient;
  }

  try {
    // Get new secured key from Cloud Function
    const functions = getFunctions();
    const getAlgoliaSecuredApiKey = httpsCallable(functions, 'getAlgoliaSecuredApiKey');
    const { data } = await getAlgoliaSecuredApiKey();
    
    // Create new search client with secured key
    const newClient = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID,
      data.securedApiKey
    );

    // Cache the client and set expiration (1 hour from now)
    cachedClient = newClient;
    cachedKeyExpirationTime = currentTime + 3600;

    return newClient;
  } catch (error) {
    console.error('Error getting secured search client:', error);
    throw new Error('Failed to initialize secure search');
  }
}

export function clearSecuredSearchCache() {
  cachedClient = null;
  cachedKeyExpirationTime = null;
}