// src/contexts/PostContext.js
import React, { createContext, useState, useContext, useCallback } from 'react';

const PostContext = createContext();

export function PostProvider({ children }) {
  const [posts, setPosts] = useState([]);

  // Enhanced setPosts to handle both objectID and id fields
  const setPostsWithNormalization = useCallback((postsOrUpdateFn) => {
    if (typeof postsOrUpdateFn === 'function') {
      setPosts((currentPosts) => {
        const updatedPosts = postsOrUpdateFn(currentPosts);
        return updatedPosts;
      });
    } else {
      // Direct posts assignment
      setPosts(postsOrUpdateFn);
    }
  }, []);

  const value = {
    posts,
    setPosts: setPostsWithNormalization,
  };

  return <PostContext.Provider value={value}>{children}</PostContext.Provider>;
}

export function usePostContext() {
  const context = useContext(PostContext);
  if (!context) {
    throw new Error('usePostContext must be used within a PostProvider');
  }
  return context;
}