// src/pages/Profile.js
import React, { useState, useEffect } from 'react';
import ProfileSkeleton from '../components/ProfileSkeleton/ProfileSkeleton';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { InstantSearch, Configure } from 'react-instantsearch';
import ProfileForm from '../components/ProfileForm/ProfileForm';
import ProfileHeader from '../components/ProfileHeader/ProfileHeader';
import PostList from '../components/PostList';
import SearchBar from '../components/SearchBar';
import { PostProvider } from '../contexts/PostContext';
import { usePost } from '../hooks/usePost';
import useUserProfile from '../hooks/useUserProfile';
import useUserPosts from '../hooks/useUserPosts';
import { getSecuredSearchClient } from '../utils/secureSearch';
import { auth } from '../firebase';
import '../styles/Profile.css';

const Profile = ({ initialTab = 'posts' }) => {
  const { t } = useTranslation();
  const { userId: paramUserId } = useParams();
  const navigate = useNavigate();
  const currentUser = auth.currentUser;
  const profileUserId = paramUserId || (currentUser && currentUser.uid);

  // Use the new hooks
  const { 
    profile, 
    isCurrentUser, 
    loadingProfile, 
    profileError, 
    setProfile, 
    friendStatus, 
    sendFriendRequest 
  } = useUserProfile(profileUserId);
  
  const {
    userPosts,
    savedPosts,
    savedPostIds,
    initialPostsLoading,
    loadingMorePosts,
    postsError,
    setUserPosts,
    fetchMorePosts,
    hasMorePosts,
    fetchMoreSavedPosts,
    hasMoreSavedPosts
  } = useUserPosts(profileUserId);

  const [searchClient, setSearchClient] = useState(null);
  const [editing, setEditing] = useState(false);
  const [activeTab, setActiveTab] = useState(initialTab);
  const [searchState, setSearchState] = useState({});

  const { setPosts } = usePost();

  // Initialize secured search client
  useEffect(() => {
    let mounted = true;

    async function initializeSecuredSearch() {
      if (!currentUser) return;

      try {
        const client = await getSecuredSearchClient();
        if (mounted) {
          setSearchClient(client);
        }
      } catch (error) {
        console.error('Failed to initialize secured search:', error);
      }
    }

    initializeSecuredSearch();
    return () => { mounted = false; };
  }, [currentUser]);  

  // Reset activeTab when navigating to a different profile
  useEffect(() => {
    setActiveTab('posts');
  }, [profileUserId]);

  const handleSearchStateChange = (nextSearchState) => {
    setSearchState(nextSearchState);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleEditProfile = () => {
    setEditing(true);
  };

  const handleCancelEdit = () => {
    setEditing(false);
  };

  const handleProfileUpdate = (updatedProfile) => {
    setProfile(updatedProfile);
    setEditing(false);
  };

  const handlePostDelete = (deletedPostId) => {
    setUserPosts((currentPosts) => currentPosts.filter((post) => post.id !== deletedPostId));
    setPosts((currentPosts) => currentPosts.filter((post) => post.id !== deletedPostId));
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };  

  if (loadingProfile || !searchClient) {
    return <ProfileSkeleton />;
  }

  if (profileError || postsError) {
    return (
      <div className="profile-error">
        {t('error')}: {profileError || postsError}
      </div>
    );
  }

  if (!profile) {
    return <div className="profile-error">{t('profile.not_found')}</div>;
  }

  const filterString =
    activeTab === 'saved'
      ? `objectID:${savedPostIds.join(' OR objectID:')}`
      : `userId:${profileUserId}`;

      return (
        <PostProvider>
          <div className="profile-container">
            {editing ? (
              <ProfileForm
                existingProfile={profile}
                onProfileUpdate={handleProfileUpdate}
                onCancel={handleCancelEdit}
              />
            ) : (
              <ProfileHeader
                user={{ ...profile, id: profileUserId }}
                isOwnProfile={isCurrentUser}
                friendshipStatus={friendStatus}
                onEditProfile={handleEditProfile}
                onLogout={handleLogout}
                onSendRequest={sendFriendRequest}
              />
            )}
    
            <div className="profile-tabs">
              <button
                className={`profile-tab ${activeTab === 'posts' ? 'active' : ''}`}
                onClick={() => handleTabChange('posts')}
              >
                {t('posts.title')}
              </button>
              {isCurrentUser && (
                <button
                  className={`profile-tab ${activeTab === 'saved' ? 'active' : ''}`}
                  onClick={() => handleTabChange('saved')}
                >
                  {t('profile.saved_posts')}
                </button>
              )}
            </div>
    
            <InstantSearch
              searchClient={searchClient}
              indexName={process.env.REACT_APP_ALGOLIA_INDEX_NAME}
              searchState={searchState}
              onSearchStateChange={handleSearchStateChange}
            >
              <Configure filters={filterString} />
              <div className="profile-posts-search">
                <SearchBar />
              </div>
              <div className="profile-content">
                <PostList
                  defaultPosts={activeTab === 'posts' ? userPosts : savedPosts}
                  isCurrentUser={isCurrentUser}
                  activeTab={activeTab}
                  onPostDeleted={handlePostDelete}
                  fetchMorePosts={fetchMorePosts}
                  hasMorePosts={hasMorePosts}
                  fetchMoreSavedPosts={fetchMoreSavedPosts}
                  hasMoreSavedPosts={hasMoreSavedPosts}                  
                  initialLoading={initialPostsLoading}
                  loadingMore={loadingMorePosts}
                />
              </div>
            </InstantSearch>
          </div>
        </PostProvider>
      );
    };
    
    export default Profile;