// src/components/NotificationItem.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Heart, Share2, MessageCircle, UserPlus, MessageSquare } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { useTranslation } from 'react-i18next';
import './NotificationItem.css';

const getIcon = (type) => {
  switch (type) {
    case 'like':
      return <Heart size={18} className="notification-icon like" />;
    case 'share':
      return <Share2 size={18} className="notification-icon share" />;
    case 'comment':
      return <MessageCircle size={18} className="notification-icon comment" />;
    case 'follow':
      return <UserPlus size={18} className="notification-icon follow" />;
    case 'reply':
      return <MessageSquare size={18} className="notification-icon reply" />;
    case 'mention':
      return <MessageSquare size={18} className="notification-icon mention" />;
    default:
      return null;
  }
};

// Helper function to format time with i18n
const formatTime = (date, t) => {
  if (!date) return t('timeFormat.longTimeAgo');
  
  const now = new Date();
  const diffMs = now - date;
  const diffMins = Math.floor(diffMs / (1000 * 60));
  
  if (diffMins < 1) {
    return t('timeFormat.justNow');
  }
  
  if (diffMins < 60) {
    return t('timeFormat.minutesAgo', { count: diffMins });
  }
  
  const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
  if (diffHours < 24) {
    return t('timeFormat.hoursAgo', { count: diffHours });
  }
  
  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  if (diffDays < 7) {
    return t('timeFormat.daysAgo', { count: diffDays });
  }
  
  // Fall back to date-fns for older dates
  return formatDistanceToNow(date, { addSuffix: false });
};

const NotificationItem = ({ notification, onClose }) => {
  const { t } = useTranslation();
  const { type, placeholders, createdAt, relatedId } = notification;
  const navigate = useNavigate();
  
  const date = createdAt && createdAt.toDate ? 
    createdAt.toDate() : 
    (createdAt instanceof Date ? createdAt : new Date());
  
  const formattedDate = formatTime(date, t);

  // Get the localized notification text
  const notificationText = t(`notifications.${type}`, 
    placeholders || { userName: t('notifications.anonymous') });

  const handleClick = () => {
    if (relatedId) {
      onClose(); // Close the notifications dropdown
      navigate(`/post/${relatedId}`, {
        state: {
          commentIndex: notification.commentIndex != null ? parseInt(notification.commentIndex) : null,
          replyIndex: notification.replyIndex != null ? parseInt(notification.replyIndex) : null,
        },
      });
    }
  };

  return (
    <div className="notification-item" onClick={handleClick}>
      <div className="notification-icon-container">
        {getIcon(type)}
      </div>
      <div className="notification-content">
        <p className="notification-text">{notificationText}</p>
        <span className="notification-time">{formattedDate}</span>
      </div>
    </div>
  );
};

export default NotificationItem;