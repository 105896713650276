// src/hooks/useUser.js
import { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { getUserProfile, updateUserProfile, updateCompleteProfile } from '../services/userService';
import { useTranslation } from 'react-i18next';

/**
 * Hook for managing the current authenticated user
 * Provides current user info, profile data, and methods to update profile
 */
const useUser = () => {
  const { i18n } = useTranslation();
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Subscribe to auth state changes
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      setLoading(true);
      setError(null);
      
      if (authUser) {
        try {
          const userProfile = await getUserProfile(authUser.uid);
          setUser(authUser);
          setProfile(userProfile);
          
          // Set language based on user preference
          if (userProfile.languagePreference && i18n && i18n.language !== userProfile.languagePreference) {
            i18n.changeLanguage(userProfile.languagePreference);
          }
        } catch (err) {
          console.error('Error fetching user profile:', err);
          setError(err.message || 'Failed to load profile');
          // Still set the user even if profile fetch fails
          setUser(authUser);
          setProfile(null);
        }
      } else {
        setUser(null);
        setProfile(null);
      }
      
      setLoading(false);
    });

    return () => unsubscribe();
  }, [i18n]);

  /**
   * Update user profile data
   * @param {Object} profileData - New profile data to update
   */
  const updateProfile = async (profileData) => {
    setLoading(true);
    setError(null);
    
    try {
      const updatedProfile = await updateUserProfile(profileData);
      setProfile(prev => ({ ...prev, ...updatedProfile }));
      return updatedProfile;
    } catch (err) {
      setError(err.message || 'Failed to update profile');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * Update complete user profile including username and profile picture
   * @param {Object} profileData - Basic profile data
   * @param {string} originalUsername - Original username for comparison
   * @param {File} profilePicture - Profile picture file (optional)
   */
  const updateCompleteUserProfile = async (profileData, originalUsername, profilePicture) => {
    setLoading(true);
    setError(null);
    
    try {
      const completeProfile = await updateCompleteProfile(
        profileData, 
        originalUsername, 
        profilePicture
      );
      setProfile(prev => ({ ...prev, ...completeProfile }));
      return completeProfile;
    } catch (err) {
      setError(err.message || 'Failed to update profile');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * Check if profile is incomplete (missing required fields)
   */
  const isProfileIncomplete = () => {
    return !profile || !profile.displayName || !profile.username;
  };

  return {
    user,
    profile,
    loading,
    error,
    updateProfile,
    updateCompleteUserProfile,
    isLoggedIn: !!user,
    isProfileIncomplete,
    userId: user?.uid
  };
};

export default useUser; 