// src/components/AddMembersModal.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { X, Search } from 'lucide-react';
import useFriends from '../hooks/useFriends';
import useGroupMembers from '../hooks/useGroupMembers';
import SelectableFriendItem from './SelectableFriendItem';
import Button from './Button';
import styles from './AddMembersModal.module.css';

const AddMembersModal = ({ group, existingMembers, onClose, onMembersAdded }) => {
  const { t } = useTranslation();
  const { friends, loading: loadingFriends } = useFriends();
  const { getAvailableFriends, addMembers, loading: addingMembers, error: addError } = useGroupMembers(group, existingMembers);
  
  const [availableFriends, setAvailableFriends] = useState([]);
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);

  // Filter friends who aren't already in the group
  useEffect(() => {
    if (friends && existingMembers) {
      const available = getAvailableFriends(friends);
      setAvailableFriends(available);
    }
  }, [friends, existingMembers, getAvailableFriends]);

  // Update error from hook
  useEffect(() => {
    if (addError) {
      setError(addError);
    }
  }, [addError]);

  const toggleFriendSelection = (friendId) => {
    setSelectedFriends(prev => {
      if (prev.includes(friendId)) {
        return prev.filter(id => id !== friendId);
      }
      return [...prev, friendId];
    });
  };

  const filteredFriends = availableFriends.filter(friend => {
    const searchTerm = searchQuery.toLowerCase();
    const displayName = (friend.displayName || '').toLowerCase();
    const username = (friend.username || '').toLowerCase();
    return displayName.includes(searchTerm) || username.includes(searchTerm);
  });

  const handleAddMembers = async () => {
    if (selectedFriends.length === 0) {
      setError(t('groups.select_members_error'));
      return;
    }

    setError(null);

    try {
      const addedMembers = await addMembers(selectedFriends);
      onMembersAdded(addedMembers);
      onClose();
    } catch (error) {
      console.error('Error adding members:', error);
      setError(t('groups.add_members_error'));
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        {/* Header */}
        <div className={styles.modalHeader}>
          <button 
            onClick={onClose}
            className={styles.closeButton}
            aria-label={t('common.close')}
          >
            <X size={24} />
          </button>
          <h2 className={styles.title}>{t('groups.add_members')}</h2>
          <Button
            onClick={handleAddMembers}
            disabled={addingMembers || selectedFriends.length === 0}
            variant="primary"
            size="small"
          >
            {addingMembers ? t('groups.adding_members') : t('common.add')}
          </Button>
        </div>

        {/* Search Bar */}
        <div className={styles.searchContainer}>
          <div className={styles.searchWrapper}>
            <Search size={18} className={styles.searchIcon} />
            <input
              type="text"
              placeholder={t('groups.search_friends')}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={styles.searchInput}
            />
          </div>
        </div>

        {/* Friends List */}
        <div className={styles.friendsList}>
          {loadingFriends ? (
            <div className={styles.loadingIndicator}>
              <div className={styles.spinner} />
            </div>
          ) : filteredFriends.length > 0 ? (
            filteredFriends.map(friend => (
              <SelectableFriendItem
                key={friend.id}
                friend={friend}
                isSelected={selectedFriends.includes(friend.id)}
                onToggleSelect={toggleFriendSelection}
                disabled={addingMembers}
              />
            ))
          ) : (
            <p className={styles.noFriendsMessage}>
              {searchQuery 
                ? t('groups.no_search_results')
                : t('groups.no_available_friends')}
            </p>
          )}
        </div>

        {error && <div className={styles.errorMessage}>{error}</div>}

        {/* Loading Overlay */}
        {addingMembers && (
          <div className={styles.loadingOverlay}>
            <div className={styles.spinner} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddMembersModal;