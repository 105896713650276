// src/components/ReplyIndicator.js

import React, { memo } from 'react';
import { Reply, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import './ReplyIndicator.css';

// Extract ReplyIndicator to a separate file and memoize it
const ReplyIndicator = memo(({ message, sender, onCancel }) => {
  const { t } = useTranslation();
  
  if (!message) return null;
  
  const senderName = message.senderId === 'bot' 
    ? t('chat.affin_icon') 
    : (sender?.displayName || t('chat.user'));
  
  return (
    <div className="reply-indicator">
      <Reply size={16} color="var(--color-primary)" />
      <div className="reply-indicator-info">
        <div className="reply-to-name">
          {t('chat.replying_to')} {senderName}
        </div>
        <div className="reply-preview">
          {message.text || (message.images?.length > 0 ? t('chat.image') : '')}
        </div>
      </div>
      <button 
        className="cancel-reply" 
        onClick={onCancel} 
        aria-label={t('chat.cancel_reply')}
      >
        <X size={16} />
      </button>
    </div>
  );
});

ReplyIndicator.displayName = 'ReplyIndicator';

export default ReplyIndicator;