// src/services/chatService.js
import { auth, db, storage } from '../firebase';
import { 
  collection, addDoc, query, orderBy, limit, 
  onSnapshot, serverTimestamp, getDocs, startAfter, GeoPoint 
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getLocation } from '../utils/location';
import { compressImage } from '../utils/imageCompression';

// Initialize Firebase Functions
const functions = getFunctions();
const difyChat = httpsCallable(functions, 'difyChatV2', { timeout: 550000 });

/**
 * Subscribe to user's private chat messages
 * @param {number} messagesLimit - Number of messages to load initially
 * @param {Function} onUpdate - Callback for messages update
 * @param {Function} onError - Callback for errors
 * @returns {Function} Unsubscribe function
 */
export const subscribeToMessages = (messagesLimit = 25, onUpdate, onError) => {
  const user = auth.currentUser;
  if (!user) {
    onUpdate([]);
    return () => {};
  }

  const messagesRef = collection(db, `users/${user.uid}/messages`);
  const q = query(
    messagesRef,
    orderBy('timestamp', 'desc'),
    limit(messagesLimit)
  );

  return onSnapshot(
    q,
    (querySnapshot) => {
      const messages = [];
      querySnapshot.forEach((doc) => {
        messages.push({ id: doc.id, ...doc.data() });
      });

      onUpdate({
        messages: messages.reverse(), // Oldest first
        lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
        hasMore: querySnapshot.docs.length === messagesLimit
      });
    },
    (error) => {
      console.error('Error subscribing to messages:', error);
      if (onError) onError(error);
    }
  );
};

/**
 * Load more messages (pagination)
 * @param {Object} lastVisible - Last document snapshot for pagination
 * @param {number} messagesLimit - Number of messages to load
 * @returns {Promise<Object>} Object with messages, lastVisible, hasMore
 */
export const loadMoreMessages = async (lastVisible, messagesLimit = 25) => {
  const user = auth.currentUser;
  if (!user || !lastVisible) {
    return { messages: [], lastVisible: null, hasMore: false };
  }

  try {
    const messagesRef = collection(db, `users/${user.uid}/messages`);
    const q = query(
      messagesRef,
      orderBy('timestamp', 'desc'),
      startAfter(lastVisible),
      limit(messagesLimit)
    );

    const querySnapshot = await getDocs(q);
    const messages = [];
    querySnapshot.forEach((doc) => {
      messages.push({ id: doc.id, ...doc.data() });
    });

    return {
      messages: messages.reverse(),
      lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1] || null,
      hasMore: querySnapshot.docs.length === messagesLimit
    };
  } catch (error) {
    console.error('Error loading more messages:', error);
    throw error;
  }
};

/**
 * Upload images for chat
 * @param {Array<File>} files - Array of file objects
 * @returns {Promise<Array>} Uploaded image data (url, name, type)
 */
export const uploadChatImages = async (files) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User not authenticated');

  try {
    const imagePromises = Array.from(files).map(async (file) => {
      // Compress the image before uploading
      const compressedFile = await compressImage(file);
      
      // Use the same filename format as ImageUploader
      const fileName = `${Date.now()}_${file.name}`;
      
      // Use the same path structure as ImageUploader
      const storagePath = `chat_images/${user.uid}/${fileName}`;
      const storageRef = ref(storage, storagePath);
      
      // Define custom metadata
      const customMetadata = {
        uploaderId: user.uid,
        uploadTime: new Date().toISOString(),
      };
      
      // Upload with metadata
      await uploadBytes(storageRef, compressedFile, { customMetadata });
      const downloadUrl = await getDownloadURL(storageRef);
      
      return {
        url: downloadUrl,
        name: fileName,
        type: file.type,
        metadata: customMetadata
      };
    });

    return Promise.all(imagePromises);
  } catch (error) {
    console.error('Error uploading images:', error);
    throw error;
  }
};

/**
 * Send a message in private chat
 * @param {string} text - Message text
 * @param {Array} images - Array of image objects
 * @param {string} language - Current UI language
 * @returns {Promise<Object>} Created message reference
 */
export const sendMessage = async (text, images = [], language = 'en') => {
  const user = auth.currentUser;
  if (!user) throw new Error('User not authenticated');

  try {
    // Get location data
    const location = await getLocation();

    // Prepare message data
    const messageData = {
      text,
      sender: 'user',
      type: 'chat',
      timestamp: serverTimestamp(),
      images: images.length > 0 ? images : null,
      location: location ? new GeoPoint(location.lat, location.lng) : null
    };

    // Add user message
    const messagesRef = collection(db, `users/${user.uid}/messages`);
    const newMessageRef = await addDoc(messagesRef, messageData);

    // Add typing indicator for bot response
    await addDoc(messagesRef, {
      text: 'Please wait...',
      sender: 'bot',
      type: 'typing',
      status: 'thinking',
      inReplyTo: newMessageRef.id,
      timestamp: serverTimestamp(),
      stage: 'thinking',
      progress: 0,
      progressStage: 0
    });

    // Call Dify AI in the background
    difyChat({
      query: text,
      images,
      messageId: newMessageRef.id,
      language
    }).catch((error) => {
      console.error('Error in difyChat:', error);
    });

    return { id: newMessageRef.id };
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
}; 