// src/hooks/useGroups.js
import { useState, useEffect, useCallback } from 'react';
import { 
  createGroup, 
  subscribeToUserGroups, 
  formatRelativeTime,
  getGroupInitials
} from '../services/groupService';

/**
 * Hook for managing groups list and creation
 */
const useGroups = () => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(null);

  // Subscribe to user's groups
  useEffect(() => {
    setLoading(true);

    const handleError = (err) => {
      console.error('Error in useGroups:', err);
      setError(err.message || 'Failed to load groups');
      setLoading(false);
    };

    const handleUpdate = (groupsList) => {
      setGroups(groupsList);
      setLoading(false);
    };
    
    // Use the service to subscribe to groups
    const unsubscribe = subscribeToUserGroups(handleUpdate, handleError);
    
    return () => unsubscribe();
  }, []);

  /**
   * Create a new group
   * @param {string} name - Group name
   * @param {File} iconFile - Group icon file
   * @param {Array} memberIds - Member IDs to add to group
   * @returns {Promise<Object>} Newly created group
   */
  const handleCreateGroup = useCallback(async (name, iconFile, memberIds) => {
    setCreating(true);
    setError(null);
    
    try {
      const newGroup = await createGroup(name, iconFile, memberIds);
      
      // Groups list will update automatically via the subscription
      return newGroup;
    } catch (err) {
      console.error('Error creating group:', err);
      setError(err.message || 'Failed to create group');
      throw err;
    } finally {
      setCreating(false);
    }
  }, []);
  
  /**
   * Search groups by name
   * @param {string} searchTerm - Search term
   * @returns {Array} Filtered groups
   */
  const searchGroups = useCallback((searchTerm) => {
    if (!searchTerm?.trim()) return groups;
    
    const term = searchTerm.toLowerCase().trim();
    return groups.filter(group => 
      group.name.toLowerCase().includes(term)
    );
  }, [groups]);
  
  /**
   * Format relative time for display
   * @param {Date} date - Date to format
   * @returns {string} Formatted time string
   */
  const formatTime = useCallback((date) => {
    return formatRelativeTime(date);
  }, []);
  
  /**
   * Get initials from a group name
   * @param {string} name - Group name
   * @returns {string} Initials for display
   */
  const getInitials = useCallback((name) => {
    return getGroupInitials(name);
  }, []);

  return {
    groups,
    loading,
    creating,
    error,
    createGroup: handleCreateGroup,
    searchGroups,
    formatTime,
    getInitials
  };
};

export default useGroups; 